// @flow
import filesize from 'filesize'
import moment from 'moment'
import React from 'react'
import get from 'lodash/get'
import last from 'lodash/last'
import i18next from 'i18next'
import uuid from 'uuid'
import path from 'path'
import { batch } from 'react-redux'
import tailwindConfig from '@edison/webmail-ui/tailwind.config'
import * as client from '@edison/webmail-core/api'
import { createAction } from 'utils/redux'
import { getAuth } from 'core/auth/selectors'
import { getLabelState } from 'core/labels/selectors'
import { getThreadMessages, getMessage } from 'core/messages/selectors'
import {
  getUndoDuration,
  getDefaultSenderEmailSelector,
} from 'core/settings/selectors'
import { usageSelector } from 'core/usage/selectors'
import { getCurrentDomain } from 'core/custom-domains/selectors'
import {
  getMessagesState,
  getThreadMessagesState,
  getThreadDraftMessageIds,
  getThreadNotDraftMessageIds,
} from 'core/metadata/selectors'
import { getAssignedLabels } from 'core/metadata/helpers'
import { updateMessageNudge } from 'core/email-nudges/actions'
import { showNotification } from 'core/toasts/actions'
import { showSnackbar } from 'core/snackbars/actions'
import { show as showModal } from 'core/modals/actions'
import { updateMessage } from 'core/messages/actions'
import {
  checkAndShowFileStoragePaywall,
  checkAndShowSingleFileSizeLimitPaywall,
  checkRemainingSizeAndShowFileStoragePaywall,
} from 'core/premium/actions'
import {
  getDraft,
  getDrafts,
  getDraftAttachments,
  getDraftLargeAttachments,
  isDraftDisabled,
  getComposeBody,
  hasDraftAttachmentSizeExceed,
  getDraftRealAttachments,
  getDraftSize,
  getDraftFromEmail,
} from './selectors'
import { toastVariants } from 'common/toasts'
import { debounced, promises } from 'middlewares/debounce'

import { isEmail } from '@edison/webmail-ui/utils'
import { nudgeStatus } from '@edison/webmail-core/utils/constants'

import {
  hasLargeAttachmentComplete,
  hasLargeAttachmentError,
  hasAttachmentComplete,
  hasAttachmentError,
  formatComposeRecipients,
  isPlainDraft,
} from 'utils'
import { isHTMLEmpty } from 'utils/htmlProcess'
import { CONTENT_DISPOSITION_ATTACHMENT } from '@edison/webmail-core/utils/constants'

import {
  labelNames,
  COMPOSE_DEBOUNCE_TIME,
  composeStatus,
  snackbarTypes,
  draftTypes,
  modalTypes,
  MAX_ATTACHMENT_TOTAL_SIZE,
  BLOCKED_UPLOAD_FILE_TYPES,
  COMPOSE_DEFAULT_HTML,
  autoSelectEmail,
} from 'utils/constants'
import {
  isSignatureEnable,
  getAccountSignatureContent,
} from 'core/settings/selectors'
import { generateSignatureSection } from 'utils/responseTemplate'
import {
  SendFailureAction,
  SendSuccessAction,
  UndoSendSuccessAction,
} from 'screens/Compose/components/SendSnackbarAction'

import { uploadAttachments, addAttachments } from 'core/attachments/actions'
import {
  uploadLargeAttachments,
  discardUploads,
} from 'core/large-attachments/actions'
import { getAttachment, getAttachments } from 'core/attachments/selectors'
import { getActiveAccount } from 'core/retrofit/selectors'
import { hasFilesExceedMaxSize, existedDraftMessageIds } from './helpers'
import sanitize from 'sanitize-filename'
import {
  persistSendWithUploadingAttachmentTip,
  loadSendWithUploadingAttachmentTip,
} from 'common/storage'
import {
  largeAttachmentScanStatus,
  largeAttachmentStatus,
} from '@edison/webmail-ui/utils/constants'
import {
  removeLargeAttachmentTemplate,
  insertLargeAttachmentPlaceholder,
  clearLargeAttachmentPlaceholder,
} from 'utils/htmlProcess'

import type { ThunkAction, ActionCreator } from 'types/redux'
import type {
  SaveDraftRequest,
  SaveDraftSuccess,
  SaveDraftFailure,
  FetchDraftRequest,
  FetchDraftSuccess,
  FetchDraftFailure,
  DeleteDraftRequest,
  DeleteDraftSuccess,
  DeleteDraftFailure,
  SendDraftRequest,
  SendDraftSuccess,
  SendDraftFailure,
  UpdateDraft,
  CreateDraft,
  RemoveDraft,
  ActiveDraft,
  BeforeSendDraft,
  BeforeDeleteDraft,
  AfterSendDraftFailure,
  AfterDeleteDraftFailure,
  BatchDeleteDraftRequest,
  BatchDeleteDraftSuccess,
  BatchDeleteDraftFailure,
} from './types'
import { discardUpload, addUploads } from 'core/large-attachments/actions'
import { waitTime } from '../../utils'

export const createDraft: ActionCreator<CreateDraft> =
  createAction('CREATE_DRAFT')
export const removeDraft: ActionCreator<RemoveDraft> =
  createAction('REMOVE_DRAFT')
export const updateDraft: ActionCreator<UpdateDraft> =
  createAction('UPDATE_DRAFT')

export const activeDraft: ActionCreator<ActiveDraft> =
  createAction('ACTIVE_DRAFT')
export const beforeSendDraft: ActiveCreator<BeforeSendDraft> =
  createAction('BEFORE_SEND_DRAFT')

export const beforeDeleteDraft: ActiveCreator<BeforeDeleteDraft> = createAction(
  'BEFORE_DELETE_DRAFT',
)

export const afterDeleteDraftFailure: ActiveCreator<AfterDeleteDraftFailure> =
  createAction('AFTER_DELETE_DRAFT_FAILURE')

export const afterSendDraftFailure: ActiveCreator<AfterSendDraftFailure> =
  createAction('AFTER_SEND_DRAFT_FAILURE')

export function createNewDraft(
  draftId: string,
  draft: Object = {},
): ThunkAction {
  return async (dispatch, getState) => {
    let { html = '' } = draft
    const state = getState()
    const isEnableSignature = isSignatureEnable()(state)
    const defaultSender = getDefaultSenderEmailSelector(state)
    const signatureContent = getAccountSignatureContent(defaultSender)(state)
    const activeAccount = getActiveAccount(state)
    const auth = getAuth()(state)
    const from =
      defaultSender === autoSelectEmail
        ? activeAccount
          ? activeAccount?.emailAddress
          : auth.user
        : defaultSender
    dispatch(
      createDraft({
        from,
        ...draft,
        id: draftId,
        html:
          html ||
          (isEnableSignature
            ? `${COMPOSE_DEFAULT_HTML}${
                isEnableSignature
                  ? generateSignatureSection(signatureContent)
                  : ''
              }`
            : ''),
      }),
    )
  }
}

export const saveDraftActions: {
  request: ActionCreator<SaveDraftRequest>,
  success: ActionCreator<SaveDraftSuccess>,
  failure: ActionCreator<SaveDraftFailure>,
} = {
  request: createAction('SAVE_DRAFT_REQUEST'),
  success: createAction('SAVE_DRAFT_SUCCESS'),
  failure: createAction('SAVE_DRAFT_FAILURE'),
}

export function saveDraft(draftId: string, forced: boolean): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const draft = getDraft(draftId)(state)
    const draftType = get(draft, 'extendInfo.draftType', draftTypes.DEFAULT)

    // Skip the saving process while it's a claim email draft
    if (draftType === draftTypes.CLAIM_EMAIL && !forced) {
      return
    }
    if (
      dispatch(
        checkRemainingSizeAndShowFileStoragePaywall(
          getDraftSize(draftId)(state),
        ),
      )
    ) {
      return
    }

    if (hasDraftAttachmentSizeExceed(draftId)(state)) {
      dispatch(
        showModal({
          key: modalTypes.error,
          props: {
            message: i18next.t('compose.send.error.exceedMaxSize', {
              maxSize: filesize(MAX_ATTACHMENT_TOTAL_SIZE),
            }),
          },
        }),
      )
      return
    }

    const auth = getAuth()(state)

    dispatch(saveDraftActions.request({ draftId }))
    try {
      const { messageId, responseMessageId, threadId } = draft

      const composeBody = getComposeBody(draftId)(state)
      const { references, inReplyTo } = composeBody
      composeBody.threadId = threadId
      const res = await client.compose.saveCompose(composeBody, auth)
      const {
        messageId: newMessageId,
        threadId: newThreadId,
        cidToAttachment,
        largeAttachments,
        messageHeaderId,
        composeId,
        snippet,
        date,
      } = res.result

      existedDraftMessageIds.add(newMessageId)
      // attachment or large attachment need to be latest avoid update override
      const attachments = getDraftAttachments(draftId)(getState())
      const { largeAttachmentUuids } = getDraft(draftId)(getState())

      // filter the deleted attachments
      const addedLargeAttachments = attachments
        .filter(item => cidToAttachment[item.id])
        .map(item => cidToAttachment[item.id])

        .map(item => ({
          ...item,
          uuid: uuid(),
          status: largeAttachmentStatus.DONE,
          scanStatus: largeAttachmentScanStatus.CLEAN,
          parts: [{}],
          chunkSize: item.size,
        }))
      const newDraft = {
        //get the updatest draft state
        ...getDraft(draftId)(getState()),
        id: !responseMessageId ? newThreadId : draftId,
        messageId: newMessageId,
        threadId: newThreadId,
        composeId,
        //filter some attachment convert to largeattachment
        attachmentUuids: attachments
          .filter(item => !cidToAttachment[item.id])
          .map(item => item.uuid),
        //get current new largeAttachmentUuids
        //converted attachments sort newer
        largeAttachmentUuids: addedLargeAttachments
          .map(item => item.uuid)
          .concat(largeAttachmentUuids),
      }

      // Diff the view labels for the changed draft
      const changedThread = getDraftViewLabels(
        newThreadId,
        {
          added: [newMessageId],
          removed: [messageId],
        },
        { getState },
      )

      dispatch(
        saveDraftActions.success({
          snippet,
          date,
          cidToAttachment,
          draft: newDraft,
          from: composeBody.from,
          threadId: newThreadId,
          messageId: newMessageId,
          oldMessageId: messageId,
          oldDraftId: draftId,
          addedLargeAttachments,
          largeAttachments,
          attachments,
          messageHeaderId,
          references,
          inReplyTo,
          changedThread,
        }),
      )
    } catch (e) {
      batch(() => {
        dispatch(saveDraftActions.failure({ error: e, draftId }))
        dispatch(
          showNotification(
            i18next.t('compose.save.error'),
            toastVariants.error,
          ),
        )
      })
    }
  }
}

export const fetchDraftActions: {
  request: ActionCreator<FetchDraftRequest>,
  success: ActionCreator<FetchDraftSuccess>,
  failure: ActionCreator<FetchDraftFailure>,
} = {
  request: createAction('FETCH_DRAFT_REQUEST'),
  success: createAction('FETCH_DRAFT_SUCCESS'),
  failure: createAction('FETCH_DRAFT_FAILURE'),
}

export function fetchDraft(threadId: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    dispatch(fetchDraftActions.request({ threadId }))
    try {
      const res = await client.messages.list(threadId, { auth })

      if (
        !res.result.messages.length ||
        res.result.messages.some(
          item => !isPlainDraft(item.labelIds, res.result.messages.length),
        )
      ) {
        throw Error('Fetch compose error')
      }

      const {
        to,
        cc,
        bcc,
        attachments,
        html,
        subject,
        id,
        from,
        largeAttachments,
        composeId,
      } = last(res.result.messages)
      const formattedAttachments = attachments.map(item => ({
        ...item,
        uuid: uuid(),
      }))
      const formattedLargeAttachments = largeAttachments.map(item => ({
        ...item,
        uuid: uuid(),
        chunkSize: item.size,
        parts: [{}],
        status: largeAttachmentStatus.DONE,
        scanStatus: largeAttachmentScanStatus.CLEAN,
      }))
      let removedLargeAttachmentsHtml = html

      if (largeAttachments.length) {
        removedLargeAttachmentsHtml = removeLargeAttachmentTemplate(html)
      }
      const draft = {
        id: threadId,
        composeId,
        threadId,
        messageId: id,
        to,
        cc,
        bcc,
        attachmentUuids: formattedAttachments.map(item => item.uuid),
        largeAttachmentUuids: formattedLargeAttachments.map(item => item.uuid),
        subject,
        html: removedLargeAttachmentsHtml,
        from: from ? from.email : null,
        actived: true,
        saved: true,
      }

      dispatch(
        fetchDraftActions.success({
          draft,
          thread: res.result,
          attachments: formattedAttachments,
          largeAttachments: formattedLargeAttachments,
        }),
      )
    } catch (e) {
      e.message = e.message || e.status === 404 ? 'Draft not found' : ''
      batch(() => {
        dispatch(fetchDraftActions.failure({ message: e.message }))
        dispatch(showNotification(e.message, toastVariants.error))
      })
    }
  }
}

export const deleteDraftActions: {
  request: ActionCreator<DeleteDraftRequest>,
  success: ActionCreator<DeleteDraftSuccess>,
  failure: ActionCreator<DeleteDraftFailure>,
} = {
  request: createAction('DELETE_DRAFT_REQUEST'),
  success: createAction('DELETE_DRAFT_SUCCESS'),
  failure: createAction('DELETE_DRAFT_FAILURE'),
}

export function deleteDraft(draftId: string): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)

    const draft = getDraft(draftId)(state)
    try {
      dispatch(deleteDraftActions.request())
      if (!draft.composeId) {
        await client.compose.deleteDraft(draft.messageId, { auth })
      } else {
        await client.compose.deleteCompose(draft.composeId, auth)
      }

      const messages = draft.responseMessageId
        ? getThreadMessages(draft.threadId)(state).filter(
            item => item.id !== draft.messageId,
          )
        : []

      const { messageId, threadId } = draft

      // Diff the view labels for the changed draft
      const changedThread = getDraftViewLabels(
        threadId,
        {
          added: [],
          removed: [messageId],
        },
        { getState },
      )
      dispatch(
        deleteDraftActions.success({
          draftId,
          threadId: draft.threadId,
          messageId: draft.messageId,
          responseMessageId: draft.responseMessageId,
          messages,
          changedThread,
        }),
      )
    } catch (e) {
      batch(() => {
        dispatch(deleteDraftActions.failure({ error: e, draftId }))
        dispatch(showNotification(e.message, toastVariants.error))
      })
    }
  }
}

export const sendDraftActions: {
  request: ActionCreator<SendDraftRequest>,
  success: ActionCreator<SendDraftSuccess>,
  failure: ActionCreator<SendDraftFailure>,
} = {
  request: createAction('SEND_DRAFT_REQUEST'),
  success: createAction('SEND_DRAFT_SUCCESS'),
  failure: createAction('SEND_DRAFT_FAILURE'),
}
export function sendDraft(draftId: string): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const draft = getDraft(draftId)(state)
    const auth = getAuth()(state)

    try {
      const composeBody = getComposeBody(draftId)(state)
      const { references, inReplyTo, from, to, cc, bcc } = composeBody
      dispatch(sendDraftActions.request({ draftId }))
      let res
      if (!draft.composeId) {
        res = await client.compose.sendDraft(composeBody, { auth })
      } else {
        res = await client.compose.sendCompose(draft.composeId, auth)
      }
      existedDraftMessageIds.add(draft.messageId)
      const {
        threadId: newThreadId,
        messageId: newMessageId,
        messageHeaderId,
        largeAttachments: newLargeAttachments,
      } = res.result

      dispatch(
        sendDraftActions.success({
          draft: { ...draft, to, cc, bcc },
          messageId: newMessageId,
          threadId: newThreadId,
          messageHeaderId,
          from,
          references: references || [],
          inReplyTo,
          largeAttachments: newLargeAttachments,
          attachments: getDraftAttachments(draftId)(getState()),
        }),
      )
    } catch (e) {
      batch(() => {
        dispatch(sendDraftActions.failure({ error: e, draftId }))
        dispatch(showNotification(e.message, toastVariants.error))
      })
    }
  }
}

const EXTRA_UNDO_DURATION = 2000

export const delaySendDraftActions: {
  request: ActionCreator<DelaySendDraftRequest>,
  success: ActionCreator<DelaySendDraftSuccess>,
  failure: ActionCreator<DelaySendDraftFailure>,
} = {
  request: createAction('DELAY_SEND_DRAFT_REQUEST'),
  success: createAction('DELAY_SEND_DRAFT_SUCCESS'),
  failure: createAction('DELAY_SEND_DRAFT_FAILURE'),
}

export function delaySendDraft(draftId: string): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    const undoDuration = getUndoDuration()(state)

    try {
      dispatch(delaySendDraftActions.request({ draftId }))
      const { composeId, threadId, messageId, largeAttachmentUuids, html } =
        getDraft(draftId)(state)

      let res
      const delay = Math.round((undoDuration + EXTRA_UNDO_DURATION) / 1000)

      if (composeId) {
        res = await client.schedulers.delaySendCompose(
          { composeId, delay },
          { auth },
        )
      } else {
        res = await client.schedulers.delaySend(
          {
            body: {
              draftId,
            },
            delay: Math.round((undoDuration + EXTRA_UNDO_DURATION) / 1000),
          },
          { auth },
        )
      }

      setTimeout(
        () => {
          dispatch(checkSchedulerResult(draftId))
        },
        undoDuration + EXTRA_UNDO_DURATION + 1000,
      )

      const schedulerId = res.result
      const draft = getDraft(draftId)(state)

      //large attachment need fetch the hidden html avoid reply/forward missing quotation
      let finalHtml = html
      if (largeAttachmentUuids.length) {
        const messageResult = await client.messages.batchGet([messageId], {
          auth,
        })

        finalHtml = messageResult.result?.messages?.[0]?.html
      }

      const changedThread = getDraftViewLabels(
        threadId,
        {
          added: [messageId],
          removed: [],
        },
        { getState },
      )
      batch(() => {
        dispatch(
          delaySendDraftActions.success({
            draftId,
            schedulerId,
            threadId,
            messageId,
            draft,
            changedThread,
            html: finalHtml,
          }),
        )
        // To set the set the nudge status to `went on` if there're valid nudge
        dispatch(updateMessageNudge(nudgeStatus.WENT_ON, { threadId }))
      })
    } catch (e) {
      batch(() => {
        dispatch(delaySendDraftActions.failure({ draftId, error: e }))
        dispatch(showNotification(e.message, toastVariants.error))
      })
    }
  }
}

export const undoSendDraftActions: {
  request: ActionCreator<UndoSendDraftRequest>,
  success: ActionCreator<UndoSendDraftSuccess>,
  failure: ActionCreator<UndoSendDraftFailure>,
} = {
  request: createAction('UNDO_SEND_DRAFT_REQUEST'),
  success: createAction('UNDO_SEND_DRAFT_SUCCESS'),
  failure: createAction('UNDO_SEND_DRAFT_FAILURE'),
}

export function undoSendDraft(draftId: string): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)

    try {
      const { schedulerId, threadId, messageId } = getDraft(draftId)(state)
      dispatch(undoSendDraftActions.request())
      await client.schedulers.deleteScheduler(schedulerId, { auth })

      batch(() => {
        dispatch(
          undoSendDraftActions.success({
            draftId,
            threadId,
            messageId,
          }),
        )
      })
    } catch (e) {
      throw e
    }
  }
}

export function discardDraft(draftId: string): ThunkAction {
  return async (dispatch, getState) => {
    if (!draftId) return
    const state = getState()
    const draft = getDraft(draftId)(state)

    const attachments = getDraftAttachments(draftId)(state)
    attachments.forEach(item => item.xhr && item.xhr.abort())
    batch(() => {
      draft.largeAttachmentUuids.length &&
        dispatch(discardUploads(draft.largeAttachmentUuids))

      if (!draft.messageId && draft.status !== composeStatus.saving) {
        dispatch(flushRemoveDraft(draftId))
      } else {
        const { threadId, messageId } = draft

        dispatch(
          beforeDeleteDraft({
            draftId,
            threadId,
            messageId,
          }),
        )

        dispatch(flushDeleteDraft(draftId))
      }
    })
  }
}

export function sendDraftFlow(
  draftId: string,
  confirmUploadError: boolean,
): ThunkAction {
  return async (dispatch, getState) => {
    // After user unsuccessfully sends an email because storage limit has been exceeded
    //issue: when low bandwidth, the fetch useage request take long time so will trigger dumplicate send issue
    // await dispatch(fetchUsage())
    let state = getState()

    const { threadId, messageId, extendInfo = {} } = getDraft(draftId)(state)

    // Don't process undo for claim email
    const isInstance =
      get(extendInfo, 'draftType', draftTypes.DEFAULT) ===
      draftTypes.CLAIM_EMAIL

    const attachments = getDraftRealAttachments(draftId)(state)
    const largeAttachments = getDraftLargeAttachments(draftId)(state)
    const isNeedUpload =
      attachments.some(item => !hasAttachmentComplete(item)) ||
      largeAttachments.some(item => !hasLargeAttachmentComplete(item))

    batch(() => {
      dispatch(
        beforeSendDraft({
          draftId,
          threadId,
          messageId,
        }),
      )
      dispatch(
        showSnackbar({
          key: snackbarTypes.send,
          props: isNeedUpload
            ? {
                draftId,
                message: i18next.t('compose.toast.uploading'),
                progress: 30,
              }
            : {
                draftId,
                message: i18next.t('compose.toast.sending'),
                progress: 30,
              },
        }),
      )
    })
    await Promise.all([
      ...attachments.map(item => item.promise),
      ...largeAttachments.map(item => item.promise),
    ])
    if (
      !confirmUploadError &&
      (getDraftRealAttachments(draftId)(getState()).some(hasAttachmentError) ||
        getDraftLargeAttachments(draftId)(getState()).some(
          hasLargeAttachmentError,
        ))
    ) {
      batch(() => {
        dispatch(
          showSnackbar({
            key: snackbarTypes.send,
            props: {
              draftId,
              action: <SendFailureAction draftId={draftId} />,
              message: i18next.t('compose.toast.sendFailure'),
              progress: 100,
              backgroundColor: tailwindConfig.theme.colors.red,
            },
          }),
        )

        dispatch(afterSendDraftFailure({ draftId }))
      })

      return
    }

    isNeedUpload &&
      dispatch(
        showSnackbar({
          key: snackbarTypes.send,
          props: {
            draftId,
            message: i18next.t('compose.toast.sending'),
            progress: 70,
          },
        }),
      )
    //correct the draft promise result
    await dispatch(flushDraft(draftId))
    state = getState()

    const { html, saved } = getDraft(draftId)(state) || {}
    let savedDraft = saved
    const currAttachments = getDraftAttachments(draftId)(state)
    //replace the inline image uuid to cid when send compose the inline attachment not upload complete.
    let realHtml = html
    currAttachments
      .filter(
        item =>
          item.contentDisposition !== CONTENT_DISPOSITION_ATTACHMENT && item.id,
      )
      .forEach(item => {
        realHtml = realHtml.replace(item.uuid, `cid:${item.id}`)
      })
    if (realHtml !== html) {
      savedDraft = false
    }

    // insert placeholder for backend insert large attachments section
    ;(() => {
      const largeAttachments = getDraftLargeAttachments(draftId)(state)
      if (largeAttachments.length > 0) {
        const formattedHtml = insertLargeAttachmentPlaceholder(realHtml)
        if (formattedHtml !== realHtml) {
          savedDraft = false
          realHtml = formattedHtml
        }
      }
    })()

    if (!savedDraft) {
      if (realHtml !== html) {
        dispatch(updateDraft({ id: draftId, html: realHtml, saved: false }))
      }

      await dispatch(saveDraft(draftId))
      const draft = getDraft(draftId)(getState())
      if (!draft || draft.error) {
        dispatch(
          showSnackbar({
            key: snackbarTypes.send,
            props: {
              draftId,
              action: <SendFailureAction draftId={draftId} />,
              message: i18next.t('compose.toast.sendFailure'),
              progress: 100,
              backgroundColor: tailwindConfig.theme.colors.red,
            },
          }),
        )
        return
      }
    }

    if (isInstance) {
      await dispatch(saveDraft(draftId, true))
      await dispatch(sendDraft(draftId))
    } else {
      await dispatch(delaySendDraft(draftId))
    }

    const draft = getDraft(draftId)(getState())

    if (!draft || draft.error) {
      dispatch(
        showSnackbar({
          key: snackbarTypes.send,
          props: {
            draftId,
            action:
              draft && !draft.responseMessageId ? (
                <SendFailureAction draftId={draftId} />
              ) : null,
            message: i18next.t('compose.toast.sendFailure'),
            progress: 100,
            backgroundColor: tailwindConfig.theme.colors.red,
          },
        }),
      )

      dispatch(afterSendDraftFailure({ draftId }))
    } else {
      dispatch(
        showSnackbar({
          key: snackbarTypes.send,
          props: {
            draftId,
            action: (
              <SendSuccessAction threadId={draft.threadId} draftId={draftId} />
            ),
            message: i18next.t('compose.toast.sendSuccess'),
            progress: 100,
            autoHideDuration: isInstance ? 10000 : getUndoDuration()(state),
            resumeHideDuration: 5000,
          },
        }),
      )
      //After user successfully sends an email that is still within the storage limit
      // await dispatch(fetchUsage())
      dispatch(checkAndShowFileStoragePaywall(0.9))
    }
  }
}

export function undoSendDraftFlow(draftId): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const undoDuration = getUndoDuration()(state)
    const { date, html } = getDraft(draftId)(state)

    if (moment().unix() - date >= undoDuration / 1000) {
      dispatch(
        showSnackbar({
          key: snackbarTypes.send,
          props: {
            draftId,
            action: <UndoSendSuccessAction />,
            message: i18next.t('compose.toast.undoFailure'),
            backgroundColor: tailwindConfig.theme.colors.red,
            autoHideDuration: 5000,
          },
        }),
      )
      return
    }
    dispatch(
      showSnackbar({
        key: snackbarTypes.send,
        props: {
          draftId,
          message: i18next.t('compose.toast.undoing'),
          progress: 30,
        },
      }),
    )

    const largeAttachments = getDraftLargeAttachments(draftId)(state)

    const draftHtml = largeAttachments.length
      ? clearLargeAttachmentPlaceholder(html)
      : html

    if (draftHtml !== html) {
      dispatch(updateDraft({ id: draftId, html: draftHtml, saved: false }))
    }

    try {
      await dispatch(undoSendDraft(draftId))
    } catch (e) {
      dispatch(
        showSnackbar({
          key: snackbarTypes.send,
          props: {
            draftId,
            action: <UndoSendSuccessAction />,
            message: i18next.t('compose.toast.undoFailure'),
            backgroundColor: tailwindConfig.theme.colors.red,
            autoHideDuration: 5000,
          },
        }),
      )
      return
    }

    dispatch(
      showSnackbar({
        key: snackbarTypes.send,
        props: {
          draftId,
          action: <UndoSendSuccessAction />,
          message: i18next.t('compose.toast.undoSuccess'),
          progress: 100,
          autoHideDuration: 5000,
        },
      }),
    )

    if (draftHtml !== html) {
      dispatch(flushSaveDraft(draftId))
    }
  }
}

export function createOrFetchDraft(
  draftId: string,
  rawDraft: Object = {},
): ThunkAction {
  return async (dispatch, getState) => {
    const draft = getDraft(draftId)(getState())
    if (draft) return
    if (draftId && draftId.startsWith('new')) {
      dispatch(createNewDraft(draftId, rawDraft))
    } else {
      dispatch(fetchDraft(draftId))
    }
  }
}

export function debouncedSaveDraft(draftId: string, ...rest): ThunkAction {
  const action = async (dispatch, getState) => {
    const { status } = getDraft(draftId)(getState()) || {}
    if (![composeStatus.deleted, composeStatus.sended].includes(status)) {
      return dispatch(saveDraft(draftId, ...rest))
    }
  }
  return debounced(action, {
    key: draftId,
    wait: COMPOSE_DEBOUNCE_TIME,
  })
}

export function flushSaveDraft(draftId: string, ...rest): ThunkAction {
  const action = async (dispatch, getState) => {
    const { status } = getDraft(draftId)(getState())
    if (![composeStatus.deleted, composeStatus.sended].includes(status)) {
      return dispatch(saveDraft(draftId, ...rest))
    }
  }
  return debounced(action, {
    key: draftId,
    wait: COMPOSE_DEBOUNCE_TIME,
    flush: true,
  })
}

export function flushRemoveDraft(draftId: string): ThunkAction {
  return debounced(removeDraft(draftId), {
    key: draftId,
    wait: COMPOSE_DEBOUNCE_TIME,
    flush: true,
  })
}

export function debouncedDeleteDraft(draftId: string): ThunkAction {
  return debounced(deleteDraft(draftId), {
    key: draftId,
    wait: COMPOSE_DEBOUNCE_TIME,
  })
}
export function flushDeleteDraft(draftId: string): ThunkAction {
  return debounced(deleteDraft(draftId), {
    key: draftId,
    wait: COMPOSE_DEBOUNCE_TIME,
    flush: true,
  })
}

export function debouncedSendDraft(draftId: string): ThunkAction {
  return debounced(sendDraft(draftId), {
    key: draftId,
    wait: COMPOSE_DEBOUNCE_TIME,
  })
}
export function flushSendDraft(draftId: string): ThunkAction {
  return debounced(sendDraft(draftId), {
    key: draftId,
    wait: COMPOSE_DEBOUNCE_TIME,
    flush: true,
  })
}
export function flushDelaySendDraft(draftId: string): ThunkAction {
  return debounced(delaySendDraft(draftId), {
    key: draftId,
    wait: COMPOSE_DEBOUNCE_TIME,
    flush: true,
  })
}

export function flushDraft(draftId: string): ThunkAction {
  return debounced(null, {
    key: draftId,
    wait: COMPOSE_DEBOUNCE_TIME,
    flush: true,
  })
}
// auto scroll to the attachmentnode when upload normal attachments
export function uploadFiles(draftId: string, files: Array<File>): ThunkAction {
  return (dispatch, getState) => {
    const emptySizeFile = files.find(item => !item.size)

    if (emptySizeFile) {
      dispatch(
        showModal({
          key: modalTypes.error,
          props: {
            message: i18next.t('compose.upload.error.emptySize', {
              fileName: emptySizeFile.name,
            }),
          },
        }),
      )
      return
    }
    const validatedFiles = files
      .filter(file => {
        const extname = path.extname(file.name).toUpperCase()
        return !extname || !BLOCKED_UPLOAD_FILE_TYPES.includes(`${extname},`)
      })
      .map(file => {
        return new File([file], sanitize(file.name), { type: file.type })
      })
    if (validatedFiles.length !== files.length) {
      dispatch(
        showNotification(
          i18next.t('compose.upload.error.wrongType'),
          toastVariants.warning,
        ),
      )
    }
    const state = getState()
    //skip the virus detected attachment
    const attachments = getDraftRealAttachments(draftId)(state)
    const { largeAttachmentUuids } = getDraft(draftId)(state)

    if (
      largeAttachmentUuids.length ||
      hasFilesExceedMaxSize(attachments, validatedFiles)
    ) {
      dispatch(uploadDraftLargeAttachments(draftId, validatedFiles))
    } else {
      return dispatch(uploadDraftAttachments(draftId, validatedFiles))
    }
  }
}

export function uploadDraftAttachments(draftId: string, files): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const currFileTotalSize = files
      .map(item => item.size)
      .reduce((prev, curr) => prev + curr, 0)

    if (
      dispatch(checkRemainingSizeAndShowFileStoragePaywall(currFileTotalSize))
    ) {
      return
    }

    const { attachmentUuids, messageId } = getDraft(draftId)(state)
    //force save draft if not saved
    if (!messageId) {
      dispatch(flushSaveDraft(draftId))
    }

    const newAttachments = files.map(file => ({
      file,
      fileName: file.name,
      contentType: file.type,
      size: file.size,
      progress: 0,
      uuid: uuid(),
      contentDisposition: CONTENT_DISPOSITION_ATTACHMENT,
    }))
    batch(() => {
      dispatch(
        updateDraft({
          id: draftId,
          attachmentUuids: attachmentUuids.concat(
            newAttachments.map(item => item.uuid),
          ),
        }),
      )
      dispatch(uploadAttachments(draftId, newAttachments))
    })
  }
}

export function uploadDraftLargeAttachments(draftId, files) {
  return async (dispatch, getState) => {
    if (
      files.some(item =>
        dispatch(checkAndShowSingleFileSizeLimitPaywall(item.size)),
      )
    ) {
      return
    }

    const state = getState()
    const usage = usageSelector(state)
    const maxNumber = get(usage, 'limits.largeAttachmentNumPerLink', 0)

    const currFileTotalSize = files
      .map(item => item.size)
      .reduce((prev, curr) => prev + curr, 0)

    const largeAttachments = getDraftLargeAttachments(draftId)(state)

    const currNumber = largeAttachments.length + files.length
    if (currNumber > maxNumber) {
      dispatch(
        showNotification(
          i18next.t('largeAttachment.error.exceedMaxFileNumber', { maxNumber }),
          toastVariants.error,
        ),
      )
      return
    }

    if (
      dispatch(checkRemainingSizeAndShowFileStoragePaywall(currFileTotalSize))
    ) {
      return
    }

    const { largeAttachmentUuids, messageId } = getDraft(draftId)(state)
    //force save draft if not saved
    if (!messageId) {
      dispatch(flushSaveDraft(draftId))
    }

    const newLargeAttachments = files.map(file => ({
      file,
      name: file.name,
      //list in inbox sort by create date
      date: moment().unix(),
      contentType: file.type,
      size: file.size,
      parts: [],
      chunkSize: 0,
      uuid: uuid(),
      status: 'CREATING',
    }))
    batch(() => {
      dispatch(
        updateDraft({
          id: draftId,
          largeAttachmentUuids: largeAttachmentUuids.concat(
            newLargeAttachments.map(item => item.uuid),
          ),
        }),
      )
      dispatch(uploadLargeAttachments(draftId, newLargeAttachments))
    })
  }
}

export function removeDraftAttachment(draftId, attachmentUuid) {
  return async (dispatch, getState) => {
    const disabled = isDraftDisabled(draftId)(getState())
    if (disabled) return

    const attachment = getAttachment(attachmentUuid)(getState())

    !attachment.id && attachment.xhr && attachment.xhr.abort()

    const { attachmentUuids } = getDraft(draftId)(getState())
    dispatch(
      updateDraft({
        id: draftId,
        attachmentUuids: attachmentUuids.filter(
          item => item !== attachmentUuid,
        ),
      }),
    )
    attachment.id && dispatch(debouncedSaveDraft(draftId))
  }
}

export function removeDraftLargeAttachment(draftId, attachmentUuid) {
  return async (dispatch, getState) => {
    const disabled = isDraftDisabled(draftId)(getState())
    if (disabled) return

    dispatch(discardUpload(draftId, attachmentUuid))
  }
}

export const batchDeleteDraftActions: {
  request: ActionCreator<BatchDeleteDraftRequest>,
  success: ActionCreator<BatchDeleteDraftSuccess>,
  failure: ActionCreator<BatchDeleteDraftFailure>,
} = {
  request: createAction('DRAFT_BATCH_DELETE_REQUEST'),
  success: createAction('DRAFT_BATCH_DELETE_SUCCESS'),
  failure: createAction('DRAFT_BATCH_DELETE_FAILURE'),
}

export function batchDeleteDrafts(
  threadIds: $ReadOnlyArray<string>,
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    const drafts = getDrafts()(state).filter(item =>
      threadIds.includes(item.threadId),
    )
    const draftIds = drafts.map(item => item.id)

    const debouncePromises = draftIds
      .filter(item => promises[item])
      .map(item => promises[item])

    let changedThreads = []

    threadIds.forEach(item => {
      const drafts = getThreadDraftMessageIds(item)(state)

      // Diff the view labels for the changed draft
      const changedThread = getDraftViewLabels(
        item,
        {
          added: [],
          removed: drafts,
        },
        { getState },
      )
      changedThreads.push(changedThread)
    })

    const attachmentUuids = drafts
      .map(item => item.attachmentUuids)
      .reduce((prev, curr) => [...prev, ...curr], [])
    const attachments = getAttachments(attachmentUuids)(state)
    const largeAttachmentUuids = drafts
      .map(item => item.largeAttachmentUuids)
      .reduce((prev, curr) => [...prev, ...curr], [])

    attachments.forEach(item => item.xhr && item.xhr.abort())
    largeAttachmentUuids.length &&
      dispatch(discardUploads(largeAttachmentUuids))

    try {
      dispatch(
        batchDeleteDraftActions.request({
          draftIds,
        }),
      )
      await Promise.all(debouncePromises)
      const threadMessageIds = {}
      threadIds.forEach(threadId => {
        threadMessageIds[threadId] =
          getThreadNotDraftMessageIds(threadId)(getState())
      })
      const messageIds = threadIds
        .map(item => getThreadDraftMessageIds(item)(getState()))
        .reduce((prev, curr) => [...prev, ...curr], [])

      await client.messages.batchDelete({ messageIds }, { auth })
      dispatch(
        batchDeleteDraftActions.success({ threadMessageIds, changedThreads }),
      )
    } catch (e) {
      dispatch(batchDeleteDraftActions.failure({ message: e.message }))
    }
  }
}

const MAX_RECIPIENT_COUNT = 100
export function checkAndSendDraft(draftId, onGoToSettings): ThunkAction {
  return (dispatch, getState) => {
    const state = getState()
    const draft = getDraft(draftId)(state)
    const { to = [], cc = [], bcc = [] } = draft || {}

    const allRecipients = [...to, ...cc, ...bcc]

    if (!allRecipients.length) {
      dispatch(
        showNotification(
          i18next.t('compose.send.error.noRecipient'),
          toastVariants.error,
        ),
      )
      return
    }

    if (allRecipients.some(item => !isEmail(item.email))) {
      dispatch(
        showNotification(
          i18next.t('compose.send.error.wrongRecipient'),
          toastVariants.error,
        ),
      )
      return
    }

    const {
      to: finalTo,
      cc: finalCc,
      bcc: finalBcc,
    } = formatComposeRecipients({ to: draft.to, cc: draft.cc, bcc: draft.bcc })
    const allFormattedComposeRecipients = [...finalTo, ...finalCc, ...finalBcc]

    if (allFormattedComposeRecipients.length > MAX_RECIPIENT_COUNT) {
      dispatch(
        showNotification(
          i18next.t('compose.send.error.exceedMaxRecipientCount', {
            maxCount: MAX_RECIPIENT_COUNT,
          }),
          toastVariants.error,
        ),
      )
      return
    }
    if (isHTMLEmpty(draft.html) && !draft.subject.trim()) {
      if (
        !window.confirm(i18next.t('compose.send.confirm.emptyBodyOrSubject'))
      ) {
        return
      }
    }

    if (hasDraftAttachmentSizeExceed(draftId)(state)) {
      dispatch(
        showModal({
          key: modalTypes.error,
          props: {
            message: i18next.t('compose.send.error.exceedMaxSize', {
              maxSize: filesize(MAX_ATTACHMENT_TOTAL_SIZE),
            }),
          },
        }),
      )
      return
    }

    if (
      dispatch(
        checkRemainingSizeAndShowFileStoragePaywall(
          getDraftSize(draftId)(state),
        ),
      )
    ) {
      return
    }
    const from = getDraftFromEmail(draftId)(state)

    if (from) {
      const fromDomain = from.split('@')[1]

      const domain = getCurrentDomain(state)
      if (domain && fromDomain === domain.domain && !domain.verified) {
        dispatch(
          showModal({
            key: modalTypes.customDomainAliasNotReady,
            props: {
              email: from,
              onGoToSettings,
            },
          }),
        )
        return
      }
    }

    const largeAttachments = getDraftLargeAttachments(draftId)(state)
    const attachments = getDraftRealAttachments(draftId)(state)

    if (
      largeAttachments.some(hasLargeAttachmentError) ||
      attachments.some(hasAttachmentError)
    ) {
      dispatch(
        showModal({
          key: modalTypes.confirm,
          props: {
            message: i18next.t('compose.send.confirm.errorAttachment'),
            onSure: () => {
              dispatch(sendDraftFlow(draftId, true))
            },
          },
        }),
      )
    } else if (
      !loadSendWithUploadingAttachmentTip() &&
      (largeAttachments.some(item => !hasLargeAttachmentComplete(item)) ||
        attachments.some(item => !hasAttachmentComplete(item)))
    ) {
      dispatch(
        showModal({
          key: modalTypes.sendWithUplodingAttachment,
          props: {
            onSure: isShowAgain => {
              if (isShowAgain) {
                persistSendWithUploadingAttachmentTip(true)
              }
              dispatch(sendDraftFlow(draftId, false))
            },
          },
        }),
      )
    } else {
      dispatch(sendDraftFlow(draftId, false))
    }
  }
}

export function getDraftByLargeAttachmentUuid(attachmentUuid): ThunkAction {
  return (dispatch, getState) => {
    const drafts = getDrafts()(getState())
    return drafts.find(
      item =>
        item.largeAttachmentUuids.includes(attachmentUuid) &&
        !item.id.startsWith('new_'),
    )
  }
}

export function insertLargeAttachments(
  draftId: string,
  attachments: Array<LargeAttachemnt>,
): ThunkAction {
  return (dispatch, getState) => {
    batch(() => {
      const formattedLargeAttachments = attachments.map(item => ({
        ...item,
        status: largeAttachmentStatus.DONE,
        scanStatus: largeAttachmentScanStatus.CLEAN,
        parts: [{}],
        chunkSize: item.size,
        uuid: uuid(),
      }))

      dispatch(addUploads(formattedLargeAttachments))
      const { largeAttachmentUuids } = getDraft(draftId)(getState())
      dispatch(
        updateDraft({
          id: draftId,
          largeAttachmentUuids: largeAttachmentUuids.concat(
            formattedLargeAttachments.map(item => item.uuid),
          ),
        }),
      )
      dispatch(debouncedSaveDraft(draftId))
    })
  }
}

export function insertAttachments(
  draftId: string,
  attachments: Array<Attachment>,
): ThunkAction {
  return (dispatch, getState) => {
    batch(() => {
      const state = getState()
      const { attachmentUuids } = getDraft(draftId)(state)
      const formattedLargeAttachments = attachments.map(item => ({
        ...item,
        uuid: uuid(),
      }))
      dispatch(addAttachments(formattedLargeAttachments))

      dispatch(
        updateDraft({
          id: draftId,
          attachmentUuids: attachmentUuids.concat(
            formattedLargeAttachments.map(item => item.uuid),
          ),
        }),
      )
      dispatch(debouncedSaveDraft(draftId))
    })
  }
}

export function getOriginalDraftIdAction(id: string): ThunkAction {
  return (dispatch, getState) => {
    if (id === 'new') return `new_${Date.now()}`

    const state = getState()
    const drafts = getDrafts()(state)
    const originalDraft = drafts.find(
      item => item.threadId === id && item.id.startsWith('new_'),
    )
    if (originalDraft) return originalDraft.id

    return id
  }
}

/**
 * Diffs the view labels for the changed draft which is using to calculate
 * the unread and total count.
 *
 */
function getDraftViewLabels(
  threadId: string,
  changedMessages: {
    added: $ReadOnlyArray<string>,
    removed: $ReadOnlyArray<string>,
  },
  { getState },
) {
  const state = getState()
  const labelsMeta = getLabelState(state)
  const messagesMeta = getMessagesState()(state)
  const threadMessagesMeta = getThreadMessagesState()(state)
  const { added = [], removed = [] } = changedMessages

  const prevMessageIds = get(threadMessagesMeta, `${threadId}.messageIds`, [])
  const currMessageIds = [
    ...prevMessageIds.filter(id => !removed.includes(id)),
    ...added,
  ]

  const [prevLabelIds, currLabelIds] = [prevMessageIds, currMessageIds].map(
    messageIds =>
      messageIds.flatMap(id => {
        if (id in messagesMeta) {
          return messagesMeta[id].labelIds
        } else if (added.includes(id)) {
          // Populate the label for the new draft
          return [labelNames.drafts]
        } else return []
      }),
  )

  const [prevViewLabels, currViewLabels] = [prevLabelIds, currLabelIds]
    .map(labelIds => getAssignedLabels(labelsMeta, labelIds))
    .map(assigned => Array.from(assigned))

  return {
    threadId,
    prevViewLabels,
    currViewLabels,
  }
}

export function isLargeAttachmentStateAction(draftId, selectedAttachments) {
  const getTotalSize = attachments => {
    return attachments.reduce((prev, curr) => prev + curr.size, 0)
  }
  return (dispatch, getState) => {
    const state = getState()
    const attachments = getDraftRealAttachments(draftId)(state)
    const largeAttachments = getDraftLargeAttachments(draftId)(state)

    if (largeAttachments.length) return true
    if (getTotalSize(attachments) > MAX_ATTACHMENT_TOTAL_SIZE) return true
    if (
      getTotalSize(attachments) + getTotalSize(selectedAttachments) >
      MAX_ATTACHMENT_TOTAL_SIZE
    )
      return true
    return false
  }
}

export function checkSchedulerResult(draftId) {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)

    const { schedulerId, messageId, html } = getDraft(draftId)(getState()) || {}
    const message = getMessage(messageId)(state)
    if (!schedulerId || !message?.sending) return
    const res = await client.schedulers.fetchScheduler(schedulerId, { auth })
    const result = res.result
    if (!result || !result.response) {
      await waitTime(3000)
      return dispatch(checkSchedulerResult(draftId))
    }

    const { status } = JSON.parse(result.response)
    if (status !== 200) {
      batch(() => {
        const largeAttachments = getDraftLargeAttachments(draftId)(state)
        const draftHtml = largeAttachments.length
          ? clearLargeAttachmentPlaceholder(html)
          : html

        if (draftHtml !== html) {
          dispatch(
            updateDraft({
              id: draftId,
              html: draftHtml,
              status: null,
              schedulerId: null,
              saved: false,
            }),
          )
          dispatch(flushSaveDraft(draftId))
        } else {
          dispatch(
            updateDraft({
              id: draftId,
              status: null,
              schedulerId: null,
              saved: true,
            }),
          )
        }

        dispatch(updateMessage({ id: messageId, sending: false }))

        dispatch(
          showNotification(
            i18next.t('compose.send.error.sendError'),
            toastVariants.error,
          ),
        )
      })
    } else {
      dispatch(updateMessage({ id: messageId, sending: false }))
    }
  }
}
