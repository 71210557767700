// @flow
import { useRef } from 'react'
import { useDispatch } from 'react-redux'

export function useOnboardingEvent(action: () => ThunkAction) {
  const checked = useRef(false)
  const dispatch = useDispatch()
  const report = () => {
    if (!checked.current) {
      checked.current = true
      dispatch(action())
    }
  }

  return { report }
}
