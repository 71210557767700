// @flow
import get from 'lodash/get'
import React, { useMemo, useRef } from 'react'
import { useShowCompose } from 'hooks/useHandleDetailModal'
import {
  getContactById,
  isContactSuggestedBlock,
  isContactStatusUpdating,
  isContactUpdateLoading,
} from 'core/contacts/selectors'
import { useSelector, useDispatch } from 'react-redux'
import ContactInfo from '@edison/webmail-ui/screens/ContactDetail/ContactInfo'
import {
  Layout as ActionLayout,
  NewMessageButton,
  MoreButton,
} from '@edison/webmail-ui/screens/ContactDetail/Action'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { contactTypes } from 'utils/constants'
import AuthAvatar, { DomainAvatar } from 'common/AuthAvatar'
import { useModal } from 'common/modals'
import {
  showBlockPrompt,
  enableShowImage,
  showUnblockDomainPrompt,
  showUnblockSenderPrompt,
} from 'core/contacts/actions'

import { show as showModal } from 'core/modals/actions'
import { modalTypes } from 'utils/constants'

import type { Dispatch } from 'types/redux'

export default ({ contactId }) => {
  const dispatch: Dispatch = useDispatch()
  const showCompose = useShowCompose()
  const blockDomain = useModal(modalTypes.blockDomain)
  const contact = useSelector(
    useMemo(() => getContactById(contactId), [contactId]),
  )
  const isContactStatusLoading = useSelector(
    useMemo(() => isContactStatusUpdating(), []),
  )
  const isContactUpdating = useSelector(
    useMemo(() => isContactUpdateLoading(), []),
  )
  const name = [contact.firstName, contact.lastName]
    .map(each => each.trim())
    .filter(Boolean)
    .join(' ')

  const suggestedBlockSelector = useMemo(
    () => isContactSuggestedBlock(contactId),
    [contactId],
  )
  const isSuggestedBlock = useSelector(suggestedBlockSelector)

  const email = get(contact, 'emails[0].email', '')
  const { isDomain, id } = contact
  const initIsDomian = useRef(isDomain)
  const finalyIsDomain = !!(initIsDomian.current || isDomain)
  const [, domain] = email.split('@')
  const blocked = contact.status === contactTypes.BLOCK
  const avatar = isDomain ? (
    <DomainAvatar className="!w-20 !h-20" />
  ) : (
    <AuthAvatar src={contact.avatar} alt={name} />
  )

  const handleNewMessage = () => {
    showCompose(null, encodeURIComponent(email))
  }

  const handleOnDelete = () =>
    dispatch(
      showModal({
        key: modalTypes.deteleContactMethod,
        props: { contactId },
      }),
    )

  function onUnblock(id, email) {
    if (finalyIsDomain) {
      dispatch(showUnblockDomainPrompt(email, id))
    } else {
      dispatch(showUnblockSenderPrompt(email))
    }
  }

  function onBlock(email) {
    if (finalyIsDomain) {
      blockDomain.showModal({
        email,
      })
    } else {
      dispatch(showBlockPrompt(email))
    }
  }

  return (
    <div className="p-4 relative">
      <ContactInfo
        isDomain={finalyIsDomain}
        avatar={avatar}
        name={finalyIsDomain ? `@${domain}` : name}
        email={finalyIsDomain ? '' : email}
        blocked={blocked}
        disabled={isContactStatusLoading || isContactUpdating}
        showBlockAction={!isSuggestedBlock}
        showHideImageAction={contact.enableShowImage}
        onBlock={() => onBlock(email)}
        onUnblock={() => onUnblock(id, email)}
        onHideImages={() => dispatch(enableShowImage(contact, false))}
      />
      {!finalyIsDomain && (
        <ActionLayout>
          <NewMessageButton onClick={handleNewMessage} />
          <MoreButton
            actions={[
              {
                label: 'Edit',
                icon: <EditIcon />,
                onClick: () =>
                  dispatch(
                    showModal({
                      key: modalTypes.editContact,
                      props: { contactId },
                    }),
                  ),
              },
              {
                label: 'Delete',
                icon: <DeleteIcon />,
                onClick: handleOnDelete,
              },
            ]}
          />
        </ActionLayout>
      )}
    </div>
  )
}
