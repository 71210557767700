// @flow
import React, { useMemo, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { batch, useDispatch, useSelector } from 'react-redux'

import ThreadListItem from '@edison/webmail-ui/components/ThreadListItem'
import { ThreadListItem as ThreadListItemSkeleton } from '@edison/webmail-ui/components/Skeletons'
import * as actionNodes from '@edison/webmail-ui/components/ThreadListItem/thread-actions'
import ThreadItemActions from '@edison/webmail-ui/components/ThreadListItem/Actions'
import Checkbox from '@edison/webmail-ui/components/Checkbox'
import { useDragHandler, dragTypes } from '@edison/webmail-ui/hooks/dnd'

import AuthAvatar from 'common/AuthAvatar'
import Labels from 'common/Labels'

import { useShowCompose, useShowThreadDetail } from 'hooks/useHandleDetailModal'
import {
  useThreadActions,
  useSenderName,
  useSenderAvatar,
  useActiveLabel,
} from 'core/threads/hooks'
import * as metadataSelector from 'core/metadata/selectors'
import * as threadsSelector from 'core/threads/selectors'
import {
  batchSelectThread,
  selectThread,
  dragThread,
} from 'core/threads/actions'
import { labelNames } from 'utils/constants'
import {
  dateFormatter,
  hasAttachments,
  hasSifts,
  hasSpamMessages,
  isPlainDraft,
} from 'utils'
import DefaultChips from './Chips'
import Subject from './Subject'
import QuickActionWrapper from './QuickActionWrapper'

import type { Match, RouterHistory, Location } from 'react-router-dom'
import type { Thread } from '@edison/webmail-core/types/threads'
import type { Dispatch } from 'types/redux'
import { getThreadMessages } from 'core/messages/selectors'

type Props = {
  index: number,
  thread: Thread,
  className: string,
  isLoading: boolean,
  registerChild: any,
  style: Object,
  actions: (thread: Thread) => { [actionName: string]: boolean },
  history: RouterHistory,
  location: Location,
  match: Match,
  onSelect: Function,
  onClick?: () => mixed,
  labelDisplayFilter: any,
  enableQuickActionTutorial?: boolean,
  hasCheckBox?: boolean,
  highlightWord?: string,
}

const getSelectedThreadIds = threadsSelector.getSelectedThreadIds()

const View = ({
  registerChild,
  index,
  thread,
  style,
  isLoading,
  history,
  location,
  match,
  actions,
  onSelect,
  onClick,
  className,
  labelDisplayFilter,
  highlightWord,
  hasCheckBox = true,
  enableQuickActionTutorial = false,
}: Props) => {
  const dispatch: Dispatch = useDispatch()
  const showCompose = useShowCompose()
  const showThreadDetail = useShowThreadDetail()
  const { id, snippet, subject, date } = thread

  // Handle the default label here
  const label = match?.params?.label || 'search'

  const threadStatusSelector = useMemo(
    () => threadsSelector.getThreadUnreadStatus(id, label),
    [id, label]
  )
  const isUnread = useSelector(threadStatusSelector)

  const selectedUniverse = useSelector(threadsSelector.isSelectedUniverse)
  const selected = useSelector(getSelectedThreadIds)
  const activeLabel = useActiveLabel()
  const labelIds = useSelector(
    useMemo(() => metadataSelector.getThreadLabelIds(id), [id])
  )
  const messageIds = useSelector(
    useMemo(() => metadataSelector.getThreadMessageIds(id), [id])
  )
  const messages = useSelector(useMemo(() => getThreadMessages(id), [id]))

  const selectedRef = useRef(new Set())
  // DnD
  const { ref } = useDragHandler({
    extraInfo: { id: thread.id },
    type: dragTypes.message,
    enableCustomPreview: true,
    begin: () => {
      batch(() => {
        dispatch(dragThread({ isDragging: true }))
        if (selected.size > 0) {
          selectedRef.current = selected
          if (!selected.has(id)) {
            dispatch(
              batchSelectThread({
                ids: Array.from(selected),
                value: false,
              })
            )
          }
        }
        dispatch(selectThread({ id, value: true }))
      })
    },
    end: () => {
      batch(() => {
        dispatch(dragThread({ isDragging: false }))
        dispatch(
          batchSelectThread({
            ids: Array.from(selected),
            value: false,
          })
        )
        if (selectedRef.current.size > 0) {
          dispatch(
            batchSelectThread({
              ids: Array.from(selectedRef.current),
              value: true,
            })
          )
          selectedRef.current = new Set()
        }
      })
    },
  })

  // Thread avatar
  const avatar = useSenderAvatar(id)
  const isDrafts = labelIds.includes(labelNames.drafts)
  const isUseDefaultAvatar = isDrafts && !avatar

  // Thread senders
  const from = useSenderName(id)

  const getThreadActions = useThreadActions()

  if (isLoading) {
    return (
      <ThreadListItemSkeleton index={index} ref={registerChild} style={style} />
    )
  }

  const threadActionFlags = actions({ ...thread, labelIds })
  const actionOrder = [
    actionNodes.untrash.name,
    actionNodes.notSpam.name,
    actionNodes.unarchive.name,
    actionNodes.archive.name,
    actionNodes.read.name,
    actionNodes.unread.name,
    actionNodes.trash.name,
    actionNodes.deleteForever.name,
    actionNodes.discardDraft.name,
  ]
  const _threadActions = getThreadActions(thread)
  const threadActions = actionOrder
    .filter(name => threadActionFlags[name])
    .map(name => _threadActions[name])

  // $FlowFixMe
  const Chips = getThreadItemChips(label, { messages })
  return (
    <ThreadListItem
      ref={ref}
      highlightWord={highlightWord}
      className={className}
      style={style}
      avatar={
        <QuickActionWrapper
          threadId={id}
          actions={_threadActions}
          actionFlags={threadActionFlags}
          enableQuickActionTutorial={enableQuickActionTutorial}
        >
          <AuthAvatar
            isUseDefaultAvatar={isUseDefaultAvatar}
            src={avatar}
            alt={from.name}
          />
        </QuickActionWrapper>
      }
      fromName={from.name}
      fromAddress={from.email}
      readStatus={!isUnread}
      title={<Subject highlightWord={highlightWord} subject={subject} />}
      snippet={snippet}
      receivedAt={dateFormatter(date)}
      actions={
        threadActions.length ? (
          <ThreadItemActions actions={threadActions} />
        ) : null
      }
      selected={selected.has(id)}
      checkbox={
        hasCheckBox ? (
          <Checkbox
            label={`Select thread: ${subject}`}
            name={`selectThread:${id}`}
            checked={selected.has(id) || selectedUniverse}
            onChange={onSelect}
          />
        ) : null
      }
      showCheckbox={selected.size > 0 || selectedUniverse}
      onClick={() => {
        if (onClick) {
          onClick()
        }

        if (isPlainDraft(labelIds, messageIds.length)) {
          showCompose(id)
        } else {
          showThreadDetail(id)
        }
      }}
      labels={
        <Labels
          threadId={id}
          labelIds={labelIds.filter(
            id =>
              !labelDisplayFilter.includes(id) &&
              id !== labelNames.trash &&
              id !== labelNames.spam &&
              id !== labelNames.archive
          )}
        />
      }
      attachments={!!Chips ? <Chips threadId={id} /> : null}
      showDot={activeLabel !== labelNames.drafts}
      messageCount={messageIds.length}
    />
  )
}

function getThreadItemChips(label: string, thread: Thread) {
  if (hasSpamMessages(thread)) return null
  if (hasSifts(thread) || hasAttachments(thread)) {
    return DefaultChips
  } else return null
}

export default withRouter(View)
