// @flow
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import invert from 'lodash/invert'
import {
  Redirect,
  withRouter,
  useRouteMatch,
  generatePath,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import ThreadListLayout from '@edison/webmail-ui/components/ThreadListLayout'
import ThreadZero from 'screens/InboxZero/ThreadZero'
import BatchActionHeader from 'common/ThreadList/BatchActionHeader'
import ThreadList from 'common/ThreadList'
import ForwardView from 'common/ThreadList/ForwardView'
import { getForwardOpened, updateForwardOpened } from 'common/storage'

import { fetchThreads, batchGetThreads } from 'core/threads/actions'
import {
  isInboxZero,
  isThreadListLoading,
  getSelectedThreadIds,
  getThreadPagination,
} from 'core/threads/selectors'
import {
  useActiveLabel,
  useActiveLabelThreads,
  useThreadListItemActionFlags,
} from 'core/threads/hooks'
import { getAllLabelsState, getTotalCountByLabel } from 'core/labels/selectors'
import { useQuickAction } from 'core/settings/hooks'

import {
  routePaths,
  inboxViews,
  labelNames,
  displayTitles,
  labelRouteNames,
  THREAD_LIST_BATCH_NUM,
} from 'utils/constants'
import { useOrderId } from 'core/auth/hooks'
import { useRetrofitAccounts } from 'core/retrofit/hooks'

import type { Match, RouterHistory, Location } from 'react-router-dom'
import type { Dispatch } from 'types/redux'
import type { Node } from 'react'

type Props = {
  match: Match,
  history: RouterHistory,
  location: Location,
  header: Node,
  cards?: Node,
}

const labelSelector = getAllLabelsState()
const isInboxZeroSelector = isInboxZero()
const selectedThreadsSelector = getSelectedThreadIds()

const View = ({ match, history, location, header, cards }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const [mounted, SetMounted] = useState(false)
  const [forwardOpened, setForwardOpened] = useState(getForwardOpened())
  const isInboxZero = useSelector(isInboxZeroSelector)

  const quickAction = useQuickAction()
  const selected = useSelector(selectedThreadsSelector)
  const label: ?string = match.params.label
  const userId = useOrderId()
  const splitMatch = useRouteMatch(routePaths.splitInbox)
  const splitInboxId: ?string = get(splitMatch, 'params.splitInboxId')
  if (isNil(label)) {
    return (
      <Redirect
        to={generatePath(routePaths.main, { userId, label: 'inbox' })}
      />
    )
  }

  const [scrollToIndex, setScrollToIndex] = useState(undefined)
  const isLoading = useSelector(isThreadListLoading)

  const activeLabel = useActiveLabel()
  const { active: activeRetrofit } = useRetrofitAccounts()

  const { labelName } = getLabelMeta(label)

  const { ids: threadIds, entities: threads } = useActiveLabelThreads()
  const threadPagination = useSelector(getThreadPagination)

  const threadsTotalSelector = useMemo(
    () => getTotalCountByLabel(activeLabel),
    [activeLabel]
  )
  const total = useSelector(threadsTotalSelector)

  const threadsNum = threads.length

  const showForward = useMemo(() => {
    return (
      !forwardOpened &&
      threadsNum === total &&
      threads.filter(t => get(t, 'from[0].email', '') !== 'jeffp@onmail.com')
        .length <= 3 &&
      (activeLabel === labelNames.primary || activeLabel === labelNames.other)
    )
  }, [forwardOpened, threads, activeLabel, isLoading, threadsNum, total])

  const labelDisplayFilter =
    !isNil(splitInboxId) || activeLabel === labelNames.primary
      ? [activeLabel, labelNames.inbox, labelNames.archive]
      : [activeLabel]

  const [isLoadMore, setLoadMore] = useState(false)
  const loadMore = ({
    startIndex,
    stopIndex,
  }: {
    startIndex: number,
    stopIndex: number,
  }) => {
    if (
      stopIndex > 0 &&
      startIndex <= total &&
      threadsNum < total &&
      !isLoading
    ) {
      setLoadMore(true)
    }
  }

  const [debouncedBatchGet, cancelDebounced] = useDebouncedCallback(
    async () => {
      await dispatch(
        fetchThreads(activeLabel, {
          size: THREAD_LIST_BATCH_NUM,
          pageToken: threadPagination.next,
        })
      )
      setLoadMore(false)
    },
    300
  )

  // Fetch threads
  useEffect(() => {
    if (isLoadMore) {
      debouncedBatchGet()
    }
  }, [isLoadMore])

  useEffect(() => {
    if (mounted) {
      dispatch(batchGetThreads(threadIds))
    }
  }, [threadIds])

  useEffect(() => {
    cancelDebounced()
    setLoadMore(false)
    setScrollToIndex(0)
    setTimeout(() => setScrollToIndex(undefined), 200)
  }, [activeLabel, activeRetrofit])

  useEffect(() => {
    SetMounted(true)
  }, [])

  const actions = useThreadListItemActionFlags(splitInboxId)

  const rowTypes = useMemo(
    () =>
      labelName === labelRouteNames[labelNames.priceTracking]
        ? threads.map(thread => 'cards')
        : undefined,
    [threads]
  )

  const forwardAction = useCallback(() => {
    updateForwardOpened()
    setForwardOpened(true)
  })

  return (
    <ThreadListLayout
      hasSelected={selected.size > 0}
      isEmpty={isInboxZero}
      header={header}
      fallback={
        <ThreadZero showForward={showForward} onPress={forwardAction} />
      }
      actions={<BatchActionHeader view={inboxViews.LIST} threads={threads} />}
    >
      {({ setShadow }) => (
        <ThreadList
          //avoid trigger animation when switch label
          key={`${activeLabel}`}
          // INFO: Only enable it when the quick action is not configured
          enableQuickActionTutorial={!quickAction.configured}
          cards={cards}
          actions={actions}
          isLoading={isInboxZero ? false : isLoading}
          total={threadsNum < total ? threadsNum + 5 : total}
          threads={threads}
          loadMore={loadMore}
          scrollToIndex={scrollToIndex}
          labelDisplayFilter={labelDisplayFilter}
          onScroll={({ scrollTop }) => {
            setShadow(scrollTop > 0)
          }}
          rowTypes={rowTypes}
          footer={
            showForward && (
              <ForwardView isBottom={true} onPress={forwardAction} />
            )
          }
        />
      )}
    </ThreadListLayout>
  )
}

function isSmartFolder(label) {
  const smartFolderSet = new Set<string>(
    [
      labelNames.travel,
      labelNames.billAndReceipts,
      labelNames.packages,
      labelNames.events,
      labelNames.priceTracking,
    ].map(label => labelRouteNames[label] || label)
  )
  return smartFolderSet.has(label)
}

function getLabelMeta(label): { labelName: string, displayName: string } {
  const labels = useSelector(labelSelector)
  const invertedLabelNames: { [key: string]: string } = invert(labelNames)

  let [labelName, displayName] = [label, label]

  if (isSmartFolder(label)) {
    displayName = displayTitles[invertedLabelNames[label]]
  } else {
    const { id, name } = get(labels, labelNames[label] || label, {})
    labelName = id
    displayName = name
  }

  return {
    labelName,
    displayName,
  }
}

export default withRouter(View)
