// @flow
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ReplyIcon from '@material-ui/icons/Reply'
import ReplyAllIcon from '@material-ui/icons/ReplyAll'
import ForwardIcon from '@material-ui/icons/Forward'
import IconButton from '@material-ui/core/IconButton'
import { useDispatch, useSelector } from 'react-redux'
import {
  reply,
  forward,
  replyAll,
  printMessage,
  deleteMessage,
  showOriginalMessage,
} from 'core/messages/actions'
import { labelActions } from 'core/metadata/actions'
import {
  isShowReplyAll,
  isShowTrash,
  isShowSpam,
  isEnableThreadAction,
  isEnableMessageAction,
} from 'core/messages/selectors'
import { getMessageThreadId } from 'core/metadata/selectors'
import MoreAction from './MoreAction'
import {
  untrash,
  trash,
  markAsSpam,
  deleteForever,
} from '@edison/webmail-ui/components/ThreadListItem/thread-actions'
import Tooltip from '@edison/webmail-ui/components/Tooltip'

import type { Dispatch } from 'types/redux'

const ACTIONS = {
  reply,
  replyAll,
  forward,
  trash: labelActions.trash.message,
  untrash: labelActions.untrash.message,
  notSpam: labelActions.notSpam.message,
  markAsSpam: labelActions.markAsSpam.message,
  deleteForever: deleteMessage,
}
const MessageAction = ({ messageId, onAction }) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const showReplyAll = useSelector(
    useMemo(() => isShowReplyAll(messageId), [messageId]),
  )
  const showTrash = useSelector(
    useMemo(() => isShowTrash(messageId), [messageId]),
  )

  const showSpam = useSelector(
    useMemo(() => isShowSpam(messageId), [messageId]),
  )
  const threadId = useSelector(
    useMemo(() => getMessageThreadId(messageId), [messageId]),
  )
  const enabled = useSelector(
    useMemo(() => isEnableThreadAction(threadId), [threadId]),
  )
  const messageEnabled = useSelector(
    useMemo(() => isEnableMessageAction(messageId), [messageId]),
  )
  function handleAction(action) {
    dispatch(ACTIONS[action](messageId))
    onAction(action)
  }
  return (
    <div className="whitespace-no-wrap" onClick={e => e.stopPropagation()}>
      {showReplyAll ? (
        <Tooltip title={t('button.replyAll')}>
          <IconButton
            disableRipple
            disableFocusRipple
            disabled={!messageEnabled}
            size="small"
            onClick={() => handleAction('replyAll')}
          >
            <ReplyAllIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t('button.reply')}>
          <IconButton
            disableRipple
            disableFocusRipple
            disabled={!messageEnabled}
            size="small"
            onClick={() => handleAction('reply')}
          >
            <ReplyIcon />
          </IconButton>
        </Tooltip>
      )}
      <MoreAction
        disabled={!messageEnabled || !enabled}
        menus={[
          {
            icon: <ReplyIcon />,
            title: t('button.reply'),
            onClick: () => handleAction('reply'),
          },
          showReplyAll
            ? {
                icon: <ReplyAllIcon />,
                title: t('button.replyAll'),
                onClick: () => handleAction('replyAll'),
              }
            : null,
          {
            icon: <ForwardIcon />,
            title: t('button.forward'),
            onClick: () => handleAction('forward'),
          },

          showTrash && showSpam
            ? {
                ...trash,
                onClick: () => handleAction('trash'),
              }
            : null,
          !showTrash
            ? {
                ...untrash,

                onClick: () => handleAction('untrash'),
              }
            : null,

          showSpam && showTrash
            ? {
                ...markAsSpam,

                onClick: () => handleAction('markAsSpam'),
              }
            : null,

          {
            title: t('message.action.showOriginal'),
            onClick: () => dispatch(showOriginalMessage(messageId)),
            advanced: true,
          },
          {
            title: t('message.action.print'),
            onClick: () => dispatch(printMessage(messageId)),
            advanced: true,
          },
          {
            ...deleteForever,
            onClick: () => handleAction('deleteForever'),
            advanced: true,
          },
        ].filter(Boolean)}
      />
    </div>
  )
}
export default MessageAction
