// @flow
import i18next from 'i18next'
import findLast from 'lodash/findLast'
import first from 'lodash/first'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import uniqBy from 'lodash/uniqBy'
import uniqWith from 'lodash/uniqWith'
import { createSelector } from 'reselect'
import { convertAttachmentToICSItem, getAvatarUrl } from 'utils'
import { labelNames } from 'utils/constants'

import { CONTENT_DISPOSITION_INLINE } from '@edison/webmail-core/utils/constants'
import { getAuth } from 'core/auth/selectors'
import { getContactsIndexedByEmail } from 'core/contacts/selectors'
import { getCustomLabels, getLabelTotalCount } from 'core/labels/selectors'
import { getLoadingStatus } from 'core/loading/selectors'
import { getMessageState, getThreadMessages } from 'core/messages/selectors'
import {
  getMessagesState as getMetadataMessages,
  getThreadLabelIds,
  getThreadLabelIdsMap,
  getThreadMessagesState,
  getViewThreadsState,
  sortThreads,
} from 'core/metadata/selectors'
import {
  convertAttachmentToAttachmentItem,
  convertLargeAttachmentToAttachmentItem,
} from 'utils'

import type { Attachment } from '@edison/webmail-core/types/attachment'
import type { Label } from '@edison/webmail-core/types/labels'
import type { MessageLargeAttachment } from '@edison/webmail-core/types/messages'
import type { Thread } from '@edison/webmail-core/types/threads'
import type {
  MessagesState,
  ThreadMessagesState,
  ViewThreadsState,
} from 'core/metadata/types'
import { getIsExistQuery } from 'core/search/selectors'
import type { PaginationState } from 'types/redux'
import type { Selector, State } from 'types/state'
import { isStringEqual } from '../../utils'
import { isAttachmentICS } from '../../utils/calendar'
import type { State as ThreadState } from './reducers'

export const getThreadsState = (state: State) => state.threads

export const getFetchedThreadIds: Selector<
  $ReadOnlyArray<string>
> = createSelector(getThreadsState, state => state.ids)

// Returns the thread entity indexed by ID
export const getThreadsById: Selector<{
  [id: string]: Thread,
}> = createSelector(
  getThreadsState,
  getThreadMessagesState(),
  getMessageState(),
  getMetadataMessages(),
  (state, threadMessages, messages, messagesMeta) =>
    mapValues(state.entities, thread => {
      // Connect message entities from the message reducer by using metadata
      // as the relationship table.
      const msgs = get(threadMessages, `${thread.id}.messageIds`, [])
        .map(id => messages[id])
        .filter(Boolean)

      const date = Math.max(
        ...msgs
          .map(({ id, date }) => get(messagesMeta, `${id}.date`) || date)
          .filter(Boolean),
        thread.date
      )

      return {
        ...thread,
        date,
        messages: msgs,
      }
    })
)

export const getThreadAttachments = (threadId: string) => {
  return createSelector(getThreadMessages(threadId), messages => {
    const attachments = messages
      .map(message => {
        return [
          ...message.attachments
            .filter(
              item => item.contentDisposition !== CONTENT_DISPOSITION_INLINE
            )
            .map(attachment => {
              if (isAttachmentICS(attachment)) {
                return convertAttachmentToICSItem(attachment, message)
              } else {
                return convertAttachmentToAttachmentItem(attachment, message.id)
              }
            }),
          ...message.largeAttachments.map(largeAttachment => ({
            ...convertLargeAttachmentToAttachmentItem(largeAttachment),
            message: message.id,
          })),
        ]
      })
      .reduce((prev, curr) => [...prev, ...curr], [])
    return [...attachments]
  })
}
export const getAttachmentsByThreadId: Selector<{
  [threadId: string]: $ReadOnlyArray<Attachment & { messageId: string }>,
}> = createSelector(getThreadsById, byId =>
  mapValues(byId, thread =>
    uniqBy(
      get(thread, 'messages', []).flatMap(message =>
        get(message, 'attachments', []).map(item => ({
          ...item,
          messageId: message.id,
        }))
      ),
      'id'
    )
  )
)

export const getLargeAttachmentsByThreadId: Selector<{
  [threadId: string]: $ReadOnlyArray<
    MessageLargeAttachment & { messageId: string }
  >,
}> = createSelector(getThreadsById, byId =>
  mapValues(byId, thread =>
    uniqBy(
      get(thread, 'messages', []).flatMap(message =>
        get(message, 'largeAttachments', []).map(item => ({
          ...item,
          messageId: message.id,
        }))
      ),
      'aid'
    )
  )
)

export const isThreadListLoading = getLoadingStatus('THREAD_LIST')
export const isBatchGetLoading = getLoadingStatus('THREAD_BATCH_GET')
export const isFeedViewLoading = getLoadingStatus('THREAD_FEED_VIEW')

const _getSelectedThreaedIds: Selector<Set<string>> = createSelector(
  getThreadsState,
  (state: ThreadState) => {
    return new Set<string>([...state.selectedThreadIds])
  }
)
export function getSelectedThreadIds(): Selector<Set<string>> {
  return _getSelectedThreaedIds
}

export const isSelectedUniverse: Selector<boolean> = createSelector(
  getThreadsState,
  (state: ThreadState) => state.selectedUniverse
)

const _getActiveLabel: Selector<string> = createSelector(
  getThreadsState,
  (state: ThreadState) => state.activeLabel
)

export function getActiveLabel(): Selector<string> {
  return _getActiveLabel
}

export function isInboxZero(): Selector<boolean> {
  return createSelector(
    getActiveLabel(),
    getLabelTotalCount(),
    (activeLabel: string, labelTotal: { [labelId: string]: number }) =>
      get(labelTotal, activeLabel, 0) === 0
  )
}

export function getSelectedUniqueLabels(): Selector<$ReadOnlyArray<string>> {
  return createSelector(
    getThreadsState,
    getSelectedThreadIds(),
    getCustomLabels(),
    getThreadLabelIdsMap(),
    (
      threads: ThreadState,
      selectedIds: Set<string>,
      labels: Array<Label>,
      threadLabelIds: { [threadId: string]: $ReadOnlyArray<string> }
    ) => {
      const threadLabels = Array.from(selectedIds)
        .map(id => new Set(threadLabelIds[id]))
        .filter(labelIds => labelIds.size)

      return labels
        .filter(label => threadLabels.every(labelIds => labelIds.has(label.id)))
        .map(label => label.id)
    }
  )
}

export const getActiveLabelThreadIds: Selector<
  $ReadOnlyArray<string>
> = createSelector(
  getActiveLabel(),
  getViewThreadsState(),
  getThreadMessagesState(),
  getMetadataMessages(),
  (
    activeLabel: string,
    viewThreadsState: ViewThreadsState,
    threadMessagesState: ThreadMessagesState,
    messagesState: MessagesState
  ) => {
    const threads = sortThreads(
      get(viewThreadsState, activeLabel),
      threadMessagesState,
      messagesState
    )
    return threads
  }
)

const _getSendersById: Selector<{
  [id: string]: $ReadOnlyArray<{
    name: string,
    email: string,
  }>,
}> = createSelector(
  getThreadsById,
  getMetadataMessages(),
  (byId, messageById) => {
    return mapValues(byId, thread => {
      const source = thread.messages.flatMap(message => {
        const labelIds = get(messageById, `${message.id}.labelIds`, [])
        if (
          labelIds.includes(labelNames.sent) ||
          labelIds.includes(labelNames.drafts)
        ) {
          return [...message.to, ...message.cc, ...message.bcc]
        } else return [message.from]
      })
      return uniqWith(source, (a, b) => {
        const uniqA = [a.name || '', a.email || '']
          .map(each => (each || '').trim().toLocaleLowerCase())
          .join(' ')
        const uniqB = [b.name || '', b.email || '']
          .map(each => (each || '').trim().toLocaleLowerCase())
          .join(' ')

        return uniqA === uniqB
      })
    })
  }
)

// Returns the thread senders to show, indexed by ID
export const getSendersById: Selector<{
  [id: string]: { name: string, email: string },
}> = createSelector(getAuth(), _getSendersById, (auth, sendersByid) => {
  const self = get(auth, 'user')
  return mapValues(sendersByid, source => {
    if (source.length === 1) {
      let { name, email } = source[0]
      if (!name.trim()) {
        name = email.split('@')[0]
      }

      return { name, email }
    }

    if (source.length === 0) {
      return { name: i18next.t('thread.recipient.none'), email: '' }
    }

    // Multiple senders
    const names = source
      .reduce((prev, curr, index) => {
        // Only show the first, second and the last name source
        if (prev.length < 2 || index === source.length - 1) {
          return [...prev, curr]
        } else return prev
      }, [])
      .map(item => {
        // Set self email address as me
        if (
          !!self &&
          item.email.trim().toLocaleLowerCase() === self.toLocaleLowerCase()
        )
          return 'me'

        const [name] = !!item.name.trim()
          ? item.name.trim().split(' ')
          : item.email.split('@')
        return name
      })

    const name =
      source.length > 3
        ? `${names.join(', ')} & ${source.length - 3} others`
        : names.join(', ')

    return { name, email: '' }
  })
})

// Returns the avatar URL to use for a single thread
export const getSenderAvatarById: Selector<{
  [id: string]: string,
}> = createSelector(
  _getActiveLabel,
  getThreadsById,
  getMetadataMessages(),
  _getSendersById,
  getContactsIndexedByEmail(),
  (activeLabel, byId, messageById, sendersById, contacts) => {
    function getAvatar(email) {
      const avatar = get(contacts[email], 'avatar')

      return !!avatar ? avatar : getAvatarUrl(email)
    }

    function getRecipients(message) {
      return [...message.to, ...message.cc, ...message.bcc]
        .filter(Boolean)
        .filter(item => !!item.email)
    }

    const isInSentOrDrafts =
      activeLabel === labelNames.sent || activeLabel === labelNames.drafts

    return mapValues(byId, thread => {
      if (!isInSentOrDrafts) {
        // INFO: For threads not in sent or drafts, use the avatar from the first sender
        const sender = get(first(sendersById[thread.id]), 'email', '')
        return getAvatar(sender)
      } else {
        // INFO: For threads in sent or drafts, use the first recipient of the last message
        const message = findLast(thread.messages, message => {
          const labels = get(messageById, `${message.id}.labelIds`, [])

          if (
            labels.includes(labelNames.sent) ||
            labels.includes(labelNames.drafts)
          ) {
            const recipients = getRecipients(message)

            return recipients.length > 0
          }

          return false
        })

        if (message) {
          const recipient = first(getRecipients(message))
          return getAvatar(recipient.email)
        } else return ''
      }
    })
  }
)

export const isThreadDragging: Selector<boolean> = createSelector(
  getThreadsState,
  state => state.isDragging
)

/**
 * Select the first recipient excluding the user themself
 *
 */
export function getThreadDefaultRecipient(
  threadId: string
): Selector<?{ email: string, name: string }> {
  return createSelector(getAuth(), _getSendersById, (auth, sendersById) => {
    const self = get(auth, 'user')

    const senders = sendersById[threadId] || []

    let recipient = senders[0]

    if (
      recipient &&
      isStringEqual(recipient.email, self) &&
      senders.length > 1
    ) {
      recipient = senders[1]
    }

    return recipient
  })
}

const getThreadsEntitiesSelector = createSelector(
  getThreadsState,
  state => state.entities
)

export const getThreadIdsMessages = threadIds => {
  return createSelector(
    getThreadMessagesState(),
    getMessageState(),
    (threadMessages, messages) => {
      const res = {}
      threadIds.forEach(id => {
        res[id] = get(threadMessages, `${id}.messageIds`, [])
          .map(id => messages[id])
          .filter(Boolean)
      })
      return res
    }
  )
}

export const getActiveLabelThreadEntities: Selector<
  $ReadOnlyArray<Thread>
> = createSelector(
  getActiveLabelThreadIds,
  getThreadsEntitiesSelector,
  (labelThreads, entities) =>
    labelThreads.map(threadId => entities[threadId]).filter(Boolean)
)

export function getThreadsByIds(
  ids: $ReadOnlyArray<string>
): Selector<$ReadOnlyArray<Thread>> {
  return createSelector(getThreadsEntitiesSelector, entities => {
    return ids.map(threadId => entities[threadId]).filter(Boolean)
  })
}

export function getThread(threadId: string): Selector<Thread> {
  return createSelector(getThreadsState, state => state.entities[threadId])
}

export const getThreadPagination: Selector<PaginationState> = createSelector(
  getThreadsState,
  getLabelTotalCount(),
  (state, labelTotal) => ({
    ...state.pagination,
    total: labelTotal[state.activeLabel] || 0,
  })
)

export function getThreadUnreadStatus(
  threadId: string,
  label: string
): Selector<boolean> {
  return createSelector(
    getActiveLabel(),
    getThreadLabelIds(threadId),
    getIsExistQuery(),
    (activeLabel, labelIds, isExistQuery) => {
      if (label !== 'search') {
        return activeLabel === labelNames.drafts
          ? true
          : labelIds.includes(labelNames.unread)
      }
      return activeLabel === labelNames.drafts
        ? !isExistQuery
        : labelIds.includes(labelNames.unread)
    }
  )
}

export const getThreadListRequestId: Selector<?string> = createSelector(
  getThreadsState,
  state => state.requestIds.list
)
