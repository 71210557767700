import React, { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import SendSnackbar from '@edison/webmail-ui/components/SendSnackbar'
import { getDraft } from 'core/compose/selectors'
import { composeStatus } from 'utils/constants'
import { useTranslation } from 'react-i18next'

export default ({ draftId, ...rest }) => {
  const { t } = useTranslation()
  const { status } = useSelector(
    useMemo(() => getDraft(draftId) || {}, [draftId])
  )
  const needTip = status === composeStatus.sending

  useEffect(() => {
    if (needTip) {
      let uploadEvent
      window.addEventListener(
        'beforeunload',
        (uploadEvent = e => {
          e.preventDefault()
          e.returnValue = t('compose.beforeUnload')
          return t('compose.beforeUnload')
        })
      )
      return () => {
        window.removeEventListener('beforeunload', uploadEvent)
      }
    }
  }, [needTip])

  return <SendSnackbar {...rest} />
}
