// @flow
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import isNil from 'lodash/isNil'
import head from 'lodash/head'
import get from 'lodash/get'
import { useQueryParams } from 'common/url'
import {
  logoutKey,
  csrfKey,
  getLogoutInfo,
  getCsrfDiffInfo,
} from 'common/storage'
import { createIntervalJob } from 'utils/intervalJob'
import { betaFeatures } from '@edison/webmail-core/utils/constants'
import { retrofitAccountFilter, routePaths } from 'utils/constants'

import { Helmet } from 'react-helmet'
import MobileSwitch from '@edison/webmail-ui/components/MobileSwitch'
import MobileWall from '@edison/webmail-ui/components/MobileWall'
import InitialAnimation from '@edison/webmail-ui/components/InitialAnimation'
import { useMobileWall } from '@edison/webmail-ui/hooks/responsive'
import { useDragProvider } from '@edison/webmail-ui/hooks/dnd'
import Castle from '@edison/webmail-core/castle'
import DragLayer from 'screens/DragLayer'

import { useSession } from 'core/auth/hooks'
import {
  useRetrofitAccounts,
  useRetrofitSyncProgress,
} from 'core/retrofit/hooks'
import { useNewUserFlag, useUserTutorial } from 'core/flags/hooks'
import { UserTutorialContextProvider } from 'core/flags/context'
import { useBetaFeature } from 'core/beta-features/hooks'
import { useCurrentAssets, useDomain } from 'core/custom-domains/hooks'
import { getSession } from 'core/auth/actions'
import { fetchSyncStatus } from 'core/retrofit/actions'
import { fetchBetaFeatures } from 'core/beta-features/actions'
import { fetchCustomDomainAssets } from 'core/custom-domains/actions'
import { getCurrentDomainAssetsLoading } from 'core/custom-domains/selectors'
import { getAuth, isAuthenticated } from 'core/auth/selectors'
import * as settingsSelectors from 'core/settings/selectors'
import * as retrofitSelectors from 'core/retrofit/selectors'
import * as analytics from 'core/analytics'
import { useHistoryStep } from 'hooks/useGoBack'
import {
  useAppInitialization,
  useRefreshInitialization,
} from 'core/initialization/hooks'
import * as contactsActions from 'core/contacts/actions'

import type { Node, AbstractComponent } from 'react'
import type { Dispatch } from 'types/redux'
import { useShowUnreadIcon } from '../../core/hooks'

export function withAssets<T>(
  WrappedComponent: AbstractComponent<T>
): AbstractComponent<T> {
  return (props: T) => {
    const dispatch: Dispatch = useDispatch()
    const { t } = useTranslation()
    const showUnreadIcon = useShowUnreadIcon()
    const assets = useCurrentAssets({
      iconUrl: showUnreadIcon ? t('favIcon.unread') : t('favIcon.default'),
    })

    const isAssetsLoading = useSelector(getCurrentDomainAssetsLoading)
    const { isInOnmail } = useDomain()

    useEffect(() => {
      // Don't do the request
      // If the page is open in onmail
      if (!isInOnmail) {
        dispatch(fetchCustomDomainAssets())
      }
    }, [])

    return (
      <>
        <Helmet defaultTitle={t('defaultTitle')}>
          <title>{assets.companyName}</title>
          {!isAssetsLoading && (
            <link rel="shortcut icon" href={assets.iconUrl} />
          )}
        </Helmet>
        <WrappedComponent {...props} />
      </>
    )
  }
}

export function withInitialization<T>(
  WrappedComponent: AbstractComponent<T>
): AbstractComponent<T> {
  return (props: T) => {
    const { isInitialized, initialize } = useAppInitialization()
    const shouldHide = useMobileWall()
    const auth = useSelector(useMemo(() => getAuth(), []))
    const isAuth = useSelector(useMemo(() => isAuthenticated(), []))
    const isSettingsLoaded = useSelector(
      useMemo(() => settingsSelectors.isSettingsLoaded(), [])
    )
    const settingsProfile = useSelector(
      useMemo(() => settingsSelectors.getProfileInSettings(), [])
    )
    const [animationPlaying, setAnimationPlaying] = useState(!isInitialized)
    const onAnimationComplete = useCallback(() => {
      setAnimationPlaying(false)
    }, [])
    useEffect(() => {
      if (isAuth && !shouldHide && !isInitialized) {
        //todo: handle the error
        initialize()
      }
    }, [isAuth])
    if ((isInitialized && !animationPlaying) || shouldHide)
      return <WrappedComponent {...props} />

    const name =
      head(
        get(settingsProfile, 'senderName', '')
          .split(' ')
          .map(each => each.trim())
          .filter(Boolean)
      ) || head(get(auth, 'user', '').split('@'))

    return (
      <InitialAnimation
        isLoading={!isSettingsLoaded}
        name={name}
        onAnimationComplete={onAnimationComplete}
      />
    )
  }
}

export function withRefreshAuth<T>(
  WrappedComponent: AbstractComponent<T>
): AbstractComponent<{| ...T, domainProtected: boolean |}> {
  return ({ domainProtected, ...rest }) => {
    const { isInitialized, initialize } = useRefreshInitialization()
    const isAuth = useSelector(useMemo(() => isAuthenticated(), []))

    useEffect(() => {
      if (isAuth) {
        return
      } else {
        initialize({ domainProtected })
      }
    }, [])

    return isInitialized ? <WrappedComponent {...rest} /> : null
  }
}

export function withStorageListener<T>(
  WrappedComponent: AbstractComponent<T>
): AbstractComponent<T> {
  return (props: T) => {
    const { accounts, onLogout } = useSession()

    // Listen the changes from localStorage
    const eventHandler = useCallback(
      e => {
        if (!document.hasFocus()) {
          if (e.key === logoutKey) {
            const logoutInfo = getLogoutInfo()
            // Find out which account was logged out recently, and logout
            // of that account
            //
            // There's a chance that all accounts were logged out too, in that
            // case just remove all.
            if (isNil(logoutInfo) || logoutInfo.orderId === 'all') {
              window.history.go(routePaths.logoutAll)
            }
          } else if (e.key === csrfKey) {
            const diff = getCsrfDiffInfo(e.oldValue, e.newValue)
            // Only logout the account when delete the CSRF token in localstorage
            //
            // !!Don't trigger this when modify the CSRF token or add a new one
            if (!isNil(diff) && !(diff.prev && diff.curr)) {
              onLogout(diff.key)
            }
          }
        }
      },
      [accounts]
    )

    useEffect(() => {
      window.addEventListener('storage', eventHandler)

      return () => {
        window.removeEventListener('storage', eventHandler)
      }
    }, [eventHandler])
    return <WrappedComponent {...props} />
  }
}

export function withDomainProtect<T>(
  WrappedComponent: AbstractComponent<T>
): AbstractComponent<T> {
  return (props: T) => {
    const { isInOnmail, onmailDomain } = useDomain()

    useEffect(() => {
      if (!isInOnmail) {
        const next = new URL(window.location.href)
        next.host = `mail.${onmailDomain}`
        window.location.replace(next)
      }
    }, [isInOnmail])

    // If the web page is not open by onmail domain
    // Redirect to login screen
    if (isInOnmail) {
      return <WrappedComponent domainProtected {...props} />
    } else return null
  }
}

export function withMobileWall<T>(
  WrappedComponent: AbstractComponent<T>
): AbstractComponent<T> {
  return (props: T) => {
    return (
      <MobileSwitch mobile={<MobileWall />}>
        <WrappedComponent {...props} />
      </MobileSwitch>
    )
  }
}

export function withBetaFeature<T>(
  feature: string,
  WrappedComponent: AbstractComponent<T>
): AbstractComponent<T> {
  return (props: T) => {
    const dispatch: Dispatch = useDispatch()
    const { isInitialized } = useAppInitialization()
    const { isEnable } = useBetaFeature(feature, true)

    useEffect(() => {
      // Fetch all the beta features when the app is not initialized
      if (!isInitialized) {
        dispatch(fetchBetaFeatures())
      }
    }, [isInitialized])

    if (!isEnable) return null

    return <WrappedComponent {...props} />
  }
}

/**
 * HOC to sync the ecUUID in redux and query params
 *
 * CASES
 * #1 Active retrofit account in redux is undefined
 *    Set by the ID in query params
 *
 * #2 Active retrofit account in redux is defined
 *    Only update the query params with the active ecUUID in redux
 */
export function withActiveRetrofitAccount<T>(
  WrappedComponent: AbstractComponent<T>
): AbstractComponent<T> {
  return (props: T) => {
    const { retrofit = retrofitAccountFilter.ALL } = useQueryParams()
    const {
      accounts,
      active,
      setActive,
      setActiveQueryParam,
    } = useRetrofitAccounts()

    // Effects onmount
    useEffect(() => {
      // Condition of a valid retrofit
      // 1. Has one connected account at least
      // 2. one of the connected accounts or OnMail
      const isValidRetrofit =
        accounts.length > 0 &&
        (!!accounts.find(account => account.ecUUID === retrofit) ||
          retrofit === retrofitAccountFilter.ONMAIL)

      if (
        !active &&
        isValidRetrofit &&
        retrofit !== retrofitAccountFilter.ALL
      ) {
        setActive(retrofit)
      }
    }, [])

    // Effects on active account or query params changed
    useEffect(() => {
      if (active) {
        if (active.ecUUID !== retrofit) {
          setActiveQueryParam(active.ecUUID)
        }
      } else if (retrofit !== retrofitAccountFilter.ALL) {
        setActiveQueryParam(retrofitAccountFilter.ALL)
      }
    }, [retrofit, active])

    return <WrappedComponent {...props} />
  }
}

export function withRetrofitSyncProgress<T>(
  WrappedComponent: AbstractComponent<T>
): AbstractComponent<T> {
  return (props: T) => {
    const dispatch: Dispatch = useDispatch()
    const isPulling = useSelector(retrofitSelectors.isSyncProgressPulling)

    const retrofitFeature = useBetaFeature(betaFeatures.retrofit)

    useRetrofitSyncProgress({ startSync: retrofitFeature.isEnable })

    const syncHistoryJob = useMemo(
      () =>
        createIntervalJob(() => dispatch(fetchSyncStatus()), {
          delayOnError: true,
          default: 3 * 1000,
          step: 10 * 1000,
          maxRetries: 12,
        }),
      []
    )

    useEffect(() => {
      if (isPulling) {
        syncHistoryJob.addEventListner()
        syncHistoryJob.start()
      }

      return () => {
        syncHistoryJob.removeEventListener()
        syncHistoryJob.stop()
      }
    }, [isPulling])

    return <WrappedComponent {...props} />
  }
}

export function withSuperSession<T>(
  WrappedComponent: AbstractComponent<T>
): AbstractComponent<T> {
  return (props: T) => {
    const dispatch: Dispatch = useDispatch()

    useEffect(() => {
      dispatch(getSession())
    }, [])

    return <WrappedComponent {...props} />
  }
}

export function withCastleFingerPrint<T>(
  WrappedComponent: AbstractComponent<T>
): AbstractComponent<T> {
  return (props: T) => {
    useEffect(() => {
      Castle.init()
    }, [])

    return <WrappedComponent {...props} />
  }
}

export function withFocusListener<T>(
  WrappedComponent: AbstractComponent<T>
): AbstractComponent<T> {
  const dispatch: Dispatch = useDispatch()
  return (props: T) => {
    useEffect(() => {
      const handleEvent = e => dispatch(contactsActions.fetchContacts())
      window.addEventListener('focus', handleEvent)
      return () => window.removeEventListener('focus', handleEvent)
    }, [])
    return <WrappedComponent {...props} />
  }
}

export function withUserTutorial<T>(
  WrappedComponent: AbstractComponent<T>
): AbstractComponent<T> {
  return (props: T) => {
    const newUserFlag = useNewUserFlag()
    const tutorial = useUserTutorial()

    useEffect(() => {
      // INFO: The reason why we setup the tutorial flags here
      // is to be compatible with the cross domain signup
      if (newUserFlag.value) {
        // INFO: Clear new uesr flag
        newUserFlag.clear()

        // INFO: Setup flags for anyplace need to display tutorial UI
        tutorial.setupFlags()
      }
    }, [])

    return <WrappedComponent {...props} />
  }
}

export default ({ children }: { children: Node }) => {
  const history = useHistory()
  const dispatch: Dispatch = useDispatch()

  useEffect(() => {
    dispatch(analytics.actions.initializeFirebase())
    history.listen((e, action) => {
      dispatch(analytics.actions.general.pageView(e.pathname))
    })
  }, [])

  const dragLayer = useMemo(() => <DragLayer />, [])
  const { Provider } = useDragProvider({
    dragLayer,
    options: {
      offsetBias: 10,
    },
  })

  useHistoryStep()

  return (
    <UserTutorialContextProvider>
      <Provider>{children}</Provider>
    </UserTutorialContextProvider>
  )
}
