//@flow

import type { CalendarEventInstanceListResource } from '@edison/webmail-core/types/calendar'
import type { UnixTimestampInSeconds } from './types-common'
import type { ReduxEvent, ReduxEventJSON } from './modal-redux-event'
import momentTimezone from 'moment-timezone'
import moment from 'moment'
import type { CalendarEventsResource } from '@edison/webmail-core/types/calendar'

// $FlowFixMe
import createIntervalTree from 'interval-tree'
// $FlowFixMe
import {
  generateInstanceByRecurringEvent,
  getRBTreeTimePairsFromEvent,
} from '../../utils/calendar'
import { CALENDAR_EVENT_RSVP_STATUS } from '../../utils/constants'
type UndoAddData = {
  type: 'add',
  undoId: string,
  data: { eventId: string, calendarId: string, instanceId?: string },
}
type UndoDeleteData = {
  type: 'delete',
  undoId: string,
  data: {
    eventId: string,
    calendarId: string,
    instanceId?: string,
    eventOrInstanceData: ReduxEvent,
    eventInstancesData?: {
      rrule: string,
      [instanceId: string]: ReduxEvent,
      lastUpdated: number,
    },
  },
}
type UndoModifyData = {
  type: 'modify',
  undoId: string,
  data: {
    eventId: string,
    calendarId: string,
    instanceId?: string,
    eventData: ReduxEvent,
    instancesData?: {
      rrule: string,
      [instanceId: string]: ReduxEvent,
      lastUpdated: number,
    },
  },
}
type UndoUpdateAndAddData = {
  type: 'updateAndAdd',
  undoId: string,
  data: {
    calendarId: string,
    updateEventId: string,
    newEventId: string,
    updateEvent: ReduxEvent,
    updateEventInstancesData: {
      rrule: string,
      [instanceId: string]: ReduxEvent,
      lastUpdated: number,
    },
  },
}
type UndoUpdateRecurringEventExDateAndInstanceData = {
  type: 'updateRecurringEventExDateAndInstance',
  undoId: string,
  data: {
    calendarId: string,
    eventId: string,
    instanceId: string,
    event: ReduxEvent,
    instance: ReduxEvent,
  },
}
type UndoData =
  | UndoAddData
  | UndoDeleteData
  | UndoModifyData
  | UndoUpdateAndAddData
  | UndoUpdateRecurringEventExDateAndInstanceData
type ReduxEventsProp = {
  [calendarId: string]: {
    [eventId: string]: ReduxEvent,
    lastUpdated: UnixTimestampInSeconds,
  },
}
type ReduxInstancesProp = {
  [calendarId: string]: {
    [recurringEventId: string]: {
      rrule: string,
      [instanceId: string]: ReduxEvent,
      lastUpdated: UnixTimestampInSeconds,
    },
  },
}
export type ReactUIEventsData = {
  eventsHash: string,
  events: { [calendarId_eventOrInstanceId: string]: ReduxEvent },
  eventsTree: any,
  getEventsInRange: ({
    start: UnixTimestampInSeconds,
    end: UnixTimestampInSeconds,
    currentTimeZone: string,
  }) => ReduxEvent[],
}

export class ReduxEvents {
  events: ReduxEventsProp
  instances: ReduxInstancesProp
  undoQueue: UndoData[]

  constructor() {
    this.events = {}
    this.instances = {}
    this.undoQueue = []
  }

  static _getInstanceKeys(instances: {
    rrule: string,
    [instanceId: string]: ReduxEvent,
    lastUpdated: number,
  }) {
    const ret = []
    Object.keys(instances).forEach(key => {
      if (
        key !== 'lastUpdated' &&
        key !== 'rrule' &&
        key !== 'start' &&
        key !== 'end'
      ) {
        ret.push(key)
      }
    })
    return ret
  }

  static clone(oldSelf: ReduxEvents) {
    const newSelf = new ReduxEvents()
    newSelf._replaceEventsInplace(oldSelf.events)
    newSelf._replaceInstancesInplace(oldSelf.instances)
    newSelf._replaceUndoQueue(oldSelf.undoQueue)
    return newSelf
  }

  static areEventsDataNewer(
    oldSelf: ReduxEvents,
    {
      events,
      calendarId,
      start,
      end,
      currentTimeZone,
    }: {
      events: CalendarEventInstanceListResource,
      calendarId: string,
      start: UnixTimestampInSeconds,
      end: UnixTimestampInSeconds,
      currentTimeZone: string,
    }
  ) {
    return ReduxEvents._areDataNewer(oldSelf, {
      eventsOrInstances: events,
      calendarId,
      start,
      end,
      currentTimeZone,
    })
  }

  // static areInstancesDataNewer(
  //   oldSelf: ReduxEvents,
  //   {
  //     instances,
  //     eventId,
  //     calendarId,
  //   }: {
  //     instances: CalendarEventInstanceListResource,
  //     eventId: string,
  //     calendarId: string,
  //   }
  // ) {
  //   return ReduxEvents._areDataNewer(
  //     oldSelf,
  //     { calendarId, eventsOrInstances: instances, eventId },
  //     false
  //   )
  // }

  static _areDataNewer(
    oldSelf: ReduxEvents,
    {
      eventsOrInstances,
      calendarId,
      start,
      end,
      currentTimeZone,
    }: {
      eventsOrInstances: CalendarEventInstanceListResource,
      start: UnixTimestampInSeconds,
      end: UnixTimestampInSeconds,
      currentTimeZone: string,
      calendarId: string,
      eventId?: string,
    }
  ) {
    const oldEvents = oldSelf.events[calendarId]
    if (!oldEvents) {
      return true
    }
    if (oldEvents.lastUpdated < eventsOrInstances.updated) {
      return true
    }
    const newEvents = eventsOrInstances.items
    const newEventOrInstanceIds = {}
    for (const newEvent of newEvents) {
      const instanceId = newEvent.recurringEventId ? newEvent.id : ''
      const eventId = newEvent.recurringEventId || newEvent.id
      let oldEventOrInstance
      if (!instanceId) {
        oldEventOrInstance = oldSelf.events[calendarId][eventId]
      } else {
        if (!oldSelf.instances[calendarId]) {
          return true
        }
        if (!oldSelf.instances[calendarId][eventId]) {
          return true
        }
        oldEventOrInstance = oldSelf.instances[calendarId][eventId][instanceId]
      }
      if (!oldEventOrInstance) {
        return true
      }
      if (oldEventOrInstance.updated < newEvent.updated) {
        return true
      }
      newEventOrInstanceIds[newEvent.id] = true
    }
    const effectedTreeIds = getTreeIdsByRange({ start, end, currentTimeZone })
    for (const treeId of effectedTreeIds) {
      if (treeId.calendarId === calendarId) {
        if (treeId.instanceId) {
          if (!newEventOrInstanceIds[treeId.instanceId]) {
            return true
          }
        } else {
          if (!newEventOrInstanceIds[treeId.eventId]) {
            return true
          }
        }
      }
    }
    return false
  }

  updateEventsFromAPI({
    calendarId,
    events,
    start,
    end,
    currentTimeZone,
  }: {
    calendarId: string,
    events: CalendarEventInstanceListResource,
    start: UnixTimestampInSeconds,
    end: UnixTimestampInSeconds,
    currentTimeZone: string,
  }) {
    if (!this.events[calendarId]) {
      this.events[calendarId] = { lastUpdated: events.updated }
    }
    const oldEventIds = {}
    const oldInstancesToBeRemoved = {}
    getTreeIdsByRange({ start, end, currentTimeZone }).forEach(
      (treeId: TreeId) => {
        if (calendarId === treeId.calendarId) {
          if (treeId.instanceId) {
            oldInstancesToBeRemoved[treeId.instanceId] = treeId
          } else {
            oldEventIds[treeId.eventId] = true
          }
        }
      }
    )

    const instances: {
      [eventId: string]: CalendarEventsResource[],
    } = {}
    const appendToInstances = (data: CalendarEventsResource) => {
      if (!Array.isArray(instances[data.recurringEventId])) {
        instances[data.recurringEventId] = []
      }
      instances[data.recurringEventId].push(data)
    }
    for (const eventData of events.items) {
      const newEvent = new ReduxEvent({ ...eventData, calendarId })
      oldEventIds[newEvent.eventId] = false
      if (this.events[calendarId][newEvent.eventId] && !newEvent.isInstance) {
        if (this.events[calendarId][newEvent.eventId].isRecurringEvent) {
          this.updateRecurringEvent({
            calendarId,
            event: newEvent,
            appendToUndo: false,
          })
        } else {
          this.updateEvent({ calendarId, event: newEvent, appendToUndo: false })
        }
      } else if (!newEvent.isInstance) {
        this.addEventOrInstance({
          calendarId,
          eventId: newEvent.eventId,
          eventOrInstanceData: newEvent,
          appendToUndo: false,
          undoType: 'modify',
        })
      }
      if (newEvent.isInstance) {
        appendToInstances(eventData)
        if (oldInstancesToBeRemoved[newEvent.instanceId]) {
          delete oldInstancesToBeRemoved[newEvent.instanceId]
        }
      }
    }
    Object.keys(oldEventIds).forEach(eventId => {
      if (oldEventIds[eventId]) {
        console.debug(`Removing existing data ${eventId}`)
        const oldEvent = this.getEventById({
          calendarId: calendarId,
          eventId: eventId,
        })
        if (oldEvent) {
          this.deleteEventOrInstance({
            eventOrInstance: oldEvent,
            appendToUndo: false,
            hardDelete: true,
          })
        }
      }
    })
    Object.keys(instances).forEach(eventId => {
      if (instances[eventId].length > 0) {
        this.updateInstancesFromAPI({
          calendarId,
          eventId,
          start,
          end,
          currentTimeZone,
          // $FlowFixMe
          instances: { items: instances[eventId], updated: events.update },
        })
      }
    })
    Object.values(oldInstancesToBeRemoved).forEach((treeId: TreeId) => {
      const instance = this.getInstanceById({
        calendarId: treeId.calendarId,
        eventId: treeId.eventId,
        instanceId: treeId.instanceId,
      })
      if (instance) {
        console.debug(`Removing existing instances ${instance.instanceId}`)
        this.deleteEventOrInstance({
          eventOrInstance: instance,
          appendToUndo: false,
          hardDelete: true,
          isUndo: false,
        })
      }
    })
  }

  updateInstancesFromAPI({
    eventId,
    instances,
    calendarId,
    start,
    end,
    currentTimeZone,
  }: {
    start: UnixTimestampInSeconds,
    end: UnixTimestampInSeconds,
    currentTimeZone: string,
    eventId: string,
    calendarId: string,
    instances: CalendarEventInstanceListResource,
  }) {
    if (!this.instances[calendarId]) {
      this.instances[calendarId] = {}
    }
    if (!this.instances[calendarId][eventId]) {
      this.instances[calendarId][eventId] = {
        lastUpdated: instances.updated,
        rrule: '',
      }
    }
    const treeMapIds = getTreeIdsMap({
      calendarId,
      eventId,
      start,
      end,
      currentTimeZone,
    })
    for (const eventData of instances.items) {
      const newInstance = new ReduxEvent({ ...eventData, calendarId })
      newInstance.differentFromEvent = true
      treeMapIds[newInstance.treeMapId] = false
      const oldInstance = this.getInstanceById({
        calendarId,
        eventId,
        instanceId: newInstance.instanceId,
      })
      if (oldInstance && oldInstance.isInstance) {
        this.updateInstance({
          calendarId,
          eventId,
          instance: newInstance,
          appendToUndo: false,
        })
      } else {
        this.addEventOrInstance({
          calendarId,
          eventId,
          instanceId: newInstance.instanceId,
          eventOrInstanceData: newInstance,
          appendToUndo: false,
          undoType: 'modify',
        })
      }
    }
    Object.values(treeMapIds).forEach(item => {
      if (item && item.instanceId) {
        console.debug(`Removing existing data ${item.eventId}`)
        const oldInstance = this.getInstanceById({
          calendarId: item.calendarId,
          eventId: item.eventId,
          instanceId: item.instanceId,
        })
        if (oldInstance) {
          this.deleteEventOrInstance({
            eventOrInstance: oldInstance,
            appendToUndo: false,
            hardDelete: true,
          })
        }
      }
    })
  }

  _replaceEventsInplace(newEvents: ReduxEventsProp) {
    this.events = { ...newEvents }
  }

  _replaceInstancesInplace(newInstances: ReduxInstancesProp) {
    this.instances = { ...newInstances }
  }

  _replaceUndoQueue(newUndoQueue: UndoData[]) {
    this.undoQueue = newUndoQueue.slice()
  }

  isEmpty() {
    return Object.keys(this.events).length === 0
  }

  appendToUndoQueue(undoData: UndoData) {
    const id = undoData.undoId
    for (let i = 0; i < this.undoQueue.length; i++) {
      const undoId = this.undoQueue[i].undoId
      if (undoId === id) {
        this.undoQueue[i] = undoData
        return
      }
    }
    this.undoQueue.push(undoData)
  }

  removeUndo({
    type,
    calendarId,
    eventId,
    updateEventId,
    newEventId,
    instanceId,
  }: {
    type: 'add' | 'delete' | 'modify' | 'updateAndAdd',
    calendarId: string,
    eventId: string,
    updateEventId?: string,
    newEventId?: string,
    instanceId?: string,
  }) {
    let undoId = `${type}-${calendarId}-${eventId}-${
      instanceId ? instanceId : ''
    }`
    if (updateEventId) {
      undoId = `${type}-${calendarId}-${updateEventId}-${newEventId}`
    }
    this.undoQueue = this.undoQueue.filter((undo: UndoData) => {
      return undoId !== undo.undoId
    })
  }

  performUndo({
    type,
    calendarId,
    eventId,
    updateEventId,
    newEventId,
    instanceId,
  }: {
    type: 'add' | 'delete' | 'modify' | 'updateAndAdd',
    calendarId: string,
    eventId: string,
    updateEventId?: string,
    newEventId?: string,
    instanceId?: string,
  }) {
    let undoId = `${type}-${calendarId}-${eventId}${
      instanceId ? `-${instanceId}` : ''
    }`
    if (updateEventId) {
      undoId = `${type}-${calendarId}-${updateEventId}-${newEventId}`
    }
    console.debug('performing undo', undoId)
    this.undoQueue = this.undoQueue.filter((undoData: UndoData) => {
      if (undoData.undoId === undoId) {
        console.debug(`perform undo`, undoData)
        if (undoData.type === 'add') {
          this.undoAdd(undoData)
        } else if (undoData.type === 'delete') {
          this.undoDelete(undoData)
        } else if (undoData.type === 'modify') {
          this.undoModify(undoData)
        } else if (undoData.type === 'updateAndAdd') {
          this.undoUpdateAndAdd(undoData)
        } else if (undoData.type === 'updateRecurringEventExDateAndInstance') {
          this.undoUpdateRecurringEventExDateAndInstance(undoData)
        }
        return false
      }
      return true
    })
  }

  undoAdd(undoData: UndoAddData) {
    const { calendarId, eventId, instanceId } = undoData.data
    let eventOrInstance
    if (instanceId) {
      eventOrInstance = this.getInstanceById({
        calendarId,
        eventId,
        instanceId,
      })
    } else {
      eventOrInstance = this.getEventById({ calendarId, eventId })
    }
    this.deleteEventOrInstance({
      eventOrInstance,
      appendToUndo: false,
      hardDelete: true,
      isUndo: true,
    })
  }

  undoDelete(undoData: UndoDeleteData) {
    const {
      calendarId,
      eventId,
      instanceId,
      eventOrInstanceData,
      eventInstancesData,
    } = undoData.data
    this.addEventOrInstance({
      calendarId,
      eventId,
      instanceId,
      eventOrInstanceData,
      eventInstancesData,
      appendToUndo: false,
      isUndo: true,
    })
  }
  undoUpdateRecurringEventExDateAndInstance(
    undoData: UndoUpdateRecurringEventExDateAndInstanceData
  ) {
    const { calendarId, eventId, instanceId, event, instance } = undoData.data
    this.updateEvent({ calendarId, event, appendToUndo: false, isUndo: true })
    if (instance) {
      this.updateInstance({
        calendarId,
        eventId: instance.eventId,
        instance,
        appendToUndo: false,
        isUndo: true,
      })
    } else {
      const oldInstance = this.getInstanceById({
        calendarId,
        eventId,
        instanceId,
      })
      this.deleteEventOrInstance({
        eventOrInstance: oldInstance,
        appendToUndo: false,
        hardDelete: true,
        isUndo: true,
      })
    }
  }

  undoUpdateAndAdd(undoData: UndoUpdateAndAddData) {
    const {
      calendarId,
      updateEventId,
      updateEvent,
      updateEventInstancesData,
      newEventId,
    } = undoData.data
    const newEvent = this.getEventById({ calendarId, eventId: newEventId })
    if (newEvent) {
      this.deleteEventOrInstance({
        eventOrInstance: newEvent,
        appendToUndo: false,
        hardDelete: true,
        isUndo: true,
      })
    }
    this.deleteEventOrInstance({
      eventOrInstance: updateEvent,
      hardDelete: true,
      appendToUndo: false,
      isUndo: true,
    })
    this.addEventOrInstance({
      calendarId,
      eventId: updateEventId,
      eventOrInstanceData: updateEvent,
      eventInstancesData: updateEventInstancesData,
      appendToUndo: false,
      isUndo: true,
    })
  }

  undoModify(undoData: UndoModifyData) {
    const {
      calendarId,
      eventId,
      instanceId,
      eventData,
      instancesData,
    } = undoData.data
    if (instanceId) {
      this.updateInstance({
        calendarId,
        eventId,
        instance: eventData,
        appendToUndo: false,
        isUndo: true,
      })
    } else {
      this.updateEvent({
        calendarId,
        event: eventData,
        appendToUndo: false,
        isUndo: true,
      })
      if (instancesData) {
        ReduxEvents._getInstanceKeys(instancesData).forEach(instanceId => {
          if (this.getInstanceById({ calendarId, eventId, instanceId })) {
            this.updateInstance({
              calendarId,
              eventId,
              instance: instancesData[instanceId],
              appendToUndo: false,
              isUndo: true,
            })
          } else {
            this.addEventOrInstance({
              calendarId,
              eventId,
              instanceId,
              eventOrInstanceData: instancesData[instanceId],
              appendToUndo: false,
              isUndo: true,
            })
          }
        })
      }
    }
  }

  getEventIdsByCalendarId({
    start,
    end,
    calendarId,
    currentTimeZone,
  }: {
    calendarId: string,
    currentTimeZone: string,
    start: UnixTimestampInSeconds,
    end: UnixTimestampInSeconds,
  }): string[] {
    const events = this.events[calendarId]
    if (!events) {
      return []
    }
    const ret = []
    getTreeIdsByRange({ start, end, currentTimeZone }).forEach(
      (treeId: TreeId) => {
        if (treeId.calendarId === calendarId && events[treeId.eventId]) {
          ret.push(treeId.eventId)
        }
      }
    )
    return ret
  }

  getEventById({
    calendarId,
    eventId,
  }: {
    calendarId: string,
    eventId: string,
  }): ReduxEvent | void {
    if (this.events[calendarId] && this.events[calendarId][eventId]) {
      return this.events[calendarId][eventId].clone()
    }
  }

  getInstanceIdsByCalendarAndEventId({
    calendarId,
    eventId,
    start,
    end,
    currentTimeZone,
  }: {
    calendarId: string,
    eventId: string,
    currentTimeZone: string,
    start: UnixTimestampInSeconds,
    end: UnixTimestampInSeconds,
  }): string[] {
    const instances = this.instances[calendarId]
      ? this.instances[calendarId][eventId]
      : null
    if (!instances) {
      return []
    }
    return ReduxEvents._getInstanceKeys(instances).filter(instanceId => {
      const instance = this.instances[calendarId][eventId][instanceId]
      return instance.withInRange({ start, end, currentTimeZone })
    })
  }

  getInstancesByCalendarAndEventId(
    { calendarId, eventId },
    filterOptions?: {
      start: UnixTimestampInSeconds,
      end: UnixTimestampInSeconds,
      currentTimeZone: string,
    }
  ) {
    if (this.instances[calendarId] && this.instances[calendarId][eventId]) {
      const ret = []
      ReduxEvents._getInstanceKeys(this.instances[calendarId][eventId]).forEach(
        instanceId => {
          if (filterOptions) {
            if (
              this.instances[calendarId][eventId][instanceId].withInRange(
                filterOptions
              )
            ) {
              ret.push(this.instances[calendarId][eventId][instanceId].clone())
            }
          } else {
            ret.push(this.instances[calendarId][eventId][instanceId].clone())
          }
        }
      )
      return ret
    }
    return []
  }

  getInstanceById({
    calendarId,
    eventId,
    instanceId,
  }: {
    calendarId: string,
    eventId: string,
    instanceId: string,
  }): ReduxEvent | void {
    if (
      this.instances[calendarId] &&
      this.instances[calendarId][eventId] &&
      this.instances[calendarId][eventId][instanceId]
    ) {
      return this.instances[calendarId][eventId][instanceId].clone()
    }
  }

  getUIScopeEvents(
    {
      start,
      end,
      currentTimeZone,
      calendarIds,
    }: {
      start: UnixTimestampInSeconds,
      end: UnixTimestampInSeconds,
      currentTimeZone: string,
      calendarIds: string[],
    },
    options?: {
      showCancelled?: boolean,
      showDeclined?: boolean,
      userAliases?: string[],
    }
  ): ReduxEventJSON[] {
    // const tmpTree = new UIScopeEventTree('tmpTree')
    // const tmpAllDayTree = new UIScopeEventTree('tmpAllDay')
    const ret = []
    const uiEvents: { [eventOrInstanceId: string]: number } = {}
    const recurringEvents: {
      [eventId: string]: {
        event: ReduxEvent,
        instances: ReduxEvent[],
      },
    } = {}
    const appendInstanceOrEvent = (item: ReduxEvent) => {
      if (!item.isInstance && item.isRecurringEvent) {
        if (!recurringEvents[item.eventId]) {
          recurringEvents[item.eventId] = {
            event: item,
            instances: [],
          }
        } else {
          recurringEvents[item.eventId].event = item
        }
      } else if (item.isInstance) {
        if (!recurringEvents[item.eventId]) {
          recurringEvents[item.eventId] = {
            event: null,
            instances: [item],
          }
        } else {
          recurringEvents[item.eventId].instances.push(item)
        }
      }
    }
    if (this.isEmpty() || calendarIds.length === 0) {
      return []
    }
    let showCancelled = false
    let showDeclined = false
    let userAliases = []
    if (options) {
      showCancelled = !!options.showCancelled
      showDeclined = !!options.showDeclined
      if (Array.isArray(options.userAliases)) {
        userAliases = options.userAliases
      }
    }
    const calendarIdMap = {}
    calendarIds.forEach(id => (calendarIdMap[id] = id))
    const treeIds = getTreeIdsByRange({ start, end, currentTimeZone })
    treeIds.forEach((treeId: TreeId) => {
      const calendarId = calendarIdMap[treeId.calendarId]
        ? treeId.calendarId
        : false
      if (!calendarId) {
        return
      }
      if (treeId.instanceId.length > 0) {
        if (
          !this.instances[calendarId] ||
          !this.instances[calendarId][treeId.eventId] ||
          !this.instances[calendarId][treeId.eventId][treeId.instanceId]
        ) {
          return
        }
        const tmp = this.instances[calendarId][treeId.eventId][
          treeId.instanceId
        ].clone()
        const event =
          this.events[calendarId] && this.events[calendarId][treeId.eventId]
            ? this.events[calendarId][treeId.eventId].clone()
            : undefined
        if (event) {
          tmp.rruleString = event.rruleString
          appendInstanceOrEvent(event)
        } else {
          tmp.isOrphan = true
        }
        appendInstanceOrEvent(tmp)
      } else {
        if (
          this.events[calendarId] &&
          this.events[calendarId][treeId.eventId] &&
          (showCancelled || !this.events[calendarId][treeId.eventId].cancelled)
        ) {
          if (!this.events[calendarId][treeId.eventId].isRecurringEvent) {
            if (!showDeclined) {
              const currentAttendee = this.events[calendarId][
                treeId.eventId
              ].getAttendeeByEmails(userAliases)
              if (
                currentAttendee &&
                (currentAttendee.responseStatus || '').toLocaleUpperCase() ===
                  CALENDAR_EVENT_RSVP_STATUS.declined.rfcValue
              ) {
                return
              }
            }
            const o = this.events[calendarId][treeId.eventId].toJSON()
            if (
              uiEvents[`${treeId.calendarId}${treeId.eventId}`] === undefined
            ) {
              ret.push(o)
              uiEvents[`${treeId.calendarId}${treeId.eventId}`] = ret.length - 1
            } else {
              ret[uiEvents[`${treeId.calendarId}${treeId.eventId}`]] = o
            }
          } else {
            appendInstanceOrEvent(this.events[calendarId][treeId.eventId])
          }
        }
      }
    })
    Object.keys(recurringEvents).forEach(eventId => {
      const tmp = generateInstanceByRecurringEvent(
        recurringEvents[eventId].event,
        recurringEvents[eventId].instances,
        moment.unix(start),
        moment.unix(end)
      )
      //Do not try to move below code into generateInstanceByRecurringEvent,
      //Test show runtime cost is similar or slightly greater that current form
      Object.values(tmp).forEach((o: ReduxEvent) => {
        if (showCancelled || (!o.cancelled && !o.isSoftDelete)) {
          if (!showDeclined) {
            const currentAttendee = o.getAttendeeByEmails(userAliases)
            if (
              currentAttendee &&
              (currentAttendee.responseStatus || '').toLocaleUpperCase() ===
                CALENDAR_EVENT_RSVP_STATUS.declined.rfcValue
            ) {
              return
            }
          }
          if (uiEvents[`${o.calendarId}${o.id}`] === undefined) {
            ret.push(o.toJSON())
            uiEvents[`${o.calendarId}${o.id}`] = ret.length - 1
          } else {
            ret[uiEvents[`${o.calendarId}${o.id}`]] = o.toJSON()
          }
        }
      })
    })
    // ret.events = uiEvents
    // ret.eventsHash = eventsTimestamp.sort().join('-')
    return ret
  }
  updateEventSimpleData({
    calendarId,
    event,
    appendToUndo,
  }: {
    calendarId: string,
    event: ReduxEvent,
    appendToUndo: boolean,
  }) {
    if (!event.isInstance) {
      const oldInstances = this.getInstancesByCalendarAndEventId({
        calendarId: event.calendarId,
        eventId: event.eventId,
      })
      const oldInstancesData = this._copyInstancesData({
        calendarId,
        eventId: event.eventId,
      })
      const oldEvent = this.getEventById({
        calendarId: event.calendarId,
        eventId: event.eventId,
      })
      if (appendToUndo) {
        this.appendToUndoQueue({
          type: 'modify',
          undoId: `modify-${calendarId}-${event.eventId}`,
          data: {
            calendarId,
            eventId: event.eventId,
            eventData: oldEvent,
            instancesData: oldInstancesData,
          },
        })
      }
      this.updateEvent({
        calendarId: event.calendarId,
        event,
        appendToUndo: false,
        isUndo: false,
      })
      oldInstances.forEach((instance: ReduxEvent) => {
        instance.mergeEventSimpleData(event)
        this.updateInstance({
          calendarId: instance.calendarId,
          eventId: instance.eventId,
          instance,
          appendToUndo: false,
          isUndo: false,
        })
      })
    }
  }

  updateEvent({
    calendarId,
    event,
    appendToUndo,
    isUndo,
  }: {
    calendarId: string,
    event: ReduxEvent,
    appendToUndo: boolean,
    isUndo?: boolean,
  }) {
    if (
      !event.isInstance &&
      this.events[calendarId] &&
      this.events[calendarId][event.eventId]
    ) {
      const oldEvent = this.events[calendarId][event.eventId].clone()
      this.events[calendarId][event.eventId] = event.clone()
      if (oldEvent.isAllDayEvent) {
        allDayEventTree.remove(oldEvent)
      } else {
        uiScopeEventTree.remove(oldEvent)
      }
      if (this.events[calendarId][event.eventId].isAllDayEvent) {
        allDayEventTree.insert(this.events[calendarId][event.eventId])
      } else {
        uiScopeEventTree.insert(this.events[calendarId][event.eventId])
      }
      this._updateCalendarMetaData(
        calendarId,
        this.events[calendarId][event.eventId].updated,
        !!isUndo
      )
      if (event.isRecurringEvent) {
        this._initEventInstancesMetaData({ calendarId, eventId: event.eventId })
      }
      if (appendToUndo) {
        this.appendToUndoQueue({
          type: 'modify',
          undoId: `modify-${calendarId}-${event.eventId}`,
          data: {
            calendarId,
            eventId: event.eventId,
            eventData: oldEvent,
          },
        })
      }
    }
  }

  updateRecurringEventAndSingleInstance({
    calendarId,
    updateEvent,
    newInstance,
    appendToUndo,
  }: {
    calendarId: string,
    updateEvent: ReduxEvent,
    newInstance: ReduxEvent,
    appendToUndo: boolean,
  }) {
    if (updateEvent && newInstance) {
      if (appendToUndo) {
        const oldEvent = this.getEventById({
          calendarId,
          eventId: updateEvent.eventId,
        })
        const oldInstance = this.getInstanceById({
          calendarId,
          eventId: newInstance.eventId,
          instanceId: newInstance.instanceId,
        })
        this.appendToUndoQueue({
          type: 'updateRecurringEventExDateAndInstance',
          undoId: `updateRecurringEventExDateAndInstance-${calendarId}-${updateEvent.eventId}-${newInstance.instanceId}`,
          data: {
            calendarId,
            eventId: oldEvent.eventId,
            instanceId: newInstance.instanceId,
            event: oldEvent.clone(),
            instance: oldInstance,
          },
        })
      }
      this.updateInstance({
        calendarId,
        instance: newInstance,
        eventId: newInstance.eventId,
        appendToUndo: false,
        isUndo: false,
      })
      this.updateEvent({
        calendarId,
        event: updateEvent,
        appendToUndo: false,
        isUndo: false,
      })
    }
  }

  updateThisAndFollowingEvents({
    calendarId,
    updateEvent,
    newEvent,
    breakEventLink,
    appendToUndo,
  }: {
    calendarId: string,
    updateEvent: ReduxEvent,
    newEvent: ReduxEvent,
    breakEventLink: boolean,
    appendToUndo: boolean,
  }) {
    const oldEvent = this.getEventById({
      calendarId,
      eventId: updateEvent.eventId,
    })
    if (!oldEvent) {
      console.error(`Old event not found ${updateEvent.eventId}, ${calendarId}`)
      return
    }
    if (!oldEvent.isRecurringEvent) {
      console.error(
        `Old event is not recurring event ${updateEvent.eventId}, ${calendarId}`
      )
      return
    }
    const oldInstances = this.getInstancesByCalendarAndEventId({
      calendarId,
      eventId: updateEvent.eventId,
    })
    if (appendToUndo) {
      this.appendToUndoQueue({
        type: 'updateAndAdd',
        undoId: `updateAndAdd-${calendarId}-${oldEvent.eventId}-${newEvent.eventId}`,
        data: {
          calendarId,
          updateEvent: oldEvent,
          updateEventId: oldEvent.eventId,
          newEventId: newEvent.eventId,
          updateEventInstancesData: this._copyInstancesData({
            calendarId,
            eventId: updateEvent.eventId,
          }),
        },
      })
    }
    this.updateEvent({
      calendarId,
      event: updateEvent,
      appendToUndo: false,
    })
    const newLinkedInstances = []
    const allDayChanged = updateEvent.isAllDayEvent !== newEvent.isAllDayEvent
    let newFirstInstanceId = `${
      oldEvent.originalEventId
    }_${newEvent.start.toUTCMoment().format('YYYYMMDD')}`
    if (!newEvent.isAllDayEvent) {
      newFirstInstanceId = `${newFirstInstanceId}${newEvent.originalStartTime
        .toUTCMoment()
        .format('[T]HHmmss[Z]')}`
    }
    oldInstances.forEach((instance: ReduxEvent) => {
      if (
        instance.start &&
        instance.start.timestamp >= updateEvent.rruleUntil
      ) {
        if (
          instance.cancelled &&
          !breakEventLink &&
          !allDayChanged &&
          instance.instanceId !== newFirstInstanceId
        ) {
          const newInstance = instance.clone()
          newInstance.linkToEvent(newEvent.eventId)
          newLinkedInstances.push(newInstance)
        }
        this.deleteEventOrInstance({
          eventOrInstance: instance,
          appendToUndo: false,
          hardDelete: true,
        })
      }
    })
    this.addEventOrInstance({
      calendarId,
      eventId: newEvent.eventId,
      eventOrInstanceData: newEvent,
      appendToUndo: false,
      undoType: 'add',
      isUndo: false,
    })
    newLinkedInstances.forEach(instance => {
      instance.mergeEventData(newEvent)
      this.addEventOrInstance({
        calendarId,
        eventId: instance.eventId,
        instanceId: instance.instanceId,
        eventOrInstanceData: instance,
        appendToUndo: false,
        undoType: 'add',
        isUndo: false,
      })
    })
  }

  updateRecurringEvent({
    calendarId,
    event,
    appendToUndo,
  }: {
    calendarId: string,
    event: ReduxEvent,
    appendToUndo: boolean,
  }) {
    const oldEvent = this.getEventById({ calendarId, eventId: event.eventId })
    if (event && oldEvent && oldEvent.isRecurringEvent) {
      const oldInstancesData = this._copyInstancesData({
        calendarId,
        eventId: event.eventId,
      })
      this.updateEvent({ calendarId, event, appendToUndo: false })
      if (
        this.instances[calendarId] &&
        this.instances[calendarId][event.eventId]
      ) {
        if (oldEvent.isAllDayEvent !== event.isAllDayEvent) {
          ReduxEvents._getInstanceKeys(
            this.instances[calendarId][event.eventId]
          ).forEach(instanceId => {
            const instance = this.instances[calendarId][event.eventId][
              instanceId
            ]
            if (instance) {
              this.deleteEventOrInstance({
                calendarId,
                eventId: event.eventId,
                instanceId,
                eventOrInstance: instance,
                appendToUndo: false,
                hardDelete: true,
              })
            }
          })
        } else if (oldEvent.rruleString !== event.rruleString) {
          console.debug(
            `removing instances because rrule change ${event.eventId}, ${calendarId}`
          )
          ReduxEvents._getInstanceKeys(
            this.instances[calendarId][event.eventId]
          ).forEach(instanceId => {
            const instance = this.instances[calendarId][event.eventId][
              instanceId
            ]
            if (instance && instance.updated < event.updated) {
              this.deleteEventOrInstance({
                calendarId,
                eventId: event.eventId,
                instanceId,
                eventOrInstance: instance,
                appendToUndo: false,
                hardDelete: true,
              })
            }
          })
        } else {
          ReduxEvents._getInstanceKeys(
            this.instances[calendarId][event.eventId]
          ).forEach(instanceId => {
            const instance = this.instances[calendarId][event.eventId][
              instanceId
            ].clone()
            if (instance.updated < event.updated) {
              instance.mergeEventData(event)
              this.updateInstance({
                calendarId,
                eventId: instance.eventId,
                instance,
                appendToUndo: false,
              })
            }
          })
        }
      }
      if (appendToUndo) {
        this.appendToUndoQueue({
          type: 'modify',
          undoId: `modify-${calendarId}-${event.eventId}`,
          data: {
            calendarId,
            eventId: event.eventId,
            eventData: oldEvent,
            instancesData: oldInstancesData,
          },
        })
      }
    } else {
      console.error('Event must be an recurring event')
    }
  }

  updateInstance({
    calendarId,
    eventId,
    instance,
    appendToUndo,
    isUndo,
  }: {
    calendarId: string,
    eventId: string,
    instance: ReduxEvent,
    appendToUndo: boolean,
    isUndo?: boolean,
  }) {
    if (instance.isInstance) {
      const oldInstance = this.getInstanceById({
        calendarId,
        eventId,
        instanceId: instance.instanceId,
      })
      if (!oldInstance) {
        console.debug(
          'Instance not found, adding',
          calendarId,
          eventId,
          instance.instanceId
        )
        return this.addEventOrInstance({
          calendarId,
          eventId,
          instanceId: instance.instanceId,
          eventOrInstanceData: instance,
          appendToUndo,
          undoType: 'modify',
          isUndo,
        })
      }
      if (oldInstance.isAllDayEvent) {
        allDayEventTree.remove(oldInstance)
      } else {
        uiScopeEventTree.remove(oldInstance)
      }
      if (instance.isAllDayEvent) {
        allDayEventTree.insert(instance)
      } else {
        uiScopeEventTree.insert(instance)
      }
      this.instances[calendarId][eventId][
        instance.instanceId
      ] = instance.clone()
      // this._updateEventInstancesMetaData({ calendarId, eventId, instance })
      if (appendToUndo) {
        this.appendToUndoQueue({
          type: 'modify',
          undoId: `modify-${calendarId}-${eventId}-${instance.instanceId}`,
          data: {
            calendarId,
            eventId,
            instanceId: instance.instanceId,
            eventData: oldInstance,
          },
        })
      }
    }
  }

  _initEventInstancesMetaData({
    calendarId,
    eventId,
  }: {
    calendarId: string,
    eventId: string,
  }) {
    if (!this.instances[calendarId]) {
      this.instances[calendarId] = {}
    }
    if (!this.instances[calendarId][eventId]) {
      this.instances[calendarId][eventId] = {
        rrule: '',
        lastUpdated: 0,
      }
    }
  }

  _updateCalendarMetaData(
    calendarId: string,
    lastUpdate: UnixTimestampInSeconds,
    forceUpdate?: boolean
  ) {
    if (this.events[calendarId] && forceUpdate) {
      this.events[calendarId].lastUpdated = lastUpdate
    } else if (
      this.events[calendarId] &&
      this.events[calendarId].lastUpdated < lastUpdate
    ) {
      this.events[calendarId].lastUpdated = lastUpdate
    }
  }

  // _updateEventInstancesMetaData({
  //   calendarId,
  //   eventId,
  //   instance,
  // }: {
  //   calendarId: string,
  //   eventId: string,
  //   instance: ReduxEvent,
  // }) {
  //   if (
  //     instance &&
  //     instance.instanceId &&
  //     this.instances[calendarId] &&
  //     this.instances[calendarId][eventId]
  //   ) {
  //     if (!this.instances[calendarId][eventId].start) {
  //       this.instances[calendarId][eventId].start = 0
  //     }
  //     if (
  //       this.instances[calendarId][eventId].start > instance.start.timestamp
  //     ) {
  //       this.instances[calendarId][eventId].start = instance.start.timestamp
  //     }
  //     if (!this.instances[calendarId][eventId].end) {
  //       this.instances[calendarId][eventId].end = 0
  //     }
  //     if (this.instances[calendarId][eventId].end < instance.end.timestamp) {
  //       this.instances[calendarId][eventId].end = instance.end.timestamp
  //     }
  //     if (!this.instances[calendarId][eventId].lastUpdated) {
  //       this.instances[calendarId][eventId].lastUpdated = 0
  //     }
  //     if (this.instances[calendarId][eventId].lastUpdated < instance.updated) {
  //       this.instances[calendarId][eventId].lastUpdated = instance.updated
  //     }
  //   }
  // }

  addEventOrInstance({
    calendarId,
    eventId,
    instanceId,
    eventOrInstanceData,
    eventInstancesData,
    appendToUndo,
    undoType = 'add',
    isUndo,
  }: {
    calendarId: string,
    eventId: string,
    instanceId?: string,
    eventOrInstanceData: ReduxEvent,
    eventInstancesData?: {
      rrule: string,
      [instanceId: string]: ReduxEvent,
      lastUpdated: number,
    },
    appendToUndo: boolean,
    undoType: string,
    isUndo?: boolean,
  }) {
    if (instanceId && eventOrInstanceData) {
      if (!eventOrInstanceData.isInstance) {
        console.error('Not instance, ignoring')
        return
      }
      if (!this.instances[calendarId]) {
        this.instances[calendarId] = {}
      }
      if (!this.instances[calendarId][eventId]) {
        this.instances[calendarId][eventId] = { lastUpdated: 0, rrule: '' }
        console.warn(
          `This should have been created when adding event ${eventId}, ${calendarId}`
        )
      }
      this.instances[calendarId][eventId][
        instanceId
      ] = eventOrInstanceData.clone()
      if (this.instances[calendarId][eventId][instanceId].isAllDayEvent) {
        allDayEventTree.insert(this.instances[calendarId][eventId][instanceId])
      } else {
        uiScopeEventTree.insert(this.instances[calendarId][eventId][instanceId])
      }
      // this._updateEventInstancesMetaData({ calendarId, eventId, instance: eventOrInstanceData })
      if (appendToUndo) {
        if (undoType === 'add') {
          this.appendToUndoQueue({
            type: 'add',
            undoId: `add-${calendarId}-${eventId}-${instanceId}`,
            data: {
              calendarId,
              eventId,
              instanceId,
            },
          })
        } else if (undoType === 'modify') {
          this.appendToUndoQueue({
            type: 'modify',
            undoId: `modify-${calendarId}-${eventId}-${instanceId}`,
            data: {
              calendarId,
              eventId,
              instanceId,
              eventData: eventOrInstanceData,
            },
          })
        } else {
          console.error('UndoType for add instance not processed', undoType)
        }
      }
    } else {
      if (appendToUndo) {
        if (undoType === 'add') {
          this.appendToUndoQueue({
            type: 'add',
            undoId: `add-${calendarId}-${eventId}`,
            data: {
              calendarId,
              eventId,
            },
          })
        } else if (undoType === 'modify') {
          this.appendToUndoQueue({
            type: 'modify',
            undoId: `modify-${calendarId}-${eventId}`,
            data: {
              calendarId,
              eventId,
              eventData: eventOrInstanceData,
              instancesData: eventInstancesData,
            },
          })
        } else {
          console.error('UndoType for add event not processed', undoType)
        }
      }
      if (!this.events[calendarId]) {
        this.events[calendarId] = { lastUpdated: 0 }
      }
      this.events[calendarId][eventId] = eventOrInstanceData.clone()
      if (this.events[calendarId][eventId].isAllDayEvent) {
        allDayEventTree.insert(this.events[calendarId][eventId])
      } else {
        uiScopeEventTree.insert(this.events[calendarId][eventId])
      }
      this._updateCalendarMetaData(
        calendarId,
        this.events[calendarId][eventId].updated,
        !!isUndo
      )
      if (eventOrInstanceData.isRecurringEvent) {
        this._initEventInstancesMetaData({
          calendarId,
          eventId: eventOrInstanceData.eventId,
        })
        if (!this.instances[calendarId][eventId]) {
          console.debug(
            `Add ${eventId} to instances with rrule: ${eventOrInstanceData.rruleString}`
          )
          this.instances[calendarId][eventId] = {
            lastUpdated: 0,
            rrule: eventOrInstanceData.rruleString,
          }
        }
      }
      if (eventInstancesData) {
        if (!this.instances[calendarId]) {
          this.instances[calendarId] = {}
        }
        ReduxEvents._getInstanceKeys(eventInstancesData).forEach(instanceId => {
          this.addEventOrInstance({
            calendarId,
            eventId,
            instanceId,
            eventOrInstanceData: eventInstancesData[instanceId],
            appendToUndo: false,
            undoType: 'add',
            isUndo,
          })
        })
      }
    }
  }

  deleteEventOrInstance({
    eventOrInstance,
    appendToUndo,
    hardDelete,
    isUndo,
  }: {
    eventOrInstance: ReduxEvent | void,
    appendToUndo: boolean,
    hardDelete?: boolean,
    isUndo?: boolean,
  }) {
    if (!eventOrInstance) {
      console.error('No original data provided')
      return
    }
    const oldInstance = eventOrInstance
    const calendarId = eventOrInstance.calendarId
    const eventId = eventOrInstance.eventId
    const instanceId = eventOrInstance.instanceId
    if (eventOrInstance.isInstance) {
      if (appendToUndo) {
        this.appendToUndoQueue({
          type: 'delete',
          undoId: `delete-${calendarId}-${eventId}-${instanceId}`,
          data: {
            calendarId,
            eventId,
            instanceId,
            eventOrInstanceData: oldInstance.clone(),
          },
        })
      }
      if (oldInstance.isAllDayEvent) {
        allDayEventTree.remove(oldInstance)
      } else {
        uiScopeEventTree.remove(oldInstance)
      }
      if (hardDelete) {
        console.debug(`Hard delete ${eventId}, ${instanceId}`)
        this._hardDeleteInstance({ calendarId, eventId, instanceId })
        // this.instances[calendarId][eventId].lastUpdated = moment().unix()
      } else {
        const newInstance = oldInstance.clone()
        if (newInstance.organizer && newInstance.organizer.self) {
          newInstance.cancel()
        } else {
          newInstance.isSoftDelete = true
        }
        this.updateInstance({
          calendarId,
          eventId,
          instance: newInstance,
          appendToUndo: false,
          isUndo,
        })
      }
    } else if (this.events[calendarId] && this.events[calendarId][eventId]) {
      const oldEvent = this.events[calendarId][eventId]
      const undoData: UndoDeleteData = {
        type: 'delete',
        undoId: `delete-${calendarId}-${eventId}`,
        data: {
          calendarId,
          eventId,
          eventOrInstanceData: oldEvent.clone(),
        },
      }
      if (this.events[calendarId][eventId].isAllDayEvent) {
        allDayEventTree.remove(this.events[calendarId][eventId])
      } else {
        uiScopeEventTree.remove(this.events[calendarId][eventId])
      }
      if (hardDelete) {
        console.debug(`Hard delete ${eventId}`)
        delete this.events[calendarId][eventId]
      } else {
        const newEvent = oldEvent.clone()
        if (newEvent.organizer && newEvent.organizer.self) {
          newEvent.cancel()
        } else {
          newEvent.isSoftDelete = true
        }
        this.events[calendarId][eventId] = newEvent
        this._updateCalendarMetaData(calendarId, newEvent.updated, !!isUndo)
      }
      if (this.instances[calendarId] && this.instances[calendarId][eventId]) {
        if (appendToUndo) {
          undoData.data.eventInstancesData = this._copyInstancesData({
            calendarId,
            eventId,
          })
        }
        ReduxEvents._getInstanceKeys(
          this.instances[calendarId][eventId]
        ).forEach(instanceId => {
          this.deleteEventOrInstance({
            eventOrInstance: this.instances[calendarId][eventId][instanceId],
            appendToUndo: false,
            hardDelete: true,
            isUndo,
          })
        })
        if (hardDelete) {
          delete this.instances[calendarId][eventId]
        }
      }
      if (appendToUndo) {
        this.appendToUndoQueue(undoData)
      }
    }
  }

  _hardDeleteInstance({
    calendarId,
    eventId,
    instanceId,
  }: {
    calendarId: string,
    eventId: string,
    instanceId: string,
  }) {
    if (
      this.instances[calendarId] &&
      this.instances[calendarId][eventId] &&
      this.instances[calendarId][eventId][instanceId]
    ) {
      delete this.instances[calendarId][eventId][instanceId]
    }
  }

  _copyInstancesData({
    calendarId,
    eventId,
  }: {
    calendarId: string,
    eventId: string,
  }) {
    if (this.instances[calendarId] && this.instances[calendarId][eventId]) {
      const eventInstancesData = {
        lastUpdated: this.instances[calendarId][eventId].lastUpdated,
        rrule: this.instances[calendarId][eventId].rrule,
      }
      ReduxEvents._getInstanceKeys(this.instances[calendarId][eventId]).forEach(
        instanceId => {
          eventInstancesData[instanceId] = this.instances[calendarId][eventId][
            instanceId
          ].clone()
        }
      )
      return eventInstancesData
    }
  }
}

class TreeId {
  id: {
    calendarId: string,
    eventId: string,
    instanceId?: string,
    treeId: string,
  }

  constructor(treeId: string) {
    try {
      this.id = JSON.parse(treeId)
      this.id.treeId = treeId
    } catch (e) {
      this.id = { treeId, eventId: '', calendarId: '' }
    }
  }

  get eventId() {
    return this.id.eventId
  }

  get instanceId() {
    return this.id.instanceId || ''
  }

  get calendarId() {
    return this.id.calendarId || ''
  }

  get treeId() {
    return this.id.treeId
  }

  get treeMapId() {
    if (this.instanceId) {
      return `${this.calendarId}-${this.eventId}-${this.instanceId}`
    } else {
      return `${this.calendarId}-${this.eventId}`
    }
  }
}

class UIScopeEventTree {
  tree: any
  debugId: string

  constructor(debugId: string) {
    this.debugId = debugId
    this.tree = createIntervalTree()
  }

  static reduxEventToTreeId(reduxEvent: $ReadOnly<ReduxEvent>): string {
    if (!reduxEvent.isEmpty()) {
      if (reduxEvent.isInstance) {
        return `{"calendarId":"${reduxEvent.calendarId}","eventId":"${reduxEvent.eventId}","instanceId":"${reduxEvent.instanceId}"}`
      } else {
        return `{"calendarId":"${reduxEvent.calendarId}","eventId":"${reduxEvent.eventId}"}`
      }
    }
    return '{}'
  }

  insert(event: $ReadOnly<ReduxEvent>) {
    if (!event.isEmpty() && event.start && event.end) {
      const id = UIScopeEventTree.reduxEventToTreeId(event)
      getRBTreeTimePairsFromEvent(event).forEach(pair => {
        if (pair.start <= pair.end) {
          if (event.isRecurringEvent) {
            if (event.rruleUntil > 0) {
              if (event.rruleUntil >= pair.start) {
                this.tree.insert(pair.start, event.rruleUntil + 1, id)
              } else {
                console.debug(
                  `${this.debugId}:insert: until smaller than start, ignoring`,
                  pair.start,
                  pair.end,
                  event.rruleUntil
                )
              }
            } else {
              this.tree.insert(pair.start, Number.POSITIVE_INFINITY, id)
            }
          } else {
            if (pair.start === pair.end) {
              this.tree.insert(pair.start, pair.end + 1, id)
            } else {
              this.tree.insert(pair.start, pair.end, id)
            }
          }
          console.debug(
            `${this.debugId}:added id ${id} to tree`,
            pair.start,
            pair.end
          )
        } else {
          console.error(
            `${this.debugId}:end is smaller than start for id: ${id}`
          )
        }
      })
    } else {
      console.error(`${this.debugId}:event data incorrect`, event.id)
    }
  }

  remove(event: $ReadOnly<ReduxEvent>) {
    if (!event.isEmpty() && event.start && event.end) {
      const id = UIScopeEventTree.reduxEventToTreeId(event)
      console.debug(
        `${this.debugId}:removed id ${id} to tree`,
        event.start.timestamp,
        event.end.timestamp
      )
      if (event.isRecurringEvent) {
        if (event.rruleUntil > 0) {
          if (event.rruleUntil >= event.start.timestamp) {
            this.tree.remove(event.start.timestamp, event.rruleUntil + 1, id)
          } else {
            console.debug(
              `${this.debugId}:remove: until smaller than start, ignoring`,
              event.start.timestamp,
              event.end.timestamp,
              event.rruleUntil
            )
          }
        } else {
          this.tree.remove(event.start.timestamp, Number.POSITIVE_INFINITY, id)
        }
      } else {
        if (event.start.timestamp === event.end.timestamp) {
          this.tree.remove(event.start.timestamp, event.end.timestamp + 1, id)
        } else {
          this.tree.remove(event.start.timestamp, event.end.timestamp, id)
        }
      }
    }
  }

  clear() {
    this.tree = createIntervalTree()
  }

  *getIdsGenerator(start: UnixTimestampInSeconds, end: UnixTimestampInSeconds) {
    const ret = this.tree.search(start + 1, end - 1)
    for (let i = 0; i < ret.length; i++) {
      const obj = new TreeId(ret[i][2])
      if (typeof obj.eventId === 'string' && obj.eventId.length > 0) {
        yield obj
      }
    }
  }
}

const uiScopeEventTree = new UIScopeEventTree('fullEvents:noneAllDay')
const allDayEventTree = new UIScopeEventTree('fullEvents:allDay')

const getTreeIdsByRange = ({
  start,
  end,
  currentTimeZone,
}: {
  start: UnixTimestampInSeconds,
  end: UnixTimestampInSeconds,
  currentTimeZone: string,
}): TreeId[] => {
  const allDayStart = momentTimezone
    .tz(start * 1000, currentTimeZone)
    .utc(true)
    .unix()
  const allDayEnd = momentTimezone
    .tz(end * 1000, currentTimeZone)
    .utc(true)
    .unix()
  const ret = []
  const eventOrInstanceIds = uiScopeEventTree.getIdsGenerator(start, end)
  let { value, done } = eventOrInstanceIds.next()
  while (!done && !!value) {
    ret.push(value)
    const tmp = eventOrInstanceIds.next()
    value = tmp.value
    done = tmp.done
  }
  const allDayIds = allDayEventTree.getIdsGenerator(allDayStart, allDayEnd)
  const tmp = allDayIds.next()
  value = tmp.value
  done = tmp.done
  while (!done && !!value) {
    ret.push(value)
    const tmp = allDayIds.next()
    value = tmp.value
    done = tmp.done
  }
  return ret
}

const getTreeIdsMap = ({
  start,
  end,
  currentTimeZone,
  calendarId,
  eventId,
}: {
  start: UnixTimestampInSeconds,
  end: UnixTimestampInSeconds,
  currentTimeZone: string,
  calendarId: string,
  eventId?: string,
}): { [treeId: string]: TreeId | boolean } => {
  const ret = {}
  getTreeIdsByRange({ start, end, currentTimeZone }).forEach(
    (treeId: TreeId) => {
      const id = treeId.treeMapId
      if (id && treeId.calendarId === calendarId) {
        if (eventId) {
          if (eventId === treeId.eventId) {
            ret[id] = treeId
          }
        } else {
          ret[id] = treeId
        }
      }
    }
  )
  return ret
}
