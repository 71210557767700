// @flow
import { sha256 } from 'utils/crypto'
import { initializeKochava } from 'utils/kochava'
import { getAuthUser } from 'core/auth/selectors'

export function reportKochavaEvent(eventName: string, params = {}) {
  return async (dispatch, getState) => {
    const onMailEmail = getAuthUser(getState())
    try {
      const eventParams = {
        ...params,
        email: sha256(onMailEmail),
      }
      const kochava = await initializeKochava()
      kochava.sendEvent(eventName, eventParams)
    } catch (error) {
      console.error(`kochava event ${eventName} report error`)
    }
  }
}

export function kochavaEventsLoginSuccess() {
  return (dispatch, getState) => {
    dispatch(reportKochavaEvent('login_success'))
  }
}

export function kochavaEventsSignUpSuccess() {
  return (dispatch, getState) => {
    dispatch(reportKochavaEvent('account_created'))
  }
}

export function kochavaEventsThirdPartyAccountAdded(subAccountCount) {
  return (dispatch, getState) => {
    if (subAccountCount === 1) {
      dispatch(reportKochavaEvent('1st_third_party_account_added'))
    } else if (subAccountCount === 2) {
      dispatch(reportKochavaEvent('2nd_third_party_account_added'))
    } else if (subAccountCount === 3) {
      dispatch(reportKochavaEvent('3rd_third_party_account_added'))
    } else if (subAccountCount >= 4) {
      dispatch(reportKochavaEvent('4th_or_more_third_party_account_added'))
    }
  }
}
