// @flow
import reverse from 'lodash/reverse'
import sortBy from 'lodash/sortBy'
import { useLocation } from 'react-router-dom'
import React, { useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AttachmentBox from '@edison/webmail-ui/components/AttachmentBox'
import { getPendingAttachments } from 'core/large-attachments/selectors'
import { useTranslation } from 'react-i18next'
import {
  retryAttachment,
  retryAllAttachments,
  discardAttachment,
} from 'core/large-attachments/actions'
import qs from 'qs'
import { hasLargeAttachmentComplete } from 'utils'
import { getDraftByLargeAttachmentUuid } from 'core/compose/actions'
import { useHistory } from 'react-router-dom'

const AttachmentBoxView = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const attachments = useSelector(useMemo(() => getPendingAttachments(), []))
  const displayAttachments = useMemo(
    () => reverse(sortBy(attachments, 'date')),
    [attachments],
  )

  const completed = useMemo(
    () => attachments.every(hasLargeAttachmentComplete),
    [attachments],
  )

  // if some attachments uploading, need show prompt
  useEffect(() => {
    let event
    if (!completed) {
      window.addEventListener(
        'beforeunload',
        (event = e => {
          e.preventDefault()
          e.returnValue = t('compose.attachmentBox.beforeUnload')
          return t('compose.attachmentBox.beforeUnload')
        }),
      )
    } else {
      window.removeEventListener('beforeunload', event)
    }
    return () => {
      window.removeEventListener('beforeunload', event)
    }
  }, [completed])

  if (!displayAttachments.length) return null
  return (
    <AttachmentBox
      autoExpand
      attachments={displayAttachments}
      onRetry={attachmentUuid => dispatch(retryAttachment(attachmentUuid))}
      onRetryAll={() => dispatch(retryAllAttachments())}
      onCancel={attachmentUuid => dispatch(discardAttachment(attachmentUuid))}
      onClick={attachmentUuid => {
        const draft = dispatch(getDraftByLargeAttachmentUuid(attachmentUuid))
        draft &&
          history.push({
            search: `?${qs.stringify({
              [draft.responseMessageId ? 'thread' : 'compose']: draft.threadId,
            })}`,
          })
      }}
    />
  )
}

export default () => {
  const location = useLocation()
  const query = qs.parse(location.search.slice(1))

  if (query.compose || query.thread) {
    return null
  }
  return <AttachmentBoxView />
}
