// @flow
import React, { forwardRef, useMemo, useEffect, useRef } from 'react'
import LargeAttachmentItem from 'common/AttachmentItem/LargeAttachmentItem'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { downloadAll } from 'core/large-attachments/actions'
import AttachmentChip from '@edison/webmail-ui/components/AttachmentChip'
import AttachmentIcon from '@edison/webmail-ui/components/AttachmentIcon'
import Strip from '@edison/webmail-ui/components/EmailInput/Strip'
import SettingsIcon from '@material-ui/icons/Settings'
import DownloadIcon from '@edison/webmail-ui/components/Icons/Download'
import Dot from '@edison/webmail-ui/components/Dot'
import { usePreviewModal } from 'core/previews/hooks'
import { isCurrentUser } from 'core/custom-domains/selectors'
import { getSearchKeywords } from 'core/search/selectors'
import { highlightHTML } from 'utils/htmlProcess'
import { convertLargeAttachmentToAttachmentItem } from 'utils'
import { isDownloadAllloading } from 'core/attachments/selectors'
import type { Dispatch } from 'types/redux'

const searchKeywordsSelector = getSearchKeywords('attachments')

const TrimedAttachmentItem = forwardRef(({ attachments, index }, ref) => {
  const previews = usePreviewModal()
  const { name, size, contentType } = attachments[index]
  return (
    <AttachmentChip
      ref={ref}
      thumbnail={
        <AttachmentIcon
          fontSize="small"
          contentType={contentType}
          fileName={name}
        />
      }
      fileName={name}
      size={size}
      onClick={() => previews.showModal({ attachments, index })}
    />
  )
})

export default ({ largeAttachments, trimed, fromEmail, messageId }) => {
  const dispatch: Dispatch = useDispatch()
  const { t } = useTranslation()
  const ref = useRef()
  const keywords = useSelector(searchKeywordsSelector)
  const isLoading = useSelector(isDownloadAllloading)
  const attachmentItems = largeAttachments.map(attachment => ({
    ...convertLargeAttachmentToAttachmentItem(attachment),
    message: messageId,
  }))

  const currentUser = useSelector(
    useMemo(() => isCurrentUser(fromEmail), [fromEmail]),
  )

  useEffect(() => {
    if (ref.current && keywords.length) {
      highlightHTML(ref.current, keywords)
    }
  }, [messageId])

  return (
    <div ref={ref}>
      {trimed ? (
        <Strip>
          {largeAttachments.map((attachment, index) => (
            <TrimedAttachmentItem
              key={attachment.aid}
              attachments={attachmentItems}
              index={index}
            />
          ))}
        </Strip>
      ) : (
        <>
          {currentUser && (
            <div className="mb-2 flex items-center">
              <span className="text-lg font-semibold text-dark mr-2">
                {t('thread.tip.sendAsLinks')}
              </span>
              <div className="rounded-full text-secondary shadow w-8 h-8 inline-flex items-center justify-center">
                <SettingsIcon fontSize="small" className="text-gray-medium" />
              </div>
            </div>
          )}
          <div className="flex flex-wrap -m-2">
            {largeAttachments.map((attachment, index) => (
              <LargeAttachmentItem
                key={attachment.aid}
                attachments={attachmentItems}
                index={index}
              />
            ))}
          </div>
          {largeAttachments.length > 1 && (
            <div className="pt-2">
              <button
                onClick={
                  isLoading ? null : () => dispatch(downloadAll(messageId))
                }
                className="text-gray-light text-xs hover:text-gray-medium"
              >
                <DownloadIcon style={{ fontSize: 16 }} />
                &nbsp;&nbsp;
                {isLoading ? (
                  <>
                    Downloading <Dot />
                  </>
                ) : (
                  t('button.downloadAll')
                )}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}
