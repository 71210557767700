// @flow
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useShowCompose, useShowThreadDetail } from 'hooks/useHandleDetailModal'
import Button from '@edison/webmail-ui/components/Button'
import { hideSnackbar } from 'core/snackbars/actions'
import { snackbarTypes } from 'utils/constants'
import { undoSendDraftFlow } from 'core/compose/actions'
import { getDraft } from 'core/compose/selectors'
import type { Dispatch } from 'types/redux'
import { scrollToDraft } from '../../../utils'

export function SendFailureAction({ draftId }) {
  const { responseMessageId, threadId } = useSelector(
    useMemo(() => getDraft(draftId), []),
  )
  const showCompose = useShowCompose()
  const showThreadDetail = useShowThreadDetail()

  const dispatch: Dispatch = useDispatch()
  const { t } = useTranslation()
  return (
    <Button
      size="sm"
      color="warning"
      onClick={() => {
        responseMessageId ? showThreadDetail(threadId) : showCompose(threadId)
        dispatch(hideSnackbar({ key: snackbarTypes.send }))
      }}
    >
      {t('button.retry')}
    </Button>
  )
}

export function SendSuccessAction({ draftId }) {
  const dispatch: Dispatch = useDispatch()
  const showCompose = useShowCompose()
  const showThreadDetail = useShowThreadDetail()
  const { responseMessageId, schedulerId, sentThreadId, threadId } =
    useSelector(useMemo(() => getDraft(draftId), []))
  const { t } = useTranslation()

  const handleClick = async () => {
    await dispatch(undoSendDraftFlow(draftId))
    if (responseMessageId) {
      showThreadDetail(threadId)
      setTimeout(() => scrollToDraft(draftId))
    } else {
      showCompose(threadId)
    }
  }
  return (
    <>
      {!responseMessageId && !!sentThreadId && (
        <Button
          size="sm"
          onClick={() => {
            showThreadDetail(sentThreadId)
            dispatch(hideSnackbar({ key: snackbarTypes.send }))
          }}
        >
          {t('button.viewMessage')}
        </Button>
      )}
      {!!schedulerId && (
        <Button
          size="sm"
          color="secondary"
          className="ml-2 !text-primary bg-secondary rounded !border-none"
          onClick={handleClick}
        >
          {t('button.undo')}
        </Button>
      )}
      {/* <IconButton
        disableRipple
        size="small"
        onClick={() => dispatch(hideSnackbar({ key: snackbarTypes.send }))}
      >
        <CloseIcon />
      </IconButton> */}
    </>
  )
}

export function UndoSendSuccessAction() {
  return null
  // const dispatch: Dispatch = useDispatch()

  // return (
  //   <IconButton
  //     disableRipple
  //     size="small"
  //     onClick={() => dispatch(hideSnackbar({ key: snackbarTypes.send }))}
  //   >
  //     <CloseIcon />
  //   </IconButton>
  // )
}
