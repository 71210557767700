// @flow
import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import DetailModal from '@edison/webmail-ui/components/DetailModal'
import isNil from 'lodash/isNil'
import qs from 'qs'
import { useShowThreadDetail } from 'hooks/useHandleDetailModal'
import ContactDetail from 'common/Contacts/Details'
import { getLastestSiftInThread } from 'common/Contacts/components/Sift'
import { checkAndFetchThread } from 'core/messages/actions'
import {
  getThreadsById,
  getThreadDefaultRecipient,
} from 'core/threads/selectors'
import ThreadHelmet from 'screens/Helmets/ThreadHelmet'
import ThreadDetail from '../ThreadDetail'
import { getSiftsByThreadId } from 'core/sifts/selectors'

import { contactSiftTabs, contactDetailTabs } from 'utils/constants'
import useActions from './useActions'
import type { Dispatch } from 'types/redux'
import useOnMailTheme from 'hooks/useOnMailTheme'
import { useDetailModalGoBack } from 'hooks/useGoBack'
import { useDebouncedCallback } from 'use-debounce'
import { isAuthenticated } from 'core/auth/selectors'
import { useAppInitialization } from 'core/initialization/hooks'
import ContainerWithRightDrawer from '@edison/webmail-ui/components/DetailModal/ContainerWithRightDrawer'
import { useThreadContactDrawerOpenFlag } from 'core/flags/hooks'

const defaultContactTabs = [
  contactDetailTabs.emails,
  contactDetailTabs.photos,
  contactDetailTabs.files,
]

const ThreadDetailModal = () => {
  const theme = useOnMailTheme()
  const { search } = useLocation()
  const query = useMemo(() => qs.parse(search.slice(1)), [search])

  const threadId = query.thread
  const showThreadDetail = useShowThreadDetail()
  const previousThreadIdRef = useRef(threadId)
  const threads = useSelector(getThreadsById)
  const thread = threads[threadId]

  const dispatch: Dispatch = useDispatch()
  const byThreadId = useSelector(getSiftsByThreadId)

  const [selectedRecipient, setSelectedRecipient] = useState(null)
  const goBack = useDetailModalGoBack('thread', true)
  const [debouncedFetchThread] = useDebouncedCallback(
    threadId => dispatch(checkAndFetchThread(threadId)),
    200,
  )

  const defaultRecipientSelector = useMemo(
    () => getThreadDefaultRecipient(threadId),
    [threadId],
  )
  const defaultRecipient = useSelector(defaultRecipientSelector)

  const actions = useActions(threadId, goBack)
  useEffect(() => {
    !threadId && setSelectedRecipient(null)
  }, [threadId])

  useEffect(() => {
    if (threadId) {
      previousThreadIdRef.current && previousThreadIdRef.current !== threadId
        ? debouncedFetchThread(threadId)
        : dispatch(checkAndFetchThread(threadId))
    }
    previousThreadIdRef.current = threadId
  }, [threadId])

  useEffect(() => {
    if (defaultRecipient) {
      setSelectedRecipient(defaultRecipient)
    }
  }, [defaultRecipient])

  const previousContact = useRef(null)
  const contactDetailNode = useMemo(() => {
    let node = previousContact.current
    if (!isNil(threadId) && !isNil(selectedRecipient)) {
      if (!!thread) {
        const latestSift = getLastestSiftInThread(byThreadId[threadId] || [])
        const contactTabs = isNil(latestSift)
          ? defaultContactTabs
          : [contactSiftTabs[latestSift.domain], contactDetailTabs.emails]

        node = (
          <ContactDetail
            enableBlockAction
            tabs={contactTabs}
            onSelectThread={newThreadId => showThreadDetail(newThreadId)}
            selectedThread={threadId}
            {...selectedRecipient}
          />
        )
      }
    } else node = null
    return (previousContact.current = node)
  }, [threadId, thread, selectedRecipient])
  const { drawerOpen: userSetOpenVal, setDrawerOpenFlag: setUserSetOpenVal } =
    useThreadContactDrawerOpenFlag()
  const [drawerOpen, setDrawerOpen] = useState(() => {
    if (userSetOpenVal !== undefined) {
      return userSetOpenVal
    }
    return document.body.scrollWidth >= 1000
  })
  const onToggle = useCallback(() => {
    setDrawerOpen(!drawerOpen)
    setUserSetOpenVal(!drawerOpen)
  }, [drawerOpen])
  return (
    <DetailModal isOpen hideModal={goBack} actions={actions}>
      <ContainerWithRightDrawer
        theme={theme}
        drawerOpen={drawerOpen}
        onToggleDrawer={onToggle}
        leftContent={
          <ThreadDetail
            labelIds={thread ? thread.labelIds : []}
            threadId={threadId}
            onSelectRecipient={recipient => setSelectedRecipient(recipient)}
          />
        }
        drawerContent={contactDetailNode}
      />
    </DetailModal>
  )
}

export default () => {
  const authed = useSelector(useMemo(() => isAuthenticated(), []))
  const location = useLocation()
  const { isInitialized } = useAppInitialization()

  const query = useMemo(
    () => qs.parse(location.search.slice(1)),
    [location.search],
  )
  if (!authed || !query.thread || !isInitialized) return null

  return (
    <>
      <ThreadHelmet threadId={query.thread} />
      <ThreadDetailModal />
    </>
  )
}
