// @flow
import moment from 'moment'
import { batch } from 'react-redux'
import difference from 'lodash/difference'
import head from 'lodash/head'
import isNil from 'lodash/isNil'
import keys from 'lodash/keys'
import get from 'lodash/get'

import * as client from '@edison/webmail-core/api'
import { createAction } from 'utils/redux'
import { show as showModal } from '../modals/actions'
import { labelActions, cleanupLabelChanged } from '../metadata/actions'
import { batchGetMessages } from '../messages/actions'
import { createTemporaryContact } from '../contacts/actions'
import { showLoading, hideLoading } from '../toasts/actions'
import {
  getActiveLabel,
  getThreadsState,
  getFetchedThreadIds,
  getThreadPagination,
  getSelectedThreadIds,
  getThreadListRequestId,
  isSelectedUniverse,
} from './selectors'
import { getActiveAccountLabel } from '../retrofit/selectors'
import { getAuth } from '../auth/selectors'
import {
  getAllLabelsState,
  getLabelsWithRetrofitFilter,
} from '../labels/selectors'
import { getMessageState } from '../messages/selectors'
import {
  getMessagesState as getMessagesMetaState,
  getLabelThreadsCount,
  getThreadMessagesState,
  getLatestThreadMessageId,
} from '../metadata/selectors'
import {
  getSplitInboxByLabelId,
  getPreviewableSplits,
} from '../split-inboxes/selectors'
import { getContactsIndexedByEmail } from '../contacts/selectors'
import { labelOperations } from '../metadata/constants'
import {
  getThreadLabels,
  getAssignedLabels,
  isFromCurrentAccount,
} from '../metadata/helpers'
import {
  labelNames,
  labelTypes,
  modalTypes,
  contactTypes,
  labelRouteNames,
  siftLabelsGroup,
  THREAD_LIST_BATCH_NUM,
  THREAD_PREVIEW_BATCH_NUM,
} from 'utils/constants'

import type {
  ThreadListRequest,
  ThreadListSuccess,
  ThreadListFailure,
  ThreadSetActiveLabel,
  ThreadHistoryRequest,
  ThreadHistorySuccess,
  ThreadHistoryFailure,
  ThreadSelect,
  ThreadBatchSelect,
  ThreadSelectUniverse,
  ThreadBatchGetRequest,
  ThreadBatchGetSuccess,
  ThreadBatchGetFailure,
  SetLastHistoryId,
  ThreadDrag,
  ThreadSelectReset,
  ThreadFeedViewRequest,
  ThreadFeedViewSuccess,
  ThreadFeedViewFailure,
  DragBetweenInboxRequest,
  DragBetweenInboxSuccess,
  DragBetweenInboxFailure,
} from './types'
import type { ThunkAction, ActionCreator, Dispatch } from 'types/redux'
import type { Label } from '@edison/webmail-core/types/labels'

export const fetchThreadsActions: {
  request: ActionCreator<ThreadListRequest>,
  success: ActionCreator<ThreadListSuccess>,
  failure: ActionCreator<ThreadListFailure>,
} = {
  request: createAction('THREAD_LIST_REQUEST'),
  success: createAction('THREAD_LIST_SUCCESS'),
  failure: createAction('THREAD_LIST_FAILURE'),
}

type ThreadsFetchOptions = {
  ignoreExisting?: boolean,
  pageToken?: string,
  size?: number,
}

/**
 * Fetch data for a list of threads from the backend API.
 *
 * @public
 * @param {string} folder - name of folder, e.g. INBOX, SENT etc.
 * @param {string} pageToken - Page token for the next page
 * @returns {ThunkAction}
 */
export function fetchThreads(
  labelId: string,
  { ignoreExisting = false, pageToken, size }: ThreadsFetchOptions = {}
): ThunkAction {
  return async (dispatch, getState, extras) => {
    const state = getState()
    const auth = getAuth()(state)
    const fetchedCount = getLabelThreadsCount(labelId)(state)
    const accountLabel = getActiveAccountLabel(labelId)(state)

    if (auth === null) {
      dispatch(fetchThreadsActions.failure({ message: 'User not logged in' }))
      return {}
    }

    const overwrite = !pageToken

    const meta = {
      request: { id: `${+new Date()}` },
    }

    try {
      dispatch(fetchThreadsActions.request({ label: labelId, overwrite }, meta))
      const res = await client.threads.list({
        auth,
        size,
        pageToken,
        accountLabel,
        label: labelId,
        offset: overwrite ? 0 : fetchedCount,
        batchGet: true,
      })
      const {
        ids,
        threads,
        nextPageToken,
        estimateTotal,
        estimateUnread,
        historyId,
      } = res.result

      const requestId = getThreadListRequestId(getState())
      // Skip the result when there's another list is requesting
      if (requestId !== meta.request.id) {
        return {}
      }

      dispatch(
        fetchThreadsActions.success(
          {
            ids,
            threads,
            labelId,
            overwrite,
            historyId,
            unreadCount: estimateUnread,
            pagination: {
              next: nextPageToken,
              total: estimateTotal,
            },
          },
          meta
        )
      )

      return res.result
    } catch (e) {
      dispatch(fetchThreadsActions.failure({ message: e.message }, meta))
      return {}
    }
  }
}

/**
 * Fetches the next page of thread IDs
 */
export function fetchNextThreads(): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const activeLabel = getActiveLabel()(state)
    const pagination = getThreadPagination(state)
    const previewableSplits = getPreviewableSplits(state)

    let result = { ids: [] }

    if (activeLabel) {
      let size = THREAD_LIST_BATCH_NUM
      if (previewableSplits.includes(activeLabel)) {
        size = THREAD_PREVIEW_BATCH_NUM
      }

      let params: ThreadsFetchOptions = { size }
      if (pagination.next) {
        params['pageToken'] = pagination.next
      }

      const { ids = [] } = await dispatch(fetchThreads(activeLabel, params))

      result.ids = ids
    }

    return result
  }
}

/**
 * Cleans up the thread list IDs and re-fetch the first page of list
 *
 */
export function refreshThreads(labelId?: string): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const activeLabel = getActiveLabel()(state)
    const previewableSplits = getPreviewableSplits(state)
    const fetchedIds = new Set(getFetchedThreadIds(state))

    let targetLabel = labelId || activeLabel
    const isPreviewableLabel = previewableSplits.includes(
      labelRouteNames[targetLabel] || targetLabel
    )

    let result = { ids: [] }

    if (targetLabel) {
      let size = isPreviewableLabel
        ? THREAD_PREVIEW_BATCH_NUM
        : THREAD_LIST_BATCH_NUM

      const { ids = [] } = await dispatch(
        fetchThreads(targetLabel, { size, ignoreExisting: true })
      )
      // Update the fetched threads
      if (ids.some(id => fetchedIds.has(id))) {
        if (isPreviewableLabel) {
          await dispatch(batchGetThreadFeeds(ids, true))
        }
      }
      result.ids = ids
    }

    return result
  }
}

export const batchGetThreadsActions: {
  request: ActionCreator<ThreadBatchGetRequest>,
  success: ActionCreator<ThreadBatchGetSuccess>,
  failure: ActionCreator<ThreadBatchGetFailure>,
} = {
  request: createAction('THREAD_BATCH_GET_REQUEST'),
  success: createAction('THREAD_BATCH_GET_SUCCESS'),
  failure: createAction('THREAD_BATCH_GET_FAILURE'),
}

export const batchGetThreads = (
  threadIds: $ReadOnlyArray<string>,
  ignoreExisting: boolean = false
): ThunkAction => {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    const labelsMeta = getLabelsWithRetrofitFilter(state)
    const messagesMeta = getMessagesMetaState()(state)
    const threadMessagesMeta = getThreadMessagesState()(state)
    if (auth === null) {
      dispatch(
        batchGetThreadsActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      let threadsToGet = [...threadIds]
      if (!ignoreExisting) {
        const { ids } = getThreadsState(getState())
        threadsToGet = difference(threadIds, ids).slice(0, 50)
      }
      if (threadsToGet.length > 0) {
        dispatch(batchGetThreadsActions.request())
        const res = await client.threads.batchGet(threadsToGet, { auth })
        const threads = res.result

        const viewThreads = threads.map(({ id, messages }) => {
          const threadMessages = get(threadMessagesMeta, `${id}.messageIds`, [])
          const prevLabelIds = getThreadLabels(
            threadMessages.map(id => get(messagesMeta, `${id}.labelIds`, []))
          )
          const currLabelIds = getThreadLabels(
            messages.map(({ labelIds }) => labelIds)
          )

          const [prevViewLabels, currViewLabels] = [prevLabelIds, currLabelIds]
            .map(items =>
              // Filter out the threads which don't belong to current account
              isFromCurrentAccount(items, labelsMeta) ? items : []
            )
            .map(items => getAssignedLabels(labelsMeta, items))

          return {
            threadId: id,
            prevViewLabels: Array.from(prevViewLabels),
            currViewLabels: Array.from(currViewLabels),
          }
        })

        dispatch(
          batchGetThreadsActions.success({
            threads,
            viewThreads,
          })
        )

        // Handle the new pending threads
        const pendingMessages = threads
          .flatMap(thread => thread.messages || [])
          .filter(message => {
            return (message.labelIds || []).some(
              item =>
                item === labelNames.pending &&
                item !== labelNames.trash &&
                item !== labelNames.spam &&
                item !== labelNames.drafts
            )
          })
        if (pendingMessages.length > 0) {
          const contacts = getContactsIndexedByEmail()(getState())
          const createdContacts = new Set(
            keys(contacts).map(each => each.toLowerCase())
          )
          const pendingSet = new Set()
          pendingMessages.forEach(({ from }) => {
            if (from.email && !createdContacts.has(from.email.toLowerCase()))
              pendingSet.add({ email: from.email, name: from.name })
          })
          batch(() => {
            pendingSet.forEach(({ email, name }) => {
              dispatch(
                createTemporaryContact({
                  email,
                  name,
                  status: contactTypes.PENDING,
                })
              )
            })
          })
        }
      }
    } catch (e) {
      dispatch(batchGetThreadsActions.failure({ message: e.message }))
    }
  }
}

export const setActiveThreadLabel: ActionCreator<ThreadSetActiveLabel> = createAction(
  'THREAD_SET_ACTIVE_LABEL'
)

export const fetchThreadHistoryActions: {
  request: ActionCreator<ThreadHistoryRequest>,
  success: ActionCreator<ThreadHistorySuccess>,
  failure: ActionCreator<ThreadHistoryFailure>,
} = {
  request: createAction('THREAD_HISTORY_REQUEST'),
  success: createAction('THREAD_HISTORY_SUCCESS'),
  failure: createAction('THREAD_HISTORY_FAILURE'),
}

export const selectThread: ActionCreator<ThreadSelect> = createAction(
  'THREAD_SELECT'
)
export const batchSelectThread: ActionCreator<ThreadBatchSelect> = createAction(
  'THREAD_BATCH_SELECT'
)

export const selectUniverseThread: ActionCreator<ThreadSelectUniverse> = createAction(
  'THREAD_SELECT_UNIVERSE'
)

export const setLastHistoryId: ActionCreator<SetLastHistoryId> = createAction(
  'SET_LAST_HISTORY_ID'
)

export const dragThread: ActionCreator<ThreadDrag> = createAction('THREAD_DRAG')

export const dragBetweenInboxActions: {
  request: ActionCreator<DragBetweenInboxRequest>,
  success: ActionCreator<DragBetweenInboxSuccess>,
  failure: ActionCreator<DragBetweenInboxFailure>,
} = {
  request: createAction('DRAG_BETWEEN_INBOX_REQUEST'),
  success: createAction('DRAG_BETWEEN_INBOX_SUCCESS'),
  failure: createAction('DRAG_BETWEEN_INBOX_FAILURE'),
}

export function dragBetweenInbox(
  source: string,
  target: string,
  addLabels: $ReadOnlyArray<string>,
  delLabels: $ReadOnlyArray<string>,
  senders: $ReadOnlyArray<string>,
  applyAll = false
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    const splitsByLabelId = getSplitInboxByLabelId(getState())
    if (auth === null) {
      dispatch(
        dragBetweenInboxActions.failure({
          message: 'User not logged in',
        })
      )
      return
    }

    try {
      dispatch(dragBetweenInboxActions.request())
      await client.threads.inboxDnd(
        { source, target, addLabels, delLabels, senders, applyAll },
        { auth }
      )
      // Update the correspoding split-inbox filter
      const sourceSplitId = get(splitsByLabelId, `${source}.id`)
      const targetSplitId = get(splitsByLabelId, `${target}.id`)

      dispatch(
        dragBetweenInboxActions.success({
          sourceSplitId,
          targetSplitId,
          senders,
        })
      )
    } catch (e) {
      dispatch(dragBetweenInboxActions.failure({ message: e.message }))
    }
  }
}

function getInboxDraggingAction(
  dispatch: Dispatch,
  source: Label,
  target: Label,
  threadIds: $ReadOnlyArray<string>,
  senders: $ReadOnlyArray<string>,
  applyAll: boolean
) {
  let [promises, operations] = [[], []]

  const mapping = {
    [labelNames.primary]: labelOperations.primary,
    [labelNames.promotions]: labelOperations.other,
  }

  const [isToSplit, isFromSplit] = [target.type, source.type].map(
    type => type === labelTypes.SPLIT_INBOXES
  )

  if (isFromSplit) {
    operations.push({ add: [], remove: [source.id] })
  }

  if (isToSplit) {
    // Handle drop in split-inbox
    operations.push({
      add: [target.id],
      remove: [],
    })

    const prefill = { from: senders }
    promises.push(() =>
      dispatch(
        showModal({
          key: modalTypes.dndSplitConfirm,
          props: {
            prefill,
            targetLabelId: target.id,
          },
        })
      )
    )
  } else {
    // Handle drop in category label
    operations.push(mapping[target.id])

    const targetLabelId =
      target.id === labelNames.promotions ? labelNames.other : target.id
    promises.push(() =>
      dispatch(
        showModal({
          key: modalTypes.dndCategoryConfirm,
          props: {
            threadIds,
            targetLabelId,
          },
        })
      )
    )
  }

  const { add, remove } = mergeOperations(operations)
  promises.push(() =>
    dispatch(
      dragBetweenInbox(source.id, target.id, add, remove, senders, applyAll)
    )
  )

  return {
    operation: { add, remove },
    promise: () => Promise.all(promises.map(fn => fn())),
  }
}

export function dropThreadsToSmartFolder(to: string): ThunkAction {
  return async (dispatch, getState) => {
    const selected = getSelectedThreadIds()(getState())

    let target = head(siftLabelsGroup[to] || [])

    if (isNil(target)) return

    dispatch(labelActions.update([target], []).threads(Array.from(selected)))
  }
}

export function dropThreads(data: {
  from: string,
  to: string,
  selectedThreadIds?: string[],
}): ThunkAction {
  return async (dispatch, getState) => {
    if (!data) {
      return false
    }
    const { from, to, selectedThreadIds } = data
    dispatch(showLoading())
    const state = getState()
    const threadMessages = getThreadMessagesState()(state)
    const messages = getMessageState()(state)
    const labelById = getAllLabelsState()(state)
    const selected = selectedThreadIds
      ? new Set(selectedThreadIds)
      : getSelectedThreadIds()(state)
    const applyAll = isSelectedUniverse(state)
    const [source, target] = [from, to].map(labelId =>
      labelId === labelNames.other
        ? labelById[labelNames.promotions]
        : labelById[labelId]
    )
    const isFromArchive = from === labelNames.archive
    const isFromCustom = get(source, 'type') === labelTypes.CUSTOM
    const isToCustom = get(target, 'type') === labelTypes.CUSTOM
    const isToSplitInbox = get(target, 'type') === labelTypes.SPLIT_INBOXES

    const senderSet: Set<string> = new Set()
    selected.forEach(threadId =>
      get(threadMessages, `${threadId}.messageIds`, [])
        .map(id => get(messages, `${id}.from.email`))
        .filter(Boolean)
        .map(sender => senderSet.add(sender))
    )

    let [promises, operations]: [
      Array<Function>,
      Array<{
        add: $ReadOnlyArray<string>,
        remove: $ReadOnlyArray<string>,
      }>
    ] = [[], []]

    if (
      to === labelNames.primary ||
      to === labelNames.other ||
      isToSplitInbox
    ) {
      const { operation, promise } = getInboxDraggingAction(
        dispatch,
        source,
        target,
        Array.from(selected),
        Array.from(senderSet),
        applyAll
      )
      // Apply the updates to metadata and thread entities
      const startTimestamp = moment().unix()
      await dispatch(
        labelActions
          .update(operation.add, operation.remove)
          .threads(Array.from(selected), { execute: false })
      )
      const endTimestamp = moment().unix()

      // Remove the changed to avoid `batchUpdateThreads` access these changed
      dispatch(cleanupLabelChanged({ startTimestamp, endTimestamp }))

      promises.push(promise)
    }

    switch (from) {
      case labelNames.sent:
      case labelNames.drafts:
        operations.push(labelOperations.moveToInbox)
        break
      case labelNames.archive:
        operations.push(labelOperations.unarchive)
        break
      case labelNames.trash:
        operations.push(labelOperations.untrash)
        break
      case labelNames.spam:
        operations.push(labelOperations.notSpam)
        break
      case labelNames.unread:
        operations.push(labelOperations.read)
        break
      default:
        // Handle custom labels
        if (isFromCustom && isToCustom) {
          operations.push({ add: [], remove: [from] })
        }
    }

    switch (to) {
      case labelNames.archive:
        operations.push(labelOperations.archive)
        break
      case labelNames.trash:
        operations.push(labelOperations.trash)
        break
      case labelNames.spam:
        operations.push(labelOperations.markAsSpam)
        break
      case labelNames.unread:
        if (isFromArchive) {
          operations = []
        }
        operations.push(labelOperations.unread)
        break
      default:
        // Handle custom labels
        if (isToCustom) {
          if (isFromArchive) {
            operations = []
          }
          operations.push({ add: [to], remove: [] })
        }
    }

    const { add, remove } = mergeOperations(operations)

    batch(() => {
      // Update the label for the dropping threads
      Promise.all([
        dispatch(
          labelActions.update(add, remove).threads(Array.from(selected))
        ),
        ...promises.map(fn => fn()),
      ]).finally(() => dispatch(hideLoading()))
    })
    return true
  }
}

export const resetSelectThread: ActionCreator<ThreadSelectReset> = createAction(
  'THREAD_SELECT_RESET'
)

export const fetchThreadFeedsActions: {
  request: ActionCreator<ThreadFeedViewRequest>,
  success: ActionCreator<ThreadFeedViewSuccess>,
  failure: ActionCreator<ThreadFeedViewFailure>,
} = {
  request: createAction('THREAD_FEED_VIEW_REQUEST'),
  success: createAction('THREAD_FEED_VIEW_SUCCESS'),
  failure: createAction('THREAD_FEED_VIEW_FAILURE'),
}

export function batchGetThreadFeeds(
  threadIds: $ReadOnlyArray<string>,
  ignoreExisting: boolean = false
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    const messages = getMessageState()(state)

    if (auth === null) {
      dispatch(
        fetchThreadFeedsActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      await dispatch(batchGetThreads(threadIds, ignoreExisting))
      const latestMessages = getLatestThreadMessageId(threadIds)(getState())
      // Filter out the fetched messages
      const toFetch = ignoreExisting
        ? [...latestMessages]
        : latestMessages.filter(
            id => !Boolean(get(messages, `${id}.loaded`, false))
          )
      if (toFetch.length > 0) {
        dispatch(fetchThreadFeedsActions.request())
        await dispatch(batchGetMessages(toFetch))
        dispatch(fetchThreadFeedsActions.success())
      }
    } catch (e) {
      dispatch(fetchThreadFeedsActions.failure({ message: '' }))
    }
  }
}

function mergeOperations(
  operations: Array<{
    add: $ReadOnlyArray<string>,
    remove: $ReadOnlyArray<string>,
  }>
): { add: string[], remove: string[] } {
  const { add, remove } = operations.reduce(
    (prev, curr) => {
      if (isNil(curr)) return prev

      for (let toAdd of curr.add) {
        prev.add.add(toAdd)
        prev.remove.delete(toAdd)
      }

      for (let toRemove of curr.remove) {
        prev.add.delete(toRemove)
        prev.remove.add(toRemove)
      }
      return prev
    },
    { add: new Set(), remove: new Set() }
  )

  return { add: Array.from(add), remove: Array.from(remove) }
}
