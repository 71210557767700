// @flow
import { toPairs } from 'lodash'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import keys from 'lodash/keys'
import mapValues from 'lodash/mapValues'
import unionBy from 'lodash/unionBy'
import values from 'lodash/values'
import { createSelector } from 'reselect'
import { getAuthUser } from '../auth/selectors'
import { getAliases } from '../custom-domains/selectors'
import { getLoadingStatus } from '../loading/selectors'
import {
  getProfileInSettings,
  isSenderCardsDisable,
} from '../settings/selectors'

import { getDomainByEmail, isStringEqual } from 'utils'
import { contactTypes } from 'utils/constants'

import type {
  Attachment,
  Contact,
  SuggestedContact,
} from '@edison/webmail-core/types/contacts'
import type { Profile } from '@edison/webmail-core/types/settings'
import type { Thread } from '@edison/webmail-core/types/threads'
import type { Selector, State } from 'types/state'
import { isOnmailAccount, updateEmailsByEntities } from './helpers'
import type { CommonContact, State as ContactState } from './types'

import { getActiveAccount } from 'core/retrofit/selectors'

const contactStateSelector = (state: State) => state.contacts

export const selectContactState = createSelector(
  contactStateSelector,
  getActiveAccount,
  (state, activeAccount) => {
    const isAllAccount = !activeAccount
    const { entities } = state.contacts
    const nextEntities = {}
    if (isAllAccount) {
      return state
    } else if (isOnmailAccount(activeAccount)) {
      for (const [id, contact] of toPairs(entities)) {
        if (contact.ecUuids?.includes('')) {
          nextEntities[id] = contact
        }
      }
    } else {
      for (const [id, contact] of toPairs(entities)) {
        if (contact.ecUuids?.includes(activeAccount.ecUUID)) {
          nextEntities[id] = contact
        }
      }
    }
    return {
      ...state,
      contacts: {
        ...state.contacts,
        emails: updateEmailsByEntities(nextEntities),
        entities: nextEntities,
      },
    }
  }
)

export function getContactspageToken() {
  return createSelector(
    contactStateSelector,
    state => state?.pageToken?.pageToken
  )
}

export const getContactsById: Selector<{
  [id: string]: Contact,
}> = createSelector(
  selectContactState,
  state => state.contacts.entities
)

export const getContactsByEmail: Selector<{
  [email: string]: Contact,
}> = createSelector(
  selectContactState,
  state => {
    const { entities, emails } = state.contacts
    return mapValues(emails, id => entities[id])
  }
)

const _getContacts: Selector<$ReadOnlyArray<Contact>> = createSelector(
  getContactsById,
  byId => values(byId).filter(each => each.emails.length > 0)
)

export function getContacts() {
  return _getContacts
}

/**
 * Returns the contacts from suggestions
 * Which would filter the blocked and pending contacts
 *
 * @public
 * @returns {Selector<$ReadOnlyArray<SuggestedContact>>}
 */
export function getSuggestedContacts(): Selector<
  $ReadOnlyArray<SuggestedContact>
> {
  return createSelector(
    selectContactState,
    getContactsById,
    (contactState: ContactState, byId) => {
      const { suggestions } = contactState
      return values(suggestions.autoComplete)
        .filter(contact => {
          if (!!contact.id && contact.id in byId) {
            return get(byId, `${contact.id}.status`) === contactTypes.NORMAL
          } else return true
        })
        .sort((a, b) => (a.date > b.date ? -1 : 1))
    }
  )
}

export const hasSuggestedBlock: Selector<boolean> = createSelector(
  selectContactState,
  state => {
    return !isEmpty(state.suggestions.block)
  }
)

export const getSuggestedBlockContacts: Selector<
  $ReadOnlyArray<{ id: string, name: string, email: string }>
> = createSelector(
  selectContactState,
  getContactsById,
  (state: ContactState, byId) => {
    const { suggestions } = state

    return keys(suggestions.block)
      .filter(id => id in byId)
      .flatMap(id =>
        convertContact(byId[id]).map(({ name, email }) => ({
          id,
          name,
          email,
        }))
      )
  }
)

export function getSuggestedBlockByEmail(
  email: string
): Selector<?{ id: string, name: string, email: string }> {
  return createSelector(
    getSuggestedBlockContacts,
    suggestedBlocks => suggestedBlocks.find(item => item.email === email)
  )
}

export function getSuggestedBlockById(
  id: string
): Selector<?{ id: string, name: string, email: string }> {
  return createSelector(
    getSuggestedBlockContacts,
    suggestedBlocks => suggestedBlocks.find(item => item.id === id)
  )
}

export function isContactSuggestedBlock(id: string): Selector<boolean> {
  return createSelector(
    getSuggestedBlockById(id),
    contact => !!contact
  )
}

export function getWtihBlockDomainSenders(domain: string) {
  return createSelector(
    getBlockedContacts(),
    blockedContacts => {
      return blockedContacts
        .filter(contact => !contact.isDomain)
        .filter(contact => {
          return contact.emails.some(
            item => `@${getDomainByEmail(item.email)}` === domain
          )
        })
    }
  )
}

export function getEmailIsBlockedDomain(email: string) {
  return createSelector(
    getBlockedContacts(),
    blockedContacts => {
      return blockedContacts
        .filter(contact => contact.isDomain)
        .some(contact =>
          contact.emails.some(
            item => getDomainByEmail(item.email) === getDomainByEmail(email)
          )
        )
    }
  )
}

export function getBlockDomainContact(email: string) {
  return createSelector(
    getBlockedContacts(),
    blockedContacts => {
      return blockedContacts
        .filter(contact => contact.isDomain)
        .filter(contact => {
          return contact.emails.some(
            item => getDomainByEmail(item.email) === getDomainByEmail(email)
          )
        })
    }
  )
}

export function hasBlockedDomainByEmail(email: string) {
  return createSelector(
    getBlockedContacts(),
    blockedContacts =>
      blockedContacts
        .filter(contact => contact.isDomain)
        .some(contact =>
          contact.emails.some(
            item => getDomainByEmail(item.email) === getDomainByEmail(email)
          )
        )
  )
}

export function getRelatedContactsByEmail(email: string) {
  return createSelector(
    getBlockedContacts(),
    blockedContacts =>
      blockedContacts
        .filter(contact => !contact.isDomain)
        .filter(contact => {
          return contact.emails.some(
            item => getDomainByEmail(item.email) === getDomainByEmail(email)
          )
        })
  )
}

/**
 * Returns all the contacts without any filter
 * including in-store contacts and suggestions
 *
 * @public
 * @returns {Selector<$ReadOnlyArray<CommonContact>>}
 */
export function getAllContacts(): Selector<$ReadOnlyArray<CommonContact>> {
  return createSelector(
    getContacts(),
    getSuggestedContacts(),
    (
      contacts: $ReadOnlyArray<Contact>,
      suggestedContacts: $ReadOnlyArray<SuggestedContact>
    ) => {
      return [
        ...contacts.flatMap(convertContact),
        ...suggestedContacts
          .map(({ id, avatar, name, email }) =>
            !!id ? { avatar, name, email } : null
          )
          .filter(Boolean),
      ]
    }
  )
}

/**
 * Returns a list of normal contacts
 *
 * @public
 * @returns {Selector<$ReadOnlyArray<Contact>>}
 */
const _getAcceptedContacts: Selector<$ReadOnlyArray<Contact>> = createSelector(
  isSenderCardsDisable,
  getContacts(),
  (disabled: boolean, contacts: $ReadOnlyArray<Contact>) =>
    contacts
      .filter(item =>
        // INFO: when sender cards disabled, all the contacts excepting blocked are treated as accepted
        disabled
          ? item.status !== contactTypes.BLOCK
          : item.status === contactTypes.NORMAL
      )
      .sort(sortContacts)
)
export function getAcceptedContacts(): Selector<$ReadOnlyArray<Contact>> {
  return _getAcceptedContacts
}

/**
 * Returns a list of pending contacts
 *
 * @public
 * @returns {Selector<$ReadOnlyArray<Contact>>}
 */
export const _getPendingContacts: Selector<
  $ReadOnlyArray<Contact & { contactId: string }>
> = createSelector(
  isSenderCardsDisable,
  getContacts(),
  (disabled, contacts) =>
    disabled
      ? []
      : contacts
          .filter(item => item.status === contactTypes.PENDING)
          .sort(sortContactsByDate)
          .map(item => ({ ...item, contactId: item.id }))
)
export function getPendingContacts(): Selector<
  $ReadOnlyArray<Contact & { contactId: string }>
> {
  return _getPendingContacts
}

export function hasPendingContacts(): Selector<boolean> {
  return createSelector(
    getPendingContacts(),
    (pendingContacts: $ReadOnlyArray<Contact>) => !isEmpty(pendingContacts)
  )
}

/**
 * Returns a list of blocked contacts
 *
 * @public
 * @returns {Selector<$ReadOnlyArray<Contact>>}
 */

const _getBlockedContacts: Selector<$ReadOnlyArray<Contact>> = createSelector(
  getContacts(),
  (contacts: $ReadOnlyArray<Contact>) => {
    return contacts
      .filter(item => item.status === contactTypes.BLOCK)
      .sort(sortContacts)
  }
)
export function getBlockedContacts(): Selector<$ReadOnlyArray<Contact>> {
  return _getBlockedContacts
}

/**
 * Returns all contacts with filter
 * Includes: Accepted and suggested contacts
 * Excludes: Pending and blocked contacts
 * Which is different from `getAllContacts` selector
 *
 * @public
 * @returns {Selector<$ReadOnlyArray<CommonContact>>}
 */
export function getFilteredContacts(): Selector<$ReadOnlyArray<CommonContact>> {
  return createSelector(
    getAcceptedContacts(),
    getSuggestedContacts(),
    (
      acceptedContacts: $ReadOnlyArray<Contact>,
      suggestedContacts: $ReadOnlyArray<SuggestedContact>
    ) => {
      return unionBy(
        acceptedContacts.flatMap(convertContact),
        suggestedContacts
          .map(({ id, avatar, name, email }) =>
            !!id ? { avatar, name, email } : null
          )
          .filter(Boolean),
        'email'
      )
    }
  )
}

export const getFilteredContactsEmail: Selector<
  $ReadOnlyArray<string>
> = createSelector(
  getFilteredContacts(),
  contacts => contacts.map(({ email }) => email)
)

export function getContactById(id: string): Selector<Contact | void> {
  return createSelector(
    getContactsById,
    byId => byId[id]
  )
}

export function getContactByEmail(email: string): Selector<?CommonContact> {
  return createSelector(
    _getContactIndexedByEmail,
    byEmail => {
      const match = keys(byEmail).find(each => isStringEqual(each, email))
      if (match) {
        return byEmail[match]
      }
      return null
    }
  )
}

export function getContactThreads(): Selector<{
  [string]: $ReadOnlyArray<Thread>,
}> {
  return createSelector(
    selectContactState,
    (contacts: ContactState) => contacts.threads
  )
}

export function getContactThreadsById(
  id: string
): Selector<$ReadOnlyArray<Thread>> {
  return createSelector(
    getContactThreads(),
    (threads: { [string]: $ReadOnlyArray<Thread> }) => get(threads, id, [])
  )
}

export function getAttachments(): Selector<{
  [string]: $ReadOnlyArray<Attachment>,
}> {
  return createSelector(
    selectContactState,
    (contacts: ContactState) => contacts.attachments
  )
}

export function getAttachmentsById(
  id: string
): Selector<$ReadOnlyArray<Attachment>> {
  return createSelector(
    getAttachments(),
    (attachments: { [string]: $ReadOnlyArray<Attachment> }) =>
      get(attachments, id, []).sort((a, b) => (a.date < b.date ? 1 : -1))
  )
}

export function getFileAttachmentsById(
  id: string
): Selector<$ReadOnlyArray<Attachment>> {
  return createSelector(
    getAttachmentsById(id),
    (attachments: $ReadOnlyArray<Attachment>) =>
      attachments
        .filter(each => !isImageAttachment(each))
        .sort((a, b) => (a.date < b.date ? 1 : -1))
  )
}

export function getImageAttachmentsById(
  id: string
): Selector<$ReadOnlyArray<Attachment>> {
  return createSelector(
    getAttachmentsById(id),
    (attachments: $ReadOnlyArray<Attachment>) =>
      attachments
        .filter(each => isImageAttachment(each))
        .sort((a, b) => (a.date < b.date ? 1 : -1))
  )
}

export function getContactsIndexedById(): Selector<{ [key: string]: Contact }> {
  return getContactsById
}

export function getContactsIndexedByEmail(): Selector<{
  [email: string]: CommonContact,
}> {
  return _getContactIndexedByEmail
}

const _getContactIndexedByEmail: Selector<{
  [email: string]: CommonContact,
}> = createSelector(
  getContactsByEmail,
  getProfileInSettings(),
  getAuthUser,
  getAliases,
  (
    byEmail: { [string]: Contact },
    profile: Profile,
    authUser: string,
    aliases: $ReadOnlyArray<string>
  ) => {
    return {
      ...mapValues(byEmail, (contact, email) =>
        convertContact(contact).find(each => each.email === email)
      ),
      ...keyBy(
        [authUser, ...aliases].map(self => ({
          email: self,
          avatar: profile.avatar,
          name: profile.senderName,
        })),
        'email'
      ),
    }
  }
)

const _getPendingThreads: Selector<{
  [key: string]: $ReadOnlyArray<Thread>,
}> = createSelector(
  selectContactState,
  state => {
    return mapValues(state.pendingThreads, threads =>
      threads.sort((a, b) => {
        if (a.ssFlag && !b.ssFlag) return -1
        else if (!a.ssFlag && b.ssFlag) return 1
        else return a.date < b.date ? 1 : -1
      })
    )
  }
)

export function getPendingThreads(): Selector<{
  [key: string]: $ReadOnlyArray<Thread>,
}> {
  return _getPendingThreads
}

export function hasPendingThreads(): Selector<boolean> {
  return createSelector(
    getPendingThreads(),
    (pendingThreads: { [key: string]: $ReadOnlyArray<Thread> }) => {
      return !isEmpty(pendingThreads)
    }
  )
}

export const getPendingMessageId: Selector<{
  [email: string]: string,
}> = createSelector(
  selectContactState,
  state => {
    return state.pendingMessageId
  }
)

const makeFlagByThreadId = (
  flagName: string
): Selector<{
  [key: string]: {
    [threadId: string]: boolean,
  },
}> =>
  createSelector(
    getPendingThreads(),
    byId => {
      return mapValues(byId, threads =>
        threads.reduce(
          (prev, { id, [flagName]: flag }) => ({
            ...prev,
            [id]: Boolean(flag),
          }),
          {}
        )
      )
    }
  )

const makeFlagByEmail = (
  flagName: string
): Selector<{
  [key: string]: boolean,
}> =>
  createSelector(
    makeFlagByThreadId(flagName),
    byId => {
      return mapValues(byId, byThreadId => values(byThreadId).some(Boolean))
    }
  )

export const getSSFlagByEmail = makeFlagByEmail('ssFlag')
export const getRRBFlagByEmail = makeFlagByEmail('rrbFlag')
export const getSSFlagByThreadId = makeFlagByThreadId('ssFlag')
export const getRRBFlagByThreadId = makeFlagByThreadId('rrbFlag')

export function isContactListLoading() {
  return getLoadingStatus('CONTACT_LIST', true)
}

export function isContactSuggestionsLoading() {
  return getLoadingStatus('CONTACT_SUGGESTIONS')
}

export function isContactPendingThreadsLoading() {
  return getLoadingStatus('CONTACT_PENDING_THREADS')
}

export function isContactUpdateLoading() {
  return getLoadingStatus('CONTACT_UPDATE')
}

export function isContactStatusUpdating() {
  return getLoadingStatus('CONTACT_STATUS_BATCH_UPDATE')
}

export const isFeedbackSuggestedBlockLoading: Selector<boolean> = getLoadingStatus(
  'SUGGESTED_BLOCK_FEEDBACK'
)

export const isSuggestedBlockListLoading: Selector<boolean> = getLoadingStatus(
  'SUGGESTED_BLOCK_LIST'
)

function sortContacts(a, b) {
  // User might set the contact name as empty
  // We need to use the email instead
  const aEmail = get(a, 'emails[0].email', '')
  const bEmail = get(b, 'emails[0].email', '')

  const aName =
    [a.firstName, a.lastName].filter(each => !!each.trim()).join(' ') || aEmail
  const bName =
    [b.firstName, b.lastName].filter(each => !!each.trim()).join(' ') || bEmail

  return aName.toLowerCase() < bName.toLowerCase() ? -1 : 1
}

function sortContactsByDate(a, b) {
  return b.insertTime - a.insertTime
}

function convertContact(contact: Contact): $ReadOnlyArray<CommonContact> {
  const name = [contact.firstName, contact.lastName]
    .filter(item => !!item)
    .join(' ')
  return contact.emails.map(({ email }) => ({
    email,
    name: name || email.split('@')[0],
    avatar: contact.avatar,
  }))
}

function isImageAttachment(attachment: Attachment) {
  const { preview, contentType } = attachment
  return preview === 'img' || contentType.includes('image')
}

export const getInnerContactByEmail = (email: string) => {
  return createSelector(
    getContacts(),
    contacts => {
      return contacts.find(contact =>
        contact.emails.find(item => isStringEqual(item.email, email))
      )
    }
  )
}
