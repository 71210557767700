// @flow
import forge from 'node-forge'

// The salt is the same with Edison Mail, and it's decided by Edison Mail mobile team.
const salt = '10722'
export function sha256(str: string) {
  const md = forge.md.sha256.create()
  md.update(str + salt, 'utf8')
  return md.digest().toHex()
}
