// @flow
import React, { useEffect, useMemo, useState } from 'react'
import {
  Empty,
  Layout,
} from '@edison/webmail-ui/screens/ContactDetail/Messages'
import ThreadList from '@edison/webmail-ui/components/ThreadList'
import { getThreadIdsLabelIdsMap } from 'core/metadata/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { batchGetThreads } from 'core/threads/actions'
import { fetchContactThreads, resetContactThreads } from 'core/contacts/actions'
import {
  getContactThreadsById,
  getContactspageToken,
} from 'core/contacts/selectors'
import ThreadListItem from './ThreadListItem'
import { getLoadingStatus } from 'core/loading/selectors'
import { labelNames } from 'utils/constants'
import { useDebouncedCallback } from 'use-debounce'
import type { Dispatch } from 'types/redux'
import isNil from 'lodash/isNil'

export function MessageList({
  contactId,
  onClick,
  classname,
  isShowLables = false,
}) {
  const dispatch: Dispatch = useDispatch()

  const [isLoadMore, setLoadMore] = useState(false)
  const contactThreads = useSelector(
    useMemo(() => getContactThreadsById(contactId), [contactId]),
  )
  const pageToken = useSelector(getContactspageToken())
  const ids = useMemo(
    () => contactThreads.map(item => item.id),
    [contactThreads],
  )
  const threadsLabelIds = useSelector(
    useMemo(() => getThreadIdsLabelIdsMap(ids), [ids]),
  )
  const notSpamIds = ids.filter(
    threadId => !(threadsLabelIds[threadId] || []).includes(labelNames.spam),
  )
  const isLoaded = ({ index }) => !isNil(notSpamIds[index])
  const loading = useSelector(
    useMemo(() => getLoadingStatus('CONTACT_THREADS'), []),
  )

  useEffect(() => {
    dispatch(resetContactThreads({ id: contactId }))
    dispatch(fetchContactThreads(contactId))
  }, [])

  useEffect(() => {
    dispatch(batchGetThreads(ids))
  }, [ids])

  const [debouncedBatchGet] = useDebouncedCallback(async () => {
    await dispatch(fetchContactThreads(contactId, 'ID', pageToken))
    setLoadMore(false)
  }, 300)

  useEffect(() => {
    if (isLoadMore) {
      debouncedBatchGet()
    }
  }, [isLoadMore])

  const _total = pageToken ? notSpamIds.length + 5 : notSpamIds.length

  const loadMore = ({
    startIndex,
    stopIndex,
  }: {
    startIndex: number,
    stopIndex: number,
  }) => {
    if (
      stopIndex > 0 &&
      startIndex <= notSpamIds.length &&
      notSpamIds.length < _total &&
      !loading
    ) {
      setLoadMore(true)
    }
  }

  if (!loading && !notSpamIds.length) {
    return <Empty />
  }

  function renderItem({ index, style, key }) {
    return (
      <ThreadListItem
        isShowLables={isShowLables}
        classname={classname}
        style={style}
        onClick={onClick}
        key={notSpamIds[index]}
        threadId={notSpamIds[index]}
      />
    )
  }
  return (
    <ThreadList
      loadMore={loadMore}
      total={loading && !ids.length ? 5 : _total}
      renderItem={renderItem}
      isLoaded={isLoaded}
    />
  )
}

export default function MessageListSection({ contactId, onClick }) {
  const loading = useSelector(
    useMemo(() => getLoadingStatus('CONTACT_THREADS'), []),
  )
  return (
    <Layout loading={loading}>
      <MessageList contactId={contactId} onClick={onClick} />
    </Layout>
  )
}
