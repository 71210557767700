import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { useTheme } from '@material-ui/core/styles'
import get from 'lodash/get'
import { highlightHTML, getKeywordLengthInDocument } from 'utils/htmlProcess'
import sanitize from '@edison/webmail-ui/utils/DOMSanitize'
import quotedHtmlTransformer from '@edison/webmail-ui/utils/quotedHtmlTransformer'
import autolinker from '@edison/webmail-ui/utils/autolinker'
import { juice, stripBodyStyle } from '@edison/webmail-ui/utils/inlineCSS'
import ShowImagesTip from '@edison/webmail-ui/components/ShowImagesTip'
import { getAuth } from 'core/auth/selectors'
import {
  isDangerMessage,
  isDisplayShowImageTip,
  isSpamMessage,
} from 'core/messages/selectors'
import { getSearchKeywords } from 'core/search/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAttachmentPreviewLink } from 'core/previews/actions'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import root from 'react-shadow'
import { usePreviewModal } from 'core/previews/hooks'
import { CONTENT_DISPOSITION_INLINE } from '@edison/webmail-core/utils/constants'
import 'intersection-observer'
import {
  highlightTextStyles,
  themeMode,
} from '@edison/webmail-ui/utils/constants'
import { UNKNOWN_SENDER, displayImageTipTypes } from 'utils/constants'
import { useImageProxyHandler } from '@edison/webmail-ui/hooks/proxyImage'
import useLazyImage, {
  EXTERNAL_IMG_ATTR,
  ATTACHMENT_IMG_ATTR,
  BACKGROUND_IMG_ATTR,
} from '@edison/webmail-ui/hooks/lazyImage'
import useMobileLayout, {
  fixOversizeElement,
  scaleContainer,
  scaleFontSize,
} from '@edison/webmail-ui/hooks/mobileLayout'
// import escape from 'escape-html'
import { showImages } from 'core/messages/actions'
import { isContactUpdateLoading } from 'core/contacts/selectors'

import { convertAttachmentToAttachmentItem } from 'utils'

const bodyKeywordsSelector = getSearchKeywords('body')

const viewports = {
  MOBILE: 'mobile',
}
const viewportHandlers = {
  [viewports.MOBILE]: [fixOversizeElement, scaleContainer, scaleFontSize],
}

const viewportCss = {
  common: `
    :focus { outline: none!important, }
    .hl {${highlightTextStyles}}


    .webmail_signature {
      color: #999;
    }
  `,
  mobile: `
    img {
      max-width: 100%!important;
    }
  `,
}
const viewportStyles = {
  mobile: {
    width: 'fit-content',
    minWidth: '100%',
  },
}

function MobileView({ messageId, html, attachments, viewport, caches }) {
  const cachedBody = useMemo(
    () => (caches ? caches.get(messageId) : undefined),
    [messageId]
  )

  const auth = useSelector(useMemo(() => getAuth(), []))
  const htmlRef = useRef()
  const [opacity, setOpacity] = useState(0)
  const dispatch = useDispatch()
  const fadeIn = () =>
    Promise.resolve().then(() => setOpacity(state => state || 1))

  const inlineAttachments = useMemo(
    () =>
      attachments
        .filter(item => item.contentDisposition === CONTENT_DISPOSITION_INLINE)
        .map(item => convertAttachmentToAttachmentItem(item, messageId)),
    [attachments]
  )

  const imageProxyHandler = useImageProxyHandler(auth ? auth.token : '')
  const attachmentImageHandler = useCallback(
    (cid, node) => {
      const index = inlineAttachments.findIndex(item => item.id === cid)

      if (!!~index) {
        return dispatch(
          fetchAttachmentPreviewLink(inlineAttachments[index])
        ).then(url => {
          url && node.setAttribute('src', url)
        })
      }
    },
    [inlineAttachments]
  )

  useLazyImage(
    {
      ref: htmlRef,
      options: {
        root: htmlRef.current,
      },
      onLoad: (src, node, attr) => {
        if (attr === EXTERNAL_IMG_ATTR || attr === BACKGROUND_IMG_ATTR) {
          return imageProxyHandler(node)
        } else if (attr === ATTACHMENT_IMG_ATTR) {
          return attachmentImageHandler(src, node)
        }
      },
    },
    [messageId]
  )

  useMobileLayout(
    {
      ref: htmlRef,
      handlers: get(viewportHandlers, viewport, []).concat(fadeIn),
    },
    [messageId, viewport]
  )

  useEffect(() => {
    return () => {
      if (htmlRef.current && caches) {
        const content = htmlRef.current.outerHTML

        caches.set(messageId, content)
      }
    }
  }, [])

  return (
    <section>
      <style type="text/css">
        {viewportCss.common}
        {get(viewportCss, viewport, '')}
      </style>
      {cachedBody ? (
        <div dangerouslySetInnerHTML={{ __html: cachedBody }} />
      ) : (
        <div
          ref={htmlRef}
          style={{
            ...get(viewportStyles, viewport, {}),
            opacity,
            position: 'relative',
            transition: 'opacity 300ms ease-in',
          }}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )}
    </section>
  )
}

function CommonView({ messageId, html, attachments, viewport }) {
  const auth = useSelector(useMemo(() => getAuth(), []))
  const keywords = useSelector(bodyKeywordsSelector)
  const htmlRef = useRef()
  const previews = usePreviewModal()
  const dispatch = useDispatch()
  const imageProxyHandler = useImageProxyHandler(auth ? auth.token : '')
  const attachmentImageHandler = useCallback(
    node => {
      if (node.hasAttribute('src')) return
      const cid = node.getAttribute(ATTACHMENT_IMG_ATTR)
      if (!cid) return
      const inlineAttachments = attachments
        .filter(item => item.contentDisposition === CONTENT_DISPOSITION_INLINE)
        .map(item => convertAttachmentToAttachmentItem(item, messageId))
      const index = inlineAttachments.findIndex(item => item.id === cid)

      if (!!~index) {
        dispatch(fetchAttachmentPreviewLink(inlineAttachments[index])).then(
          url => {
            if (url) {
              node.addEventListener('click', () => {
                previews.showModal({ attachments: inlineAttachments, index })
              })
              node.style.cursor = 'pointer'
              node.setAttribute('src', url)
            } else {
              node.style.visibility = 'initial'
            }
          }
        )
      }
    },
    [attachments, messageId]
  )

  useEffect(() => {
    autolinker(htmlRef.current)
  }, [html])
  useEffect(() => {
    if (htmlRef.current && keywords.length) {
      highlightHTML(htmlRef.current, keywords)
    }
  }, [keywords, html])

  useEffect(() => {
    const root = htmlRef.current

    root
      .querySelectorAll(`img[${EXTERNAL_IMG_ATTR}], [${BACKGROUND_IMG_ATTR}]`)
      .forEach(imageProxyHandler)
    root
      .querySelectorAll(`img[${ATTACHMENT_IMG_ATTR}]`)
      .forEach(attachmentImageHandler)
  }, [html])

  return (
    <>
      <style type="text/css">{viewportCss.common}</style>
      <div ref={htmlRef} dangerouslySetInnerHTML={{ __html: html }} />
    </>
  )
}

function HtmlBody({
  message: {
    id,
    subject,
    html,
    attachments,
    largeAttachments,
    inReplyTo,
    from,
  },
  viewport,
  ...rest
}) {
  const defaultDisplayShowImageTip = useSelector(
    useMemo(() => isDisplayShowImageTip(id), [id])
  )
  const isContactUpdating = useSelector(
    useMemo(() => isContactUpdateLoading(), [])
  )
  const spam = useSelector(useMemo(() => isSpamMessage(id), [id]))

  const { palette } = useTheme()
  const danger = useSelector(useMemo(() => isDangerMessage(id), []))
  const keywords = useSelector(bodyKeywordsSelector)
  const dispatch = useDispatch()
  const enableHiddenQuotation = useMemo(
    () => !!inReplyTo && !!subject.match(/^\s?re:/i),
    [inReplyTo, subject]
  )
  const [displayImageTipType, setDisplayImageTipType] = useState(
    defaultDisplayShowImageTip
  )

  useEffect(() => {
    setDisplayImageTipType(defaultDisplayShowImageTip)
  }, [defaultDisplayShowImageTip])

  const { quotedHTML, originalHTML, quoted, defaultOpen } = useMemo(() => {
    const shouldRemoveImageSource = [
      displayImageTipTypes.normal,
      displayImageTipTypes.noSender,
    ].includes(displayImageTipType)

    const doc = sanitize(html, {
      removeLargeAttachment: largeAttachments.length > 0,
      removeRemoteImageSource: shouldRemoveImageSource,
      darkMode: {
        enable: palette.type === themeMode.DARK,
        color: palette.text.primary,
        backgroundColor:
          viewport === viewports.MOBILE ? palette.card.main : palette.app.main,
      },
    })
    juice(doc)
    const originalHTML = stripBodyStyle(doc)
    const originalKeywordLength = getKeywordLengthInDocument(doc, keywords)
    let quoted = false
    if (viewport !== 'mobile') {
      if (enableHiddenQuotation) {
        quoted = quotedHtmlTransformer.removeQuotation(doc)
        if (
          !doc.body ||
          !doc.children[0] ||
          doc.body.textContent.trim().length === 0
        ) {
          return {
            quotedHTML: originalHTML,
            originalHTML,
            quoted: false,
            defaultOpen: false,
          }
        }
      }
    }

    return {
      quotedHTML: stripBodyStyle(doc),
      originalHTML,
      quoted,
      defaultOpen: quoted
        ? originalKeywordLength > getKeywordLengthInDocument(doc, keywords)
        : false,
    }
  }, [
    spam,
    danger,
    displayImageTipType,
    html,
    viewport,
    enableHiddenQuotation,
    keywords,
    attachments,
    palette.type,
  ])

  const [open, setOpen] = useState(defaultOpen)

  const viewProps = {
    messageId: id,
    html: open ? originalHTML : quotedHTML,
    attachments,
    viewport,
  }

  return (
    <>
      {viewport !== 'mobile' &&
        displayImageTipType !== displayImageTipTypes.hidden && (
          <ShowImagesTip
            disabled={isContactUpdating}
            email={from && from.email ? from.email : UNKNOWN_SENDER}
            showImages={displayImageTipType === displayImageTipTypes.enabled}
            onShowImagesAnyway={() => {
              setDisplayImageTipType(
                defaultDisplayShowImageTip === displayImageTipTypes.noSender
                  ? displayImageTipTypes.hidden
                  : displayImageTipTypes.enabled
              )
              dispatch(showImages(id))
            }}
          />
        )}

      <root.div className="overflow-auto text-sm text-gray-dark break-words">
        {viewport === 'mobile' ? (
          <MobileView {...viewProps} {...rest} />
        ) : (
          <CommonView {...viewProps} {...rest} />
        )}
      </root.div>
      {quoted && (
        <div className="mt-2">
          <span
            className="inline-flex items-center overflow-hidden text-gray-medium bg-gray-divider rounded-full hover:shadow h-3 px-1 cursor-pointer leading-loose"
            onClick={() => setOpen(value => !value)}
          >
            <MoreHorizIcon />
          </span>
        </div>
      )}
    </>
  )
}

function TextBodyContent({ text }) {
  const ref = useRef(null)
  const keywords = useSelector(bodyKeywordsSelector)

  useEffect(() => {
    if (ref.current && keywords.length) {
      highlightHTML(ref.current, keywords)
    }
  }, [keywords])
  useEffect(() => {
    autolinker(ref.current)
  }, [])

  return <div ref={ref}>{text}</div>
}

function TextBody({ message: { text } }) {
  return (
    <root.div className="text-sm text-dark whitespace-pre-wrap break-words">
      <style type="text/css">{`
        .hl {${highlightTextStyles}}
      `}</style>
      <TextBodyContent text={text} />
    </root.div>
  )
}

const EmailBody = ({ message, viewport, ...rest }) => {
  if (!message.loaded) return null
  if (message.html && message.html.trim())
    return <HtmlBody {...rest} message={message} viewport={viewport} />
  if (message.text) return <TextBody {...rest} message={message} />
  return null
}
export default EmailBody
