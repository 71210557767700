// @flow
import { useModal } from 'common/modals'
import { toastVariants } from 'common/toasts'
import { fetchContacts, showUnblockDomainPrompt } from 'core/contacts/actions'
import {
  getBlockedContacts,
  getEmailIsBlockedDomain,
} from 'core/contacts/selectors'
import { executeFilter } from 'core/filters/actions'
import { useActiveLabel } from 'core/threads/hooks'
import { showNotification } from 'core/toasts/actions'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { Dispatch } from 'types/redux'
import { getDomainByEmail } from 'utils'
import { contactTypes, labelNames, modalTypes } from 'utils/constants'

const blockedContactsSelector = getBlockedContacts()
const getTrashFilter = email => ({
  criteria: {
    from: email,
  },
  action: {
    addLabelIds: [labelNames.trash],
    removeLabelIds: [labelNames.inbox],
  },
})

const TrashMessageStatus = {
  Init: 0,
  Trashing: 1,
  Done: 2,
}

export default function BlockAllTip({ email }) {
  const dispatch: Dispatch = useDispatch()
  const blockDomain = useModal(modalTypes.blockDomain)
  const blockedContacts = useSelector(blockedContactsSelector)
  const isBlocked = blockedContacts.some(contact =>
    contact.emails.some(item => item.email === email),
  )
  const domain = getDomainByEmail(email)
  const isBlockedDomain = useSelector(
    useMemo(() => getEmailIsBlockedDomain(email), []),
  )

  useEffect(() => {
    dispatch(fetchContacts(contactTypes.BLOCK, 1))
  }, [])

  const activeLabel = useActiveLabel()

  const isInTrashFolder = activeLabel === labelNames.trash

  const [trashMessageStatus, setTrashMessageStatus] = useState(
    TrashMessageStatus.Init,
  )

  function handleTrashAll() {
    setTrashMessageStatus(TrashMessageStatus.Trashing)
    dispatch(executeFilter(getTrashFilter(email))).then(() => {
      showNotification(
        'Related emails are moving to trash...',
        toastVariants.success,
      )
      setTrashMessageStatus(TrashMessageStatus.Done)
    })
  }

  function handleBlockAll() {
    if (isBlockedDomain) {
      dispatch(showUnblockDomainPrompt(email))
      return
    }
    blockDomain.showModal({
      email,
      domain: getDomainByEmail(email),
    })
  }

  const trashMessagesNode = useMemo(() => {
    if (isInTrashFolder) {
      return null
    }
    if (trashMessageStatus === TrashMessageStatus.Init) {
      return (
        <button className="text-primary" onClick={handleTrashAll}>
          Trash all previous messages from this sender
        </button>
      )
    } else if (trashMessageStatus === TrashMessageStatus.Done) {
      return <span>Trash all previous messages have been trashed</span>
    } else {
      return (
        <span className="text-gray-light">
          Trash all previous messages from this sender
        </span>
      )
    }
  }, [trashMessageStatus])

  if (isBlocked || isBlockedDomain)
    return (
      <div className="px-4 py-2 mb-4 text-xs bg-app text-gray-medium bg-secondary">
        <div>
          You blocked <span className="text-dark font-semibold">{email}</span>.
          Future messages from this sender will be sent to the Trash.
        </div>
        <div>{trashMessagesNode}</div>
        <div>
          <button className="text-red" onClick={handleBlockAll}>
            {isBlockedDomain
              ? `Unblock all senders using "@${domain}"`
              : `Block all senders using "@${domain}"`}
          </button>
        </div>
      </div>
    )
  else return null
}
