import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@edison/webmail-ui/components/Button'

import { isDraftDisabled } from 'core/compose/selectors'
import { checkAndSendDraft } from 'core/compose/actions'
import { useDebouncedCallback } from 'use-debounce'

export default ({ draftId, editorRef }) => {
  const { t } = useTranslation()
  const { userId } = useParams()
  const history = useHistory()
  const disabled = useSelector(
    useMemo(() => isDraftDisabled(draftId), [draftId])
  )

  const dispatch = useDispatch()
  const [debouncedSendDraft] = useDebouncedCallback(
    draftId => {
      editorRef.current && editorRef.current.save()
      return dispatch(
        checkAndSendDraft(draftId, () =>
          history.push(`/u/${userId}/settings/domain`)
        )
      )
    },
    300,
    { leading: true, trailing: false }
  )

  return (
    <Button
      className="w-24"
      size="sm"
      disabled={disabled}
      onClick={() => {
        //avoid recipient empty when input recipient blur is trigger after click send
        setTimeout(() => debouncedSendDraft(draftId), 20)
      }}
    >
      {t('button.send')}
    </Button>
  )
}
