// @flow
import reverse from 'lodash/reverse'
import React, { useMemo } from 'react'
import { createPortal } from 'react-dom'
import { useSelector, useDispatch } from 'react-redux'
import AttachmentBox, {
  PasswordProtection,
} from '@edison/webmail-ui/components/AttachmentBox'
import { getDraftLargeAttachments, getDraft } from 'core/compose/selectors'
import {
  discardUpload,
  retryUpload,
  retryAllUpload,
} from 'core/large-attachments/actions'
import { updateDraft } from 'core/compose/actions'
import { checkAndShowPasswordProtectionPaywall } from 'core/premium/actions'
import type { Dispatch } from 'types/redux'

const AttachmentBoxView = ({ draftId }) => {
  const attachments = useSelector(
    useMemo(() => getDraftLargeAttachments(draftId), [draftId]),
  )
  const displayAttachments = useMemo(
    () => reverse([...attachments]),
    [attachments],
  )
  const { password } = useSelector(useMemo(() => getDraft(draftId), [draftId]))
  const dispatch: Dispatch = useDispatch()

  if (!displayAttachments.length) return null
  return (
    <AttachmentBox
      attachments={displayAttachments}
      passwordProtectionNode={
        <PasswordProtection
          password={password || ''}
          onShowPasswordProtectionDialog={() =>
            !dispatch(checkAndShowPasswordProtectionPaywall())
          }
          onChangePassword={newPassword =>
            dispatch(
              updateDraft({
                id: draftId,
                password: newPassword,
              }),
            )
          }
        />
      }
      onRetry={attachmentUuid => dispatch(retryUpload(draftId, attachmentUuid))}
      onRetryAll={() => dispatch(retryAllUpload(draftId))}
      onCancel={attachmentUuid =>
        dispatch(discardUpload(draftId, attachmentUuid))
      }
    />
  )
}

export default ({ draftId }) => {
  const attachments = useSelector(
    useMemo(() => getDraftLargeAttachments(draftId), [draftId]),
  )
  const { actived } = useSelector(useMemo(() => getDraft(draftId), [draftId]))
  if (!actived || !attachments.length) return null

  return createPortal(<AttachmentBoxView draftId={draftId} />, document.body)
}
