// @flow
import isNil from 'lodash/isNil'
import * as Sentry from '@sentry/browser'

import { BASE_DOMAIN } from 'utils/constants'

const enableSentry = process.env.NODE_ENV !== 'development'

export function initialize() {
  if (enableSentry) {
    Sentry.init({
      environment: BASE_DOMAIN || 'localhost',
      dsn:
        'https://29c311561eff46868cd989022c16c22a@o392989.ingest.sentry.io/5241427',
    })
  }
}

// TODO
export async function reportException(
  error: any,
  info: any,
  isLoginFailed = false
) {
  if (!enableSentry) {
    return
  }

  return new Promise<any>((resolve, reject) => {
    Sentry.withScope(scope => {
      if (isLoginFailed) {
        scope.setTag('isLoginFailed', 'log')
      }
      scope.setExtras(info)
      const eventId = Sentry.captureException(error)
      if (!isNil(eventId)) {
        console.error(`[ERROR] event_id[${eventId}] captured`)
      }
      resolve(eventId)
    })
  })
}

export function openFeedbackDialog(eventId: ?string) {
  if (isNil(eventId)) {
    return
  }

  Sentry.showReportDialog({ eventId })
}
