// @flow
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { modalTypes } from 'utils/constants'

import * as analytics from 'core/analytics/actions'
import { useQuickAction } from 'core/settings/hooks'
import { useModal } from 'common/modals'
import { useUserTutorial } from 'core/flags/hooks'
import { userTutorialFlags } from 'core/flags/constants'

import * as actionNodes from '@edison/webmail-ui/components/ThreadListItem/thread-actions'

import {
  Done,
  Trash,
  DiscardDraft,
} from '@edison/webmail-ui/components/QuickAction'
import { ProTip } from '@edison/webmail-ui/components/Tooltip'
import ThreadItemAvatar from '@edison/webmail-ui/components/ThreadItemAvatar'
import type { Node } from 'react'
import type { Dispatch } from 'types/redux'
import type { ThreadActionObject } from 'core/threads/hooks'
import type { ThreadAction } from '@edison/webmail-ui/components/ThreadListItem/thread-actions'

type Props = {
  threadId: string,
  children: Node,
  enableQuickActionTutorial?: boolean,
  actionFlags: { [key: string]: boolean },
  actions: { [key: ThreadAction]: ThreadActionObject },
}

export default ({
  enableQuickActionTutorial = false,
  threadId,
  actionFlags,
  actions,
  children,
}: Props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const quickAction = useQuickAction()
  const tutorial = useUserTutorial()

  const quickActionModal = useModal(modalTypes.quickAction)

  // INFO: Only show
  const showTooltip = (() => {
    if (!enableQuickActionTutorial) return false
    if (quickAction.configured) return false
    return tutorial.getFlag(userTutorialFlags.quickAction)
  })()

  useEffect(() => {
    if (showTooltip) {
      const handleOnClick = () =>
        tutorial.setFlag(userTutorialFlags.quickAction, false)

      document.addEventListener('click', handleOnClick)
      return () => {
        document.removeEventListener('click', handleOnClick)
      }
    }
  }, [showTooltip])

  let action
  if (quickAction.configured) {
    if (quickAction.value === actionNodes.archive.name) {
      if (actionFlags.archive) {
        action = (
          <Done
            onClick={() => {
              dispatch(
                analytics.threadActions.userThreadQuickAction(
                  actionNodes.archive.name
                )
              )

              actions.archive.onClick()
            }}
          />
        )
      }
    } else if (quickAction.value === actionNodes.trash.name) {
      if (actionFlags.trash) {
        action = (
          <Trash
            onClick={() => {
              dispatch(
                analytics.threadActions.userThreadQuickAction(
                  actionNodes.trash.name
                )
              )

              actions.trash.onClick()
            }}
          />
        )
      }
    }
  }

  if (actionFlags.discardDraft) {
    action = (
      <DiscardDraft
        onClick={() => {
          dispatch(
            analytics.threadActions.userThreadQuickAction(
              actionNodes.discardDraft.name
            )
          )

          actions.discardDraft.onClick()
        }}
      />
    )
  }

  const handleOnClick = e => {
    const target = e.currentTarget
    if (target) {
      const rect = target.getBoundingClientRect()

      quickActionModal.showModal({
        threadId,
        position: {
          x: Math.floor(rect.x),
          // HACK: `-3` is a magic number here for align
          y: Math.floor(rect.y) - 3,
        },
      })
    }
  }

  const render = () => {
    if (!action && !quickAction.configured && enableQuickActionTutorial) {
      return (
        <ProTip
          open={showTooltip}
          title={t('threadList.quickAction.tooltip')}
          placement="bottom-start"
          style={{ zIndex: 10 }}
        >
          <div onClick={handleOnClick}>{children}</div>
        </ProTip>
      )
    } else {
      return children
    }
  }

  return <ThreadItemAvatar quickAction={action}>{render()}</ThreadItemAvatar>
}
