// @flow
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import { useModal } from 'common/modals'
import { modalTypes } from 'utils/constants'

import { actions, selectors } from 'core/labels'
import { dropThreads } from 'core/threads/actions'
import { getLabelUnreadCount } from 'core/labels/selectors'

import Add from '@material-ui/icons/ControlPoint'
import More from '@material-ui/icons/MoreVert'
import ArrowRight from '@material-ui/icons/ArrowRight'
import ColorPicker from '@edison/webmail-ui/components/ColorPicker'
import LabelMenuLayout from '@edison/webmail-ui/components/Labels/LabelMenuLayout'
import LabelMenuAction from '@edison/webmail-ui/components/Labels/LabelMenuAction'
import Labels from '@edison/webmail-ui/components/Navigation/components/Labels'
import LabelHeader from '@edison/webmail-ui/components/Navigation/components/LabelHeader'
import DroppableLabel from './DroppableLabel'

import type { Dispatch } from 'types/redux'

const getCustomLabels = selectors.getCustomLabels()
const labelUnreadSelector = getLabelUnreadCount()

type Props = {
  invert: boolean,
  activeId: string,
  setLabel: (labelId: string) => void,
}

export default ({ invert, activeId, setLabel }: Props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const { showModal: showDetailModal } = useModal(modalTypes.labelDetail)
  const { showModal: showPromptModal } = useModal(modalTypes.labelPrompt)
  const customLabels = useSelector(getCustomLabels)
  const unreadLabels = useSelector(labelUnreadSelector)

  const handleChangeColor = useCallback((label, color) => {
    const { id, name, color: prevColor } = label
    if (color !== prevColor) {
      return dispatch(actions.updateLabel(id, { name, color }))
    }
  }, [])

  function renderLabel(label, index) {
    return (
      <DroppableLabel
        invert={invert}
        id={label.id}
        key={label.id}
        name={label.name}
        color={label.color}
        isActive={activeId === label.id}
        unread={get(unreadLabels, label.id, 0)}
        onClick={() => setLabel(label.id)}
        onDrop={() => dispatch(dropThreads({ from: activeId, to: label.id }))}
        moreAction={{
          trigger: <More fontSize="small" />,
          node: (
            <LabelMenuLayout>
              <LabelMenuAction
                className="relative flex items-center justify-between"
                popper={
                  <div className="absolute top-0 left-full z-10 bg-app shadow rounded">
                    <ColorPicker
                      value={label.color}
                      onChange={color => handleChangeColor(label, color)}
                    />
                  </div>
                }
              >
                {t('button.color')}
                <ArrowRight fontSize="small" />
              </LabelMenuAction>
              <LabelMenuAction
                onClick={() =>
                  showDetailModal({ labelId: label.id, name: label.name })
                }
              >
                {t('button.edit')}
              </LabelMenuAction>
              <LabelMenuAction
                onClick={() =>
                  showPromptModal({ labelId: label.id, name: label.name })
                }
              >
                {t('button.delete')}
              </LabelMenuAction>
            </LabelMenuLayout>
          ),
        }}
      />
    )
  }

  return (
    <Labels
      labels={customLabels.map(({ id, name, color, threadsUnread }) => ({
        id,
        name,
        color,
        unread: threadsUnread,
      }))}
      header={
        <LabelHeader
          invert={invert}
          actions={
            <button
              type="button"
              className="flex items-center"
              onClick={() => showDetailModal()}
            >
              <Add fontSize="small" />
            </button>
          }
        >
          {t('navigation.labels')}
        </LabelHeader>
      }
    >
      {customLabels.map(renderLabel)}
    </Labels>
  )
}
